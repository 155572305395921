import { useHistory } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useAtom } from "jotai";
import { FORWARDING_PARTNER_MANAGEMENT_ATOMS } from "jotaiStates/partnerManagement";

import { PartnerBusinessArea as BusinessArea } from "@sellernote/shared/src/types/forwarding/partnerManagement";

import PARTNER_TYPE from "./constants";

export default function PartnerBusinessArea() {
  const history = useHistory();

  const [params, setParams] = useAtom(
    FORWARDING_PARTNER_MANAGEMENT_ATOMS.SEARCH_PARAMS
  );

  const { businessArea } = params;

  const searchParams = new URLSearchParams(location.search);

  const queryString = searchParams.get("businessArea") || undefined;

  const updateQueryString = (key: string, value: BusinessArea | undefined) => {
    /** 전체인 경우 query string 삭제 (기본 진입 URL) */
    if (!value) searchParams.delete(key);
    else searchParams.set(key, value);

    history.push({ search: searchParams.toString() });
  };

  const handleBusinessAreaUpdate = (partner: BusinessArea | undefined) => {
    setParams((prev) => ({ ...prev, page: 0, businessArea: partner }));

    updateQueryString("businessArea", partner);
  };

  /** URL로 직접 진입했을때, 상태 업데이트 */
  if (businessArea !== queryString) {
    setParams({
      ...params,
      businessArea: queryString as BusinessArea | undefined,
    });
  }

  return (
    <Box display={"flex"} gap={1}>
      {PARTNER_TYPE.map(({ label, value }) => (
        <Button
          key={label}
          variant={"outlined"}
          size="small"
          color={businessArea === value ? "primary" : "inherit"}
          sx={{ borderColor: businessArea === value ? "inherit" : grey[400] }}
          startIcon={businessArea === value ? <CheckIcon /> : undefined}
          onClick={() => handleBusinessAreaUpdate(value as BusinessArea)}
        >
          {label}
        </Button>
      ))}
    </Box>
  );
}
