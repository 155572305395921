import { useState } from "react";
import {
  Button,
  FormHelperText,
  Input,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { ISSUE_AUTO_INVOICE_REQ } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import { SettlementSalesListData } from "@sellernote/shared/src/types/forwarding/adminSettlement";
import { toFormattedDate } from "@sellernote/shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/shared/src/utils/common/number";

import { useSalesSettlementContext } from "pages/settlement/salesManagement/useSalesSettlementContext";

const NO_LABEL = { "& legend": { display: "none" }, "& fieldset": { top: 0 } };

export default function InvoiceRow({
  invoice,
  isIssuing,
  handleAutoInvoiceIssue,
}: {
  invoice: SettlementSalesListData;
  isIssuing: boolean;
  handleAutoInvoiceIssue: ({
    invoiceId,
    writeDate,
    invoiceeEmail,
    remark,
  }: ISSUE_AUTO_INVOICE_REQ) => void;
}) {
  const { tabDisabledData } = useSalesSettlementContext();

  const {
    team,
    management,
    id,
    finalPrice,
    taxExemptPrice,
    issueInvoiceType,
    depositDate,
    isIssued,
    issueDate,
  } = invoice;

  /** 계산서 작성일 */
  const invoiceWrittenDate = () => {
    if (invoice.isIssued) return issueDate;

    return depositDate || new Date();
  };

  /** B/L 번호 */
  const BLNumber = management.hBL || management.mBL || "";
  /** 발행 여부 */
  const isInvoiceIssued = tabDisabledData?.includes(id) || isIssued;

  /** 비과세 항목을 "/"로 구분 */
  const taxExemptString = (() => {
    if (invoice.taxExemptList.length > 0) {
      return `${invoice.taxExemptList
        ?.map(({ item, itemPrice }) => `${item.toUpperCase()} ${itemPrice}원`)
        .join(" / ")} /`;
    }

    return "";
  })();

  /** 자동발행 계산서의 비고 */
  const invoiceRemark = (() => {
    /** 계산서가 영세인 경우 */
    if (issueInvoiceType === "zeroTax") {
      return !BLNumber
        ? `${taxExemptString} 총액 ${toThousandUnitFormat(
            finalPrice + taxExemptPrice
          )}원`
        : `BL No. ${BLNumber} / ${taxExemptString} 총액 ${toThousandUnitFormat(
            finalPrice + taxExemptPrice
          )}원`;
    }

    /** 계산서가 과세인 경우 */
    return !BLNumber
      ? `총액 ${toThousandUnitFormat(finalPrice)}원`
      : `BL No. ${BLNumber} / 총액 ${toThousandUnitFormat(finalPrice)}원`;
  })();

  /** 발행일 */
  const [invoiceIssueDate, setInvoiceIssueDate] = useState<
    string | null | Date
  >(invoiceWrittenDate);
  /** 공급받는자 이메일 */
  const [email, setEmail] = useState(team.invoiceEmail || team.email);
  /** 비고 */
  const [remark, setRemark] = useState(invoiceRemark);

  const isOverRemark = remark.length > 150;

  return (
    <TableRow>
      <TableCell>{id}</TableCell>

      <TableCell>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={invoiceIssueDate}
            disabled={isInvoiceIssued}
            inputFormat="yyyy-MM-dd"
            onChange={(date) => setInvoiceIssueDate(date)}
            renderInput={(params) => (
              <TextField
                onKeyDown={(e) => e.preventDefault()}
                {...params}
                sx={NO_LABEL}
                size="small"
              />
            )}
          />
        </LocalizationProvider>
      </TableCell>

      <TableCell>
        <Input
          defaultValue={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={isInvoiceIssued}
          sx={{ width: 200 }}
        />
      </TableCell>

      <TableCell>
        <TextField
          size="small"
          multiline
          defaultValue={invoiceRemark}
          onChange={(e) => setRemark(e.target.value)}
          disabled={isInvoiceIssued}
          sx={{ ...NO_LABEL, width: 400 }}
          error={isOverRemark}
        />

        <FormHelperText error={isOverRemark}>
          {`${remark.length}/${150}`}
        </FormHelperText>
      </TableCell>

      <TableCell>{isInvoiceIssued ? "발행 완료" : "발행 전"}</TableCell>

      <TableCell>
        <Button
          size="small"
          disabled={isInvoiceIssued || isIssuing || isOverRemark}
          variant="outlined"
          onClick={() =>
            handleAutoInvoiceIssue({
              invoiceId: id,
              // TODO: toFormattedDate > payload 형식이 지정되어 있어서 수정 보류
              writeDate: toFormattedDate(invoiceIssueDate, "YYYYMMDD"),
              invoiceeEmail: email,
              remark,
            })
          }
        >
          발행
        </Button>
      </TableCell>
    </TableRow>
  );
}
