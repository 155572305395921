import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Box, Tooltip, Typography } from "@mui/material";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "@sellernote/shared/src/admin-ui/components/LegacyTable";
import {
  GET_OPERATION_EXCEL_LIST_RES,
  GET_OPERATION_LIST_RES,
} from "@sellernote/shared/src/api-interfaces/shipda-api/admin/businessManagement";
import {
  OperationListItem,
  OperationTableHeadPanelType,
  ServiceLowercaseType,
} from "@sellernote/shared/src/types/forwarding/businessManagement";
import { formatDate } from "@sellernote/shared/src/utils/common/date";
import {
  changeBidProjectStatusNameToKr,
  changeBidStatusNameToKr,
} from "@sellernote/shared/src/utils/forwarding/bid";

export default function BusinessManagementOperationTable({
  operationList,
  currentPage,
  setCurrentPage,
  perPage,
  setPerPage,
  TablePanel,
  TableHeadPanel,
  saveFilterDataRefCallback,
  csvDownloadProps,
  isLoading,
}: {
  operationList: GET_OPERATION_LIST_RES | undefined;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  perPage: number;
  setPerPage: Dispatch<SetStateAction<number>>;
  TablePanel?: JSX.Element;
  TableHeadPanel: {
    [key in OperationTableHeadPanelType]: JSX.Element;
  };
  saveFilterDataRefCallback?: () => void;
  csvDownloadProps: {
    isCSVDownloadRequested: boolean;
    setIsCSVDownloadRequested: Dispatch<SetStateAction<boolean>>;
    dataForCSVDownload: GET_OPERATION_EXCEL_LIST_RES | undefined;
    ResponseHandlerOfFetchDataForCSVDownload: React.ReactNode;
    removeQueryOfFetchDataForCSVDownload: () => void;
    statusOfQueryOfFetchDataForCSVDownload:
      | "idle"
      | "error"
      | "loading"
      | "success";
  };
  isLoading: boolean;
}) {
  const pagination = useMemo(() => {
    if (operationList) {
      return {
        rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
        totalCount: Number(operationList?.total) || 0,
        perPage,
        setPerPage,
        currentPage,
        setCurrentPage,
      };
    }
  }, [currentPage, setCurrentPage, setPerPage, perPage, operationList]);

  const {
    isCSVDownloadRequested,
    setIsCSVDownloadRequested,
    dataForCSVDownload,
    ResponseHandlerOfFetchDataForCSVDownload,
    removeQueryOfFetchDataForCSVDownload,
    statusOfQueryOfFetchDataForCSVDownload,
  } = csvDownloadProps;

  type CellId =
    | keyof OperationListItem
    | "reset"
    | "HBL"
    | "MBL"
    | "payment"
    | "ETD"
    | "ETA"
    | "ATA"
    | "liner"
    | "bookingAgentGlobal"
    | "bookingAgent"
    | "volume"
    | "vessel"
    | "TPCompany"
    | "CCompany"
    | "sellingAmount"
    | "buyingAmount"
    | "profit"
    | "freightPaymentType";

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "reset",
        disablePadding: false,
        label: isCSVDownloadRequested ? "" : TableHeadPanel.AllResetButton,
        width: isCSVDownloadRequested ? 10 : 60,
        isFixed: true,
      },
      {
        id: "bidId",
        disablePadding: false,
        label: "Bid ID",
        width: 100,
        isFixed: true,
      },
      {
        id: "HBL",
        disablePadding: false,
        label: "H.B/L",
        width: 100,
        isFixed: true,
      },
      {
        id: "MBL",
        disablePadding: false,
        label: "M.B/L",
        width: 100,
        isFixed: true,
      },
      {
        id: "accountName",
        disablePadding: false,
        label: "AccountName",
        width: 145,
        isFixed: true,
        maxContentLength: 20,
      },
      {
        id: "incoterms",
        disablePadding: false,
        label: "Incoterms",
        width: 140,
        filter: TableHeadPanel.IncotermsFilter,
      },

      {
        id: "freightPaymentType",
        disablePadding: false,
        label: "Payment",
        width: 140,
        filter: TableHeadPanel.PaymentFilter,
      },
      {
        id: "cargoType",
        disablePadding: false,
        label: "Cargo Type",
        width: 150,
        filter: TableHeadPanel.CargoTypeFilter,
      },
      {
        id: "serviceType",
        disablePadding: false,
        label: "Service Type",
        width: 160,
        filter: TableHeadPanel.ServiceTypeFilter,
      },
      {
        id: "ETD",
        disablePadding: false,
        label: "ETD",
        width: 110,
      },
      {
        id: "ETA",
        disablePadding: false,
        label: "ETA",
        width: 110,
      },
      {
        id: "ATA",
        disablePadding: false,
        label: "ATA",
        width: 110,
      },
      {
        id: "POL",
        disablePadding: false,
        label: "POL",
        width: 160,
      },
      {
        id: "POD",
        disablePadding: false,
        label: "POD",
        width: 160,
      },
      {
        id: "liner",
        disablePadding: false,
        label: "Liner",
        width: 120,
      },
      {
        id: "bookingAgentGlobal",
        disablePadding: false,
        label: "Booking agent (Global)",
        width: 190,
      },
      {
        id: "bookingAgent",
        disablePadding: false,
        label: "Booking agent",
        width: 140,
      },
      {
        id: "rtonSupply",
        disablePadding: false,
        label: "R.TON",
        width: 80,
      },
      {
        id: "cwSupply",
        disablePadding: false,
        label: "C.W",
        width: 80,
      },
      {
        id: "container20Dry",
        disablePadding: false,
        label: "20DRY",
        width: 80,
      },
      {
        id: "container40Dry",
        disablePadding: false,
        label: "40DRY",
        width: 80,
      },
      {
        id: "container40Hq",
        disablePadding: false,
        label: "40HQ",
        width: 80,
      },
      {
        id: "container20Rf",
        disablePadding: false,
        label: "20RF",
        width: 80,
      },
      {
        id: "container40Rf",
        disablePadding: false,
        label: "40RF",
        width: 80,
      },
      {
        id: "container20Ot",
        disablePadding: false,
        label: "20OT",
        width: 80,
      },
      {
        id: "container40Ot",
        disablePadding: false,
        label: "40OT",
        width: 80,
      },
      {
        id: "container20Fr",
        disablePadding: false,
        label: "20FR",
        width: 80,
      },
      {
        id: "container40Fr",
        disablePadding: false,
        label: "40FR",
        width: 80,
      },
      {
        id: "vessel",
        disablePadding: false,
        label: "VESSEL",
        width: 90,
      },
      {
        id: "TPCompany",
        disablePadding: false,
        label: "Trucker/Parcel company",
        width: 200,
      },
      {
        id: "CCompany",
        disablePadding: false,
        label: "CustomsCompany",
        width: 180,
      },
      {
        id: "etcPartner",
        disablePadding: false,
        label: "Etc.Partner",
        width: 140,
        maxContentLength: 20,
      },
      {
        id: "sellingAmount",
        disablePadding: false,
        label: "Selling Amount",
        width: 160,
      },
      {
        id: "buyingAmount",
        disablePadding: false,
        label: "Buying Amount",
        width: 160,
      },
      {
        id: "profit",
        disablePadding: false,
        label: "Profit",
        width: 120,
      },
      {
        id: "closing",
        disablePadding: false,
        label: "Closing",
        width: 160,
        filter: TableHeadPanel.ClosingFilter,
      },
      {
        id: "bidStatus",
        disablePadding: false,
        label: "의뢰 상태",
        width: 200,
        filter: TableHeadPanel.BidStatusFilter,
      },
      {
        id: "bidProjectStatus",
        disablePadding: false,
        label: "의뢰 세부 상태",
        width: 200,
        filter: TableHeadPanel.BidProjectStatusFilter,
      },
      {
        id: "bidClosingDate",
        disablePadding: false,
        label: "BidId Closing",
        width: 120,
      },
      {
        id: "pic",
        disablePadding: false,
        label: "PIC",
        width: 120,
      },
    ];
  }, [
    TableHeadPanel.AllResetButton,
    TableHeadPanel.IncotermsFilter,
    TableHeadPanel.PaymentFilter,
    TableHeadPanel.CargoTypeFilter,
    TableHeadPanel.ServiceTypeFilter,
    TableHeadPanel.ClosingFilter,
    TableHeadPanel.BidStatusFilter,
    TableHeadPanel.BidProjectStatusFilter,
    isCSVDownloadRequested,
  ]);

  const getFormattedServiceType = useCallback(
    (serviceType: ServiceLowercaseType) => {
      const [startType, endType] = serviceType.split("-");
      const formattedStartType =
        startType.toUpperCase() === "DOOR" ? "Door" : startType.toUpperCase();
      const formattedEndType =
        endType.toUpperCase() === "DOOR" ? "Door" : endType.toUpperCase();

      return `${formattedStartType}-${formattedEndType}`;
    },
    []
  );

  const getArraySplit = useCallback((v) => {
    return v.join(", ");
  }, []);

  const getMaxLengthTooltip = useCallback((v) => {
    if (!v) return "";

    if (v.length > 20) {
      return (
        <Tooltip
          title={
            <Box sx={{ fontSize: 14, textAlign: "center", padding: 1 }}>
              {v}
            </Box>
          }
        >
          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {v}
          </Typography>
        </Tooltip>
      );
    }
    return v;
  }, []);

  const getClosingCheck = useCallback((v) => {
    switch (v) {
      case true:
        return "발행";
      case false:
        return "미발행";
      default:
        return "";
    }
  }, []);

  const mapDataForTable = useCallback(
    (
      data: GET_OPERATION_LIST_RES | GET_OPERATION_EXCEL_LIST_RES | undefined,
      isExcel: boolean
    ) => {
      if (!data?.list) return [];

      return data?.list.map((v) => {
        const row: LegacyTableBodyRow<CellId> = {
          reset: "",
          bidId: v.bidId,
          HBL: v.hBL,
          MBL: v.mBL,
          accountName: isExcel
            ? v.accountName
            : getMaxLengthTooltip(v.accountName),
          incoterms: v.incoterms,
          freightPaymentType: v.freightPaymentType,
          cargoType: v.cargoType,
          serviceType: getFormattedServiceType(v.serviceType),
          ETD: formatDate({
            date: v.fullETD,
            type: "YY_MM_DD",
          }),
          ETA: formatDate({
            date: v.fullETA,
            type: "YY_MM_DD",
          }),
          ATA: v.ATA,
          POL: v.POL,
          POD: v.POD,
          liner: v.liner,
          bookingAgentGlobal: v.foreignBookingAgent
            ? getArraySplit(v.foreignBookingAgent)
            : "",
          bookingAgent: v.domesticBookingAgent
            ? getArraySplit(v.domesticBookingAgent)
            : "",
          rtonSupply: v.rtonSupply,
          cwSupply: v.cwSupply,
          container20Dry: v.container20Dry,
          container40Dry: v.container40Dry,
          container40Hq: v.container40Hq,
          container20Rf: v.container20Rf,
          container40Rf: v.container40Rf,
          container20Ot: v.container20Ot,
          container40Ot: v.container40Ot,
          container20Fr: v.container20Fr,
          container40Fr: v.container40Fr,
          vessel: v.vessel,
          TPCompany: v.inlandPartner,
          CCompany: v.customsPartner,
          etcPartner: v.etcPartner
            ? isExcel
              ? getArraySplit(v.etcPartner)
              : getMaxLengthTooltip(getArraySplit(v.etcPartner))
            : "",
          sellingAmount: v.sellingAmount && v.sellingAmount,
          buyingAmount: v.buyingAmount && v.buyingAmount,
          profit: v.profit && v.profit,
          closing: getClosingCheck(v.closing),
          bidStatus: changeBidStatusNameToKr(v.bidStatus),
          bidProjectStatus:
            v.bidProjectStatus &&
            changeBidProjectStatusNameToKr(v.bidProjectStatus),
          bidClosingDate: formatDate({
            date: v.bidClosingDate,
            type: "YY_MM_DD",
          }),
          pic: v.pic,
        };

        return row;
      });
    },
    [
      getFormattedServiceType,
      getArraySplit,
      getMaxLengthTooltip,
      getClosingCheck,
    ]
  );

  const rows: LegacyTableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(operationList, false);
  }, [operationList, mapDataForTable]);

  const rowsForCSVDownload: LegacyTableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(dataForCSVDownload, true);
  }, [dataForCSVDownload, mapDataForTable]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column" }}
      ref={saveFilterDataRefCallback}
    >
      <Box sx={{ p: 1, background: "#fff" }}>
        <LegacyTable
          headCells={headCells}
          rows={rows}
          pagination={pagination}
          csvDownloadInfo={{
            filename: `운영/정산 현황 리스트(${formatDate({
              date: new Date(),
              type: "YY_MM_DD_HH_mm_ss",
            })})`,
            scope: "all",
            isCSVDownloadRequested,
            setIsCSVDownloadRequested,
            rowsForCSVDownload,
            ResponseHandlerOfFetchDataForCSVDownload,
            statusOfQueryOfFetchDataForCSVDownload,
            removeQueryOfFetchDataForCSVDownload,
          }}
          sx={{ marginLeft: "8px" }}
          isFixedHeader
          showsEmptyTableImage
          isLoading={isLoading}
          toolbarItems={{
            left: [
              <Typography key="total">
                총 {operationList?.total || 0}건
              </Typography>,
            ],
          }}
        />
      </Box>
    </Box>
  );
}
