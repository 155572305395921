import { useCallback } from "react";
import { Button } from "@mui/material";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_SETTLEMENT_QUERY";

function MatchBidIdButton({
  checkBoxArr,
  paymentInvoiceId,
  handleModalClose,
  refetchSettlementDepositCompanies,
}: {
  checkBoxArr: number[];
  paymentInvoiceId: number;
  handleModalClose: () => void;
  refetchSettlementDepositCompanies: () => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const {
    mutate: matchBidIdToDeposit,
    isLoading: isMatchingOngoing,
    ResponseHandler: ResponseHandlerOfMatchBidIdToDeposit,
  } = ADMIN_SETTLEMENT_QUERY.useMatchBidIdToDeposit();

  const handleBidIdMatch = useCallback(() => {
    if (isMatchingOngoing) return;

    matchBidIdToDeposit(
      {
        paymentInvoiceId,
        invoiceIds: checkBoxArr,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("요청에 성공했습니다.");
          refetchSettlementDepositCompanies();
        },

        onError: ({ response }) => {
          if (response?.data?.errorCode === "E183") {
            handleSnackbarOpen(
              "이미 다른 입금 실거래명세서에 매핑된 인보이스입니다.",
              "error"
            );
          }

          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  }, [
    isMatchingOngoing,
    matchBidIdToDeposit,
    paymentInvoiceId,
    checkBoxArr,
    handleSnackbarOpen,
    refetchSettlementDepositCompanies,
  ]);

  return (
    <>
      <Button
        variant="contained"
        onClick={handleBidIdMatch}
        disabled={isMatchingOngoing}
      >
        등록
      </Button>

      {ResponseHandlerOfMatchBidIdToDeposit}
    </>
  );
}

export default MatchBidIdButton;
