import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";

import Content from "./Content";
import Header from "./Header";

function PartnerManagement() {
  return (
    <Layout>
      <Header />

      <Content />
    </Layout>
  );
}

export default PartnerManagement;
