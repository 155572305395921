import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  Fade,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

import { FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD } from "@sellernote/shared/src/constants/forwarding/adminZIndexLevelRecord";
import useTableHeadFilterUnstyled, {
  TableHeadFilterOption,
} from "@sellernote/shared/src/hooks/admin/useTableHeadFilterUnstyled";

export default function useCommonFilter<T, U>({
  headFilterData,
  setHeadFilterData,
  submitFilterKey,
  size,
  optionList,
}: {
  headFilterData: T | null;
  setHeadFilterData: Dispatch<SetStateAction<T | null>>;
  submitFilterKey: keyof T;
  size?: number;
  optionList: TableHeadFilterOption<U>[];
}) {
  // TODO: 운송의뢰, 발주관리 테이블 헤드 필터도 바꾸기
  // TODO: 특수 형태 필터도 호환되게
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isCanceled, setIsCanceled] = useState<boolean>(false);

  const open = Boolean(anchorEl);

  const id = open ? "simple-popper" : undefined;

  const anchorElRef = useRef<HTMLDivElement>(null);

  const { FilterPanel, draftFilter, handleFilterReset } =
    useTableHeadFilterUnstyled({
      filterOptions: optionList,
      canSelectAllButton: true,
    });

  const submitFilter = useMemo(() => {
    const filterObj: Record<string, U | U[] | undefined> = {};
    filterObj[submitFilterKey as string] = draftFilter;

    return filterObj;
  }, [draftFilter, submitFilterKey]);

  const handleSomewhereClick = useCallback(() => {
    setAnchorEl(null);
    setIsCanceled(true);
  }, []);

  const handleSubmitFilter = useCallback(() => {
    if (!submitFilter) return setAnchorEl(null);

    if (submitFilter) {
      setHeadFilterData({ ...(headFilterData as T), ...submitFilter });

      setIsCanceled(false);
      setAnchorEl(null);
    }
  }, [submitFilter, setHeadFilterData, headFilterData]);

  const handleFilterOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);

      if (isCanceled) {
        if (headFilterData) {
          handleFilterReset(headFilterData[submitFilterKey]);
        }

        return setIsCanceled(false);
      } else {
        headFilterData && handleFilterReset(headFilterData[submitFilterKey]);

        return setIsCanceled(false);
      }
    },

    [anchorEl, isCanceled, headFilterData, handleFilterReset, submitFilterKey]
  );

  const filterTriggerPosition = (() => {
    const target = anchorElRef.current;
    if (!target) {
      return null;
    }

    return target.getClientRects()[0];
  })();

  const handleReset = useCallback(() => {
    handleFilterReset([]);

    setHeadFilterData(null);
  }, [handleFilterReset, setHeadFilterData]);

  const handleInsideReset = useCallback(() => {
    handleFilterReset([]);
  }, [handleFilterReset]);

  const HeadFilter = useMemo(() => {
    const DoubleFilter = () => {
      return (
        <>
          <IconButton
            aria-owns={id}
            aria-describedby={id}
            aria-haspopup={true}
            type="button"
            onClick={handleFilterOpen}
            color={
              typeof headFilterData === "object" &&
              headFilterData !== null &&
              headFilterData[submitFilterKey]
                ? "primary"
                : "inherit"
            }
          >
            <FilterAltIcon />
          </IconButton>

          <Dialog open={open}>
            <Popper
              key={id}
              placement="bottom-start"
              anchorEl={anchorEl}
              id={id}
              open={open}
              transition
              style={{
                zIndex: FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.tableHeaderFilter,
              }}
            >
              {({ TransitionProps }) => (
                <ClickAwayListener
                  onClickAway={handleSomewhereClick}
                  disableReactTree
                >
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      sx={{
                        padding: 2,
                        width: "100%",
                        minWidth: size && size > 180 ? size : 180,
                        position: "absolute",
                        maxHeight: 300,
                        top: (filterTriggerPosition?.top ?? 0) + 17,
                        left: (filterTriggerPosition?.left ?? 0) - 40,
                        right: "100%",
                        display: "flex",
                        flexDirection: "column",
                        zIndex:
                          FORWARDING_ADMIN_Z_INDEX_LEVEL_RECORD.tableHeaderFilter,
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Box
                        sx={{
                          overflowY: "scroll",
                          minWidth: size && size > 180 ? size - 20 : 160,
                          maxHeight: 300,
                        }}
                      >
                        {FilterPanel}
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "white",
                          width: "100%",
                          height: 32,
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 2,
                          mt: 1.2,
                          ml: -0.4,
                        }}
                      >
                        <IconButton onClick={() => handleInsideReset()}>
                          <Typography
                            variant="caption"
                            sx={{ display: "inline-flex" }}
                          >
                            <RestartAltIcon />
                            reset
                          </Typography>
                        </IconButton>

                        <Button variant="outlined" onClick={handleSubmitFilter}>
                          적용
                        </Button>
                      </Box>
                    </Paper>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
          </Dialog>
        </>
      );
    };

    return <Box>{DoubleFilter()}</Box>;
  }, [
    id,
    open,
    filterTriggerPosition?.left,
    filterTriggerPosition?.top,
    handleFilterOpen,
    anchorEl,
    FilterPanel,
    handleSomewhereClick,
    headFilterData,
    handleSubmitFilter,
    handleInsideReset,
    size,
    submitFilterKey,
  ]);

  return {
    HeadFilter,
    headFilterData,
    handleReset,
  };
}
