import { useMemo } from "react";

import {
  DepositV2ListItem,
  SettlementFinancialDepositListData,
  SettlementFinancialWithdrawListData,
} from "@sellernote/shared/src/types/forwarding/adminSettlement";

export default function useSettlementTableMemoData({
  settlementDepositData,
  settlementWithdrawalData,
  settlementDepositV2Data,
  paymentInvoiceId,
  type,
}: {
  settlementDepositData?: SettlementFinancialDepositListData[];
  settlementWithdrawalData?: SettlementFinancialWithdrawListData[];
  settlementDepositV2Data?: DepositV2ListItem[];
  paymentInvoiceId: number;
  type: "deposit" | "withdrawal" | "depositV2";
}) {
  const memoData = useMemo(() => {
    if (type === "deposit") {
      if (!settlementDepositData) {
        return [];
      }
      const depositData = settlementDepositData.find((v) => {
        return v.id === paymentInvoiceId;
      });

      if (depositData) {
        return depositData.comment || [];
      }
      return [];
    }

    if (type === "depositV2") {
      if (!settlementDepositV2Data) {
        return [];
      }
      return (
        settlementDepositV2Data.find((v) => {
          return v.id === paymentInvoiceId;
        })?.comment || []
      );
    }

    if (!settlementWithdrawalData) {
      return [];
    }
    const withdrawalData = settlementWithdrawalData.find((v) => {
      return v.id === paymentInvoiceId;
    });

    if (withdrawalData) {
      return withdrawalData.comment || [];
    }
    return [];
  }, [
    paymentInvoiceId,
    settlementDepositData,
    settlementDepositV2Data,
    settlementWithdrawalData,
    type,
  ]);

  return { memoData };
}
