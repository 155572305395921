import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import { sendRequest } from "@sellernote/shared/src/services/request";

const RegisterCoupon = ({
  showsRegisterCouponModal,
  setShowsRegisterCouponModal,
  refetchCouponData,
}: {
  showsRegisterCouponModal: boolean;
  setShowsRegisterCouponModal: React.Dispatch<React.SetStateAction<boolean>>;
  refetchCouponData: () => void;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      campaignName: "",
      comment: "",
      kind: "",
      couponAmount: "",
      discountAmount: "",
      startDate: null,
      endDate: null,
      couponValidDays: "",
    },
  });

  const onSubmit = async (values: any) => {
    try {
      await sendRequest({
        method: "post",
        path: `/coupon/campaign`,
        apiType: "SHIPDA_ADMIN_API",
        data: {
          ...values,
        },
      });
      handleSnackbarOpen("쿠폰을 등록했습니다.");
      refetchCouponData();
      setShowsRegisterCouponModal(false);
    } catch (err) {
      handleSnackbarOpen("쿠폰 등록에 실패했습니다.", "error");
    }
  };

  return (
    <Dialog
      open={showsRegisterCouponModal}
      onClose={() => setShowsRegisterCouponModal(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>캠페인 생성</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="campaignName"
              control={control}
              rules={{ required: "쿠폰명을 입력하세요" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="쿠폰명"
                  fullWidth
                  margin="dense"
                  required
                />
              )}
            />

            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="쿠폰 설명"
                  fullWidth
                  margin="dense"
                />
              )}
            />

            <Controller
              name="kind"
              control={control}
              rules={{ required: "쿠폰 형식을 선택하세요" }}
              render={({ field }) => (
                <Select {...field} fullWidth margin="dense" required>
                  <MenuItem value="oneTime">일회용 쿠폰</MenuItem>
                  <MenuItem value="fixed">일반 쿠폰</MenuItem>
                  <MenuItem value="programatic">자동발급 쿠폰</MenuItem>
                </Select>
              )}
            />

            <Controller
              name="couponAmount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="쿠폰 생성 개수"
                  type="number"
                  fullWidth
                  margin="dense"
                />
              )}
            />

            <Controller
              name="discountAmount"
              control={control}
              rules={{ required: "할인 금액을 입력하세요" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="할인 금액"
                  type="number"
                  fullWidth
                  margin="dense"
                  required
                />
              )}
            />

            <div style={{ display: "flex", gap: 16 }}>
              <Controller
                name="startDate"
                control={control}
                rules={{ required: "캠페인 시작일을 선택하세요" }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label="캠페인 시작일"
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date) => field.onChange(date)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth margin="dense" />
                    )}
                  />
                )}
              />

              <Controller
                name="endDate"
                control={control}
                rules={{ required: "캠페인 종료일을 선택하세요" }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label="캠페인 종료일"
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date) => field.onChange(date)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth margin="dense" />
                    )}
                  />
                )}
              />
            </div>

            <Controller
              name="couponValidDays"
              control={control}
              rules={{ required: "쿠폰 유효기간을 입력하세요" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="쿠폰 유효기간"
                  type="number"
                  fullWidth
                  margin="dense"
                  required
                />
              )}
            />

            <DialogActions>
              <Button onClick={() => setShowsRegisterCouponModal(false)}>
                돌아가기
              </Button>
              <Button type="submit" variant="contained" color="primary">
                쿠폰 생성
              </Button>
            </DialogActions>
          </form>
        </LocalizationProvider>
      </DialogContent>
    </Dialog>
  );
};

export default RegisterCoupon;
