import { Autocomplete, SxProps, TextField } from "@mui/material";

import ADMIN_COMMON_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import { SelectedCountry } from "@sellernote/shared/src/types/forwarding/partnerManagement";

import NO_LABEL from "pages/partner-management/styles";

export default function CountryInCharge({
  defaultList,
  countryList,
  setCountryList,
  hidesLabel = false,
  sx,
}: {
  defaultList?: string[];
  countryList: SelectedCountry[];
  setCountryList: (val: SelectedCountry[]) => void;
  hidesLabel?: boolean;
  sx?: SxProps;
}) {
  const { data: countryData } = ADMIN_COMMON_QUERY.useGetCountryList();

  const totalCountryList = (() => {
    if (countryList.find((country) => country.value === "ALL")) return [];

    const countryListToOption =
      countryData?.map(({ nameKR, name }) => ({
        label: nameKR,
        value: name,
      })) ?? [];

    return [{ label: "전체", value: "ALL" }, ...countryListToOption];
  })();

  const defaultValue = (() => {
    if (!defaultList) return [];

    const hasALL = defaultList.includes("ALL");

    const filteredList =
      countryData?.filter(({ name }) => defaultList.includes(name)) ?? [];

    const filteredListWithFormat = filteredList.map(({ nameKR, name }) => ({
      label: nameKR,
      value: name,
    }));

    if (hasALL) {
      return [{ label: "전체", value: "ALL" }, ...filteredListWithFormat];
    }

    return filteredListWithFormat;
  })();

  return (
    <Autocomplete
      multiple
      size="small"
      sx={{ minWidth: 150 }}
      key={defaultValue.length}
      defaultValue={defaultValue}
      options={totalCountryList}
      getOptionLabel={({ label }) => label}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          label={hidesLabel ? "" : "담당 국가"}
          sx={{ ...(hidesLabel ? NO_LABEL : {}), ...sx }}
        />
      )}
      onChange={(_, country) => setCountryList([...country])}
    />
  );
}
