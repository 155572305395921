import ADMIN_ACCOUNT_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_ACCOUNT_QUERY";
import { useDebounce } from "@sellernote/shared/src/utils/common/hook";

import { formatBRN } from "../../utils";

const PAGE = 0;
const PER_PAGE = 1500;

function useHandleAccountList(BRN: string) {
  const hasBRNValue = BRN?.length === 12;

  const debouncedBRN = useDebounce(BRN, 500);

  const { data } = ADMIN_ACCOUNT_QUERY.useGetAccountList(
    {
      page: PAGE,
      perPage: PER_PAGE,
      BRN: debouncedBRN,
    },
    { enabled: hasBRNValue }
  );

  const optionList = (() => {
    const accountList = data?.list ?? [];

    return accountList.map((account) => ({
      id: account.id,
      label: `${formatBRN(account.BRN)} ${account.name}`,
      value: account.BRN,
    }));
  })();

  return {
    optionList,
  };
}

export default useHandleAccountList;
