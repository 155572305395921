import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";
import useVesselScheduleTable from "@sellernote/shared/src/hooks/admin/useVesselScheduleTable";

const VesselScheduleFcl = () => {
  const { VesselScheduleTable, ResponseHandlerOfGetVesselScheduleList } =
    useVesselScheduleTable({ freightType: "FCL", includesWarehouse: false });

  return (
    <Layout breadcrumbs={[]} title={"운항 스케줄 FCL"}>
      {VesselScheduleTable}

      {ResponseHandlerOfGetVesselScheduleList}
    </Layout>
  );
};

export default VesselScheduleFcl;
