import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Button, Card, List, ListItem, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

import CommentList from "@sellernote/shared/src/admin-ui/components/CommentList";
import { I18N_KEY_BY_PO_STATUS } from "@sellernote/shared/src/constants/forwarding/purchaseOrder";
import { Port } from "@sellernote/shared/src/types/common/common";
import {
  AdminOrderDetail,
  POProjectStatus,
  POProjectStatusArrayItem,
} from "@sellernote/shared/src/types/forwarding/adminOrder";
import { formatDate } from "@sellernote/shared/src/utils/common/date";

import EditPONumber from "./EditPONumber";
import OrderCreateTable from "./OrderCreateTable";
import OrderPartnerListModal from "./OrderPartnerListModal";
import OrderUploadDocumentTable from "./OrderUploadDocumentTable";
import SmeInfo from "./SmeInfo";
import useExporterButtons from "./useExporterButtons";
import useOrderCommentList from "./useOrderCommentList";
import usePartnerButtons from "./usePartnerButtons";

export default function AdminOrderDetailDescriptionCard({
  orderDetail,
  portList,
}: {
  orderDetail: AdminOrderDetail;
  portList: Port[] | undefined;
}) {
  const { t } = useTranslation();

  const location = useLocation();

  const history = useHistory();

  function getPOProjectStatusKr(param: POProjectStatus) {
    switch (param) {
      case "none":
        return "파트너 컨택 전";
      case "contactPartner":
        return "운송의뢰 생성 전";
      case "completed":
        return "운송 완료";
      case "canceled":
        return "취소";
      case "failed":
        return "실패";
      default:
        return param;
    }
  }

  const {
    handleCommentInputChange,
    handleCommentUpdate,
    comment,
    ResponseHandler: ResponseHandlerOfOrderCommentList,
  } = useOrderCommentList({
    poId: orderDetail.id,
  });

  const {
    ExporterButtons,
    editExporterInfo,
    ExporterInput,
    ResponseHandler: ResponseHandlerOfExporterButtons,
  } = useExporterButtons(orderDetail?.id, orderDetail?.exporter);

  const {
    EditPartnerAndContactButton,
    showsOrderPartnerModal,
    setShowsOrderPartnerModal,
    businessArea,
    ResponseHandler: ResponseHandlerOfPartnerButtons,
  } = usePartnerButtons(orderDetail?.id, orderDetail);

  const getProjectStatusFilterTypography = useCallback(
    (poFinishedProjectStatus: POProjectStatusArrayItem[]) => {
      if (typeof poFinishedProjectStatus === "undefined") return null;
      const completedBid = poFinishedProjectStatus.find(
        (item: POProjectStatusArrayItem) => item.status === "completed"
      );
      const failedBid = poFinishedProjectStatus.find(
        (item: POProjectStatusArrayItem) => item.status === "failed"
      );
      const canceledBid = poFinishedProjectStatus.find(
        (item: POProjectStatusArrayItem) => item.status === "canceled"
      );

      const completedResult = `${completedBid?.length} / ${orderDetail.bids.length}`;
      const failedResult = `${failedBid?.length} / ${orderDetail.bids.length}`;
      const canceledResult = `${canceledBid?.length} / ${orderDetail.bids.length}`;

      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1" color={blue[500]}>
            운송 완료 : {completedResult}
          </Typography>
          <Typography variant="body1">취소 : {canceledResult}</Typography>
          <Typography variant="body1">거절 : {failedResult}</Typography>
        </Box>
      );
    },
    [orderDetail.bids.length]
  );

  const getStatusDescription = useCallback(() => {
    if (
      orderDetail.poStatus === "waiting" ||
      orderDetail.poStatus === "registered" ||
      location.pathname.includes("/sub")
    ) {
      return (
        <List
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            p: 0,
          }}
        >
          <ListItem sx={{ alignItems: "flex-start", p: 0 }}>
            <Typography>상태:</Typography>&nbsp;
            <Typography>
              {location.pathname.includes("/sub")
                ? "확인 중"
                : t(I18N_KEY_BY_PO_STATUS[orderDetail?.poStatus])}
            </Typography>
          </ListItem>

          <ListItem sx={{ p: 0 }}>
            <Typography sx={{ alignSelf: "flex-start" }}>세부 상태:</Typography>
            &nbsp;
            <Typography>
              {location.pathname.includes("/sub")
                ? "운송의뢰 생성 전"
                : getPOProjectStatusKr(orderDetail?.poProjectStatus)}
            </Typography>
          </ListItem>
        </List>
      );
    }

    return "";
  }, [
    location.pathname,
    orderDetail?.poProjectStatus,
    orderDetail.poStatus,
    t,
  ]);

  const getProductNames = (productList: AdminOrderDetail["productNames"]) => {
    if (!productList.length) return "-";

    return (
      <>
        {productList.map((product, index) => {
          return (
            <Box key={product}>
              <Typography fontWeight={600} display="inline">
                품목{index + 1}:{" "}
              </Typography>

              <Typography display="inline">{product}</Typography>
            </Box>
          );
        })}
      </>
    );
  };

  // BIG 유저가 등록한 경우 해당 값들을 null로 옴
  const isSmeUserPO = Boolean(
    orderDetail.pol && orderDetail.pod && orderDetail.incoterms
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box sx={{ background: "#fff", p: 1 }}>
          <Typography variant="h6">상세 정보</Typography>
          <List
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              p: 0,
            }}
          >
            <ListItem sx={{ p: 0, height: "36px" }}>
              <Typography>등록번호:</Typography>&nbsp;
              <Typography>{orderDetail?.id}</Typography>
            </ListItem>

            <ListItem sx={{ p: 0, height: "36px" }}>
              <Typography>PO번호:</Typography>&nbsp;
              <Typography>
                {location.pathname.includes("/sub")
                  ? `NY-${orderDetail.subPoNumber} / ${orderDetail?.poNumber}`
                  : orderDetail?.poNumber}
              </Typography>
              <EditPONumber
                poId={orderDetail?.id}
                poNumber={orderDetail?.poNumber}
              />
            </ListItem>

            <ListItem sx={{ p: 0, height: "36px" }}>
              <Typography>생성일:</Typography>&nbsp;
              <Typography>
                {formatDate({
                  date: orderDetail?.createdAt,
                  type: "YY_MM_DD_ddd",
                })}
              </Typography>
            </ListItem>

            <ListItem sx={{ p: 0, height: "36px" }}>
              <Typography>화주명:</Typography>&nbsp;
              <Button
                variant="text"
                onClick={() =>
                  history.push(`/users/list/${orderDetail.userId}`)
                }
              >
                {orderDetail?.userCompany}
              </Button>
            </ListItem>

            <ListItem sx={{ p: 0, height: "36px" }}>
              <Typography>포워딩 담당자:</Typography>&nbsp;
              <Typography>{orderDetail.managerName}</Typography>
            </ListItem>

            <ListItem sx={{ p: 0, height: "36px" }}>
              <Typography>팀명:</Typography>&nbsp;
              <Typography>{orderDetail?.teamName}</Typography>
            </ListItem>

            <ListItem sx={{ p: 0, height: "36px" }}>
              <Typography>담당자 이름:</Typography>&nbsp;
              <Typography>{orderDetail?.userCompany}</Typography>
            </ListItem>

            <ListItem sx={{ p: 0, height: "36px" }}>
              <Typography>연락처:</Typography>&nbsp;
              <Typography>{orderDetail?.userPhone}</Typography>
            </ListItem>

            <ListItem sx={{ p: 0, height: "36px" }}>
              <Typography>이메일:</Typography>&nbsp;
              <Typography>{orderDetail?.userEmail}</Typography>
            </ListItem>
          </List>

          <List>
            <ListItem
              sx={{ p: 0, minHeight: "36px", alignItems: "flex-start" }}
            >
              <Typography>상품명: &nbsp;</Typography>

              <Typography>
                {getProductNames(orderDetail?.productNames)}
              </Typography>
            </ListItem>
          </List>

          <List>
            <ListItem
              sx={{ p: 0, minHeight: "36px", alignItems: "flex-start" }}
            >
              <Typography>메모: &nbsp;</Typography>

              <Typography>{orderDetail?.memo ?? "-"}</Typography>
            </ListItem>
          </List>

          {getStatusDescription()}

          <List
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              p: 0,
            }}
          >
            <ListItem sx={{ alignItems: "flex-start", p: 0 }}>
              <Typography>상태:</Typography>&nbsp;
              <Typography>
                {t(I18N_KEY_BY_PO_STATUS[orderDetail.poStatus])}
              </Typography>
            </ListItem>

            <ListItem sx={{ p: 0 }}>
              <Typography sx={{ alignSelf: "flex-start" }}>
                세부 상태:
              </Typography>
              &nbsp;
              {getProjectStatusFilterTypography(
                orderDetail.poFinishedProjectStatus
              )}
            </ListItem>
          </List>

          {(orderDetail.poStatus === "inProgress" &&
            !location.pathname.includes("/sub")) ||
            (orderDetail.poStatus === "finished" && (
              <List
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(5, 1fr)",
                  p: 0,
                }}
              >
                <ListItem sx={{ p: 0 }}>
                  <Typography>상태:</Typography>&nbsp;
                  <Typography>
                    {t(I18N_KEY_BY_PO_STATUS[orderDetail.poStatus])}
                  </Typography>
                </ListItem>

                <ListItem sx={{ p: 0 }}>
                  <Typography>세부 상태:</Typography>&nbsp;
                  {getProjectStatusFilterTypography(
                    orderDetail.poFinishedProjectStatus
                  )}
                </ListItem>

                <ListItem sx={{ p: 0 }}>
                  <Typography>운송 상태:</Typography>&nbsp;
                  <Typography>
                    {t(I18N_KEY_BY_PO_STATUS[orderDetail.poStatus])}
                  </Typography>
                </ListItem>
              </List>
            ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 1,
            background: "#fff",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">수출자 정보</Typography>

            {ExporterButtons}
          </Box>

          <List
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              p: 0,
            }}
          >
            <ListItem sx={{ p: 0, height: "32px" }}>
              <Typography>수출자명:</Typography>
              {editExporterInfo ? (
                ExporterInput("companyName")
              ) : (
                <Typography>{orderDetail?.exporter.companyName}</Typography>
              )}
            </ListItem>

            <ListItem sx={{ p: 0, height: "32px" }}>
              <Typography>담당자 이름:</Typography>
              {editExporterInfo ? (
                ExporterInput("personName")
              ) : (
                <Typography>{orderDetail?.exporter.personName}</Typography>
              )}
            </ListItem>

            <ListItem sx={{ p: 0, height: "32px" }}>
              <Typography>연락처:</Typography>
              {editExporterInfo ? (
                ExporterInput("personPhone")
              ) : (
                <Typography>{orderDetail?.exporter.personPhone}</Typography>
              )}
            </ListItem>

            <ListItem sx={{ p: 0, height: "32px" }}>
              <Typography>이메일:</Typography>
              {editExporterInfo ? (
                ExporterInput("personEmail")
              ) : (
                <Typography>{orderDetail?.exporter.personEmail}</Typography>
              )}
            </ListItem>
          </List>
        </Box>

        <Box sx={{ background: "#fff", p: 1 }}>
          <Typography variant="h6">파트너 정보</Typography>

          <List
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: 2,
              p: 0,
            }}
          >
            <ListItem
              sx={{
                display: "flex",
                gap: 1,
                p: 0,
                flex: "0 0 auto",
                width: "auto",
              }}
            >
              <Typography>국내 파트너:</Typography>

              <Typography>
                {orderDetail?.domesticPartner?.name || "파트너 미지정"}
              </Typography>

              {EditPartnerAndContactButton({
                partnerDomain: "domestic",
                hasPartner: orderDetail?.domesticPartner?.name,
              })}
            </ListItem>

            <ListItem
              sx={{
                display: "flex",
                gap: 1,
                p: 0,
                flex: "0 0 auto",
                width: "auto",
              }}
            >
              <Typography>해외 파트너:</Typography>

              <Typography>
                {orderDetail?.foreignPartner?.name || "파트너 미지정"}
              </Typography>

              {EditPartnerAndContactButton({
                partnerDomain: "foreign",
                hasPartner: orderDetail?.foreignPartner?.name,
              })}
            </ListItem>
          </List>
        </Box>

        <Box sx={{ background: "#fff", p: 1 }}>
          <CommentList
            commentPageType="order"
            onCommentInputChange={handleCommentInputChange}
            onCommentUpdate={handleCommentUpdate}
            comment={comment}
            commentDataList={orderDetail.comment}
          />
        </Box>

        <Box sx={{ background: "#fff", p: 1 }}>
          <OrderCreateTable orderDetail={orderDetail} portList={portList} />
        </Box>

        <Box sx={{ background: "#fff", p: 1 }}>
          <OrderUploadDocumentTable {...orderDetail} />
        </Box>
      </Box>

      {/* SME로 만든 발주이고 상태가 확인 중(waiting)부터 표시  */}
      {isSmeUserPO && orderDetail.poStatus !== "registered" && (
        <Box sx={{ background: "#fff", p: 1 }}>
          <Card sx={{ padding: 3 }}>
            <SmeInfo orderDetail={orderDetail} />
          </Card>
        </Box>
      )}

      {showsOrderPartnerModal && (
        <OrderPartnerListModal
          POId={orderDetail.id}
          businessArea={businessArea}
          setShowsOrderPartnerModal={setShowsOrderPartnerModal}
          showsOrderPartnerModal={showsOrderPartnerModal}
        />
      )}

      {ResponseHandlerOfExporterButtons}
      {ResponseHandlerOfOrderCommentList}
      {ResponseHandlerOfPartnerButtons}
    </>
  );
}
