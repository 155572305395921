import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Autocomplete, TextField } from "@mui/material";

import PARTNER_QUERY from "@sellernote/shared/src/queries/forwarding/PARTNER_QUERY";
import { useDebounce } from "@sellernote/shared/src/utils/common/hook";

import usePartnerCompanyNameAutoCompleteOptions, {
  PartnerCompanyNameListForAutoComplete,
} from "./usePartnerCompanyNameAutoCompleteOptions";

function SearchWithdrawalPartner({
  partnerId,
  setPartnerId,
}: {
  partnerId: number;
  setPartnerId: Dispatch<SetStateAction<number>>;
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { partnerList } = PARTNER_QUERY.useGetPartnerList({
    page: 0,
    enabled: true,
    pageFlag: false,
    companyName: debouncedSearchTerm,
    region: "KR",
  });

  const autoCompleteLabel = useMemo(() => {
    const companyData = partnerList?.find((v) => {
      return v.id === partnerId;
    });

    if (!searchTerm && companyData) {
      return `${companyData.name}[${companyData.BRN}]`;
    }

    return searchTerm;
  }, [partnerId, partnerList, searchTerm]);

  const autoCompleteValue = useMemo(() => {
    const companyData = partnerList?.find((v) => {
      return v.id === partnerId;
    });

    if (!searchTerm && companyData) {
      return companyData.id;
    }

    return partnerId;
  }, [partnerId, partnerList, searchTerm]);

  const handleSearchTermChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const { partnerCompanyNameListForAutoComplete } =
    usePartnerCompanyNameAutoCompleteOptions({
      partnerList,
    });

  const handlePurchasePartnerChange = useCallback(
    ({
      event,
      selectValue,
    }: {
      event: SyntheticEvent<Element, Event>;
      selectValue: PartnerCompanyNameListForAutoComplete | null;
    }) => {
      setSearchTerm(selectValue?.label || "");
      setPartnerId(selectValue?.value || 0);
    },
    [setPartnerId]
  );

  return (
    <Autocomplete
      onChange={(
        event,
        newValue: PartnerCompanyNameListForAutoComplete | null
      ) => handlePurchasePartnerChange({ event, selectValue: newValue })}
      value={{
        label: autoCompleteLabel,
        value: autoCompleteValue,
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={partnerCompanyNameListForAutoComplete}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} onChange={handleSearchTermChange} />
      )}
    />
  );
}
export default SearchWithdrawalPartner;
