import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { blue } from "@mui/material/colors";

import { SearchTotalStatus } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/businessManagement";
export default function BusinessManagementExtraTable({
  operationList,
}: {
  operationList: SearchTotalStatus | undefined;
}) {
  const getProfitAmount = (
    sellingAmountTotal: number | undefined,
    buyingAmountTotal: number | undefined
  ) => {
    const result =
      sellingAmountTotal &&
      buyingAmountTotal &&
      sellingAmountTotal - buyingAmountTotal;
    return result && result.toLocaleString();
  };

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <TableContainer
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: 2,
            flexWrap: "wrap",
          }}
        >
          <Table sx={{ maxWidth: "fit-content" }}>
            <TableHead sx={{ background: blue["100"] }}>
              <TableRow>
                <TableCell>R.TON</TableCell>

                <TableCell>C.W</TableCell>

                <TableCell>20DRY</TableCell>

                <TableCell>40DRY</TableCell>

                <TableCell>40HQ</TableCell>

                <TableCell>20RF</TableCell>

                <TableCell>40RF</TableCell>

                <TableCell>20OT</TableCell>

                <TableCell>40OT</TableCell>

                <TableCell>20FR</TableCell>

                <TableCell>40FR</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>
                  {operationList?.rtonSupplyTotal.toFixed(2) || 0}
                </TableCell>

                <TableCell>
                  {operationList?.cwSupplyTotal.toFixed(2) || 0}
                </TableCell>

                <TableCell>{operationList?.container20DryTotal || 0}</TableCell>

                <TableCell>{operationList?.container40DryTotal || 0}</TableCell>

                <TableCell>{operationList?.container40HqTotal || 0}</TableCell>

                <TableCell>{operationList?.container20RfTotal || 0}</TableCell>

                <TableCell>{operationList?.container40RfTotal || 0}</TableCell>

                <TableCell>{operationList?.container20OtTotal || 0}</TableCell>

                <TableCell>{operationList?.container40OtTotal || 0}</TableCell>

                <TableCell>{operationList?.container20FrTotal || 0}</TableCell>

                <TableCell>{operationList?.container40FrTotal || 0}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Table sx={{ maxWidth: "fit-content" }} aria-label="simple table">
            <TableHead sx={{ background: blue["100"] }}>
              <TableRow>
                <TableCell>Selling Amount</TableCell>

                <TableCell>Buying Amount</TableCell>

                <TableCell>Profit</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>
                  ₩{operationList?.sellingAmountTotal.toLocaleString() || 0}
                </TableCell>

                <TableCell>
                  ₩{operationList?.buyingAmountTotal.toLocaleString() || 0}
                </TableCell>

                <TableCell>
                  ₩
                  {getProfitAmount(
                    operationList?.sellingAmountTotal,
                    operationList?.buyingAmountTotal
                  ) || 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}
