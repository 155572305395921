import { Box, Button, Typography } from "@mui/material";

import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import { DuplicatePartnerInfo } from "@sellernote/shared/src/types/forwarding/partnerManagement";

export default function DuplicatePartnerModal({
  opensModal,
  onModalClose,
  duplicatePartnerList,
  onCreatePartner,
}: {
  opensModal: boolean;
  onModalClose: () => void;
  duplicatePartnerList: DuplicatePartnerInfo[];
  onCreatePartner: () => void;
}) {
  const partnerNameInOrder = (() => {
    const partnerLength = duplicatePartnerList.length;

    if (partnerLength <= 3) {
      return duplicatePartnerList.map(({ name }) => name).join(", ");
    }

    const firstThree = duplicatePartnerList
      .slice(0, 3)
      .map(({ name }) => name)
      .join(", ");
    return `${firstThree} 외 ${partnerLength - 3}개`;
  })();

  return (
    <Modal
      isOpened={opensModal}
      handleClose={onModalClose}
      modalBody={
        <Box
          display={"flex"}
          flexDirection={"column"}
          textAlign={"center"}
          gap={4}
          minWidth={490}
        >
          <Typography component={"span"} fontWeight={"bold"}>
            입력하신 담당자 메일주소의 도메인이 아래 파트너들과 중복됩니다.
          </Typography>

          <Box>{partnerNameInOrder}</Box>

          <Typography component={"span"}>그래도 등록하시겠습니까?</Typography>

          <Button variant="contained" fullWidth onClick={onCreatePartner}>
            등록하기
          </Button>
        </Box>
      }
    />
  );
}
