import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useAtomValue } from "jotai";
import { useSetRecoilState } from "recoil";

import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/shared/src/admin-ui/components/Table";
import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/shared/src/jotaiStates/auth";
import ADMIN_DASHBOARD_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_DASHBOARD_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/shared/src/states/forwarding/invoice";
import { formatDate } from "@sellernote/shared/src/utils/common/date";
import { isEmptyObjectOrArray } from "@sellernote/shared/src/utils/common/etc";
import { replaceEmptyToDash } from "@sellernote/shared/src/utils/common/string";

import Loading from "../../components/Loading";

type OverallTrackingInfo =
  | "bl"
  | "requestId"
  | "delayedDate"
  | "port"
  | "shipInfo"
  | "targetedArrivalDate"
  | "plannedArrivalDate"
  | "manager"
  | "updatedScheduleAt";

const Dashboard = () => {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const history = useHistory();

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const [tableFilterValue, setTableFilterValue] = useState("individual");

  const { data, isFetching } = ADMIN_DASHBOARD_QUERY.useGetDashboardData();

  const { data: overallTracking } =
    ADMIN_DASHBOARD_QUERY.useGetOverallTracking();

  const headCells: TableHeadCell<OverallTrackingInfo>[] = useMemo(() => {
    return [
      {
        id: "bl",
        disablePadding: false,
        label: "BL",
      },
      {
        id: "requestId",
        disablePadding: false,
        label: "의뢰 ID",
      },
      {
        id: "delayedDate",
        disablePadding: false,
        label: "지연된 날짜",
      },
      {
        id: "port",
        disablePadding: false,
        label: "POL-POD",
      },
      {
        id: "shipInfo",
        disablePadding: false,
        label: "배 이름 / 항차 / IMO",
      },
      {
        // ETA
        id: "targetedArrivalDate",
        disablePadding: false,
        label: "도착일",
      },
      {
        // Planned ETA
        id: "plannedArrivalDate",
        disablePadding: false,
        label: "계획된 도착일",
      },
      {
        id: "manager",
        disablePadding: false,
        label: "담당 매니저",
      },
      {
        id: "updatedScheduleAt",
        disablePadding: false,
        label: "최종 업데이트",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!overallTracking) return [];

    return (
      overallTracking
        .map((v) => {
          const row: TableBodyRow<OverallTrackingInfo> = {
            bl: v.blNo,
            requestId: (
              <Button
                onClick={() => {
                  setTrelloCardId(v.shipmentId);
                  history.push("/trello/general");
                }}
              >
                {v.shipmentId}
              </Button>
            ),
            delayedDate: !v.delay ? "-" : `${v.delay}일`,
            port: `${v.pol}-${v.pod}`,
            shipInfo: `${v.shipName} / ${replaceEmptyToDash(v.voyagerNo)} / ${
              v.shipIMO
            }`,
            targetedArrivalDate: formatDate({
              date: v.ETA,
              type: "YY_MM_DD",
            }),
            plannedArrivalDate: formatDate({
              date: v.plannedETA,
              type: "YY_MM_DD",
            }),
            manager: v.manager,
            updatedScheduleAt: formatDate({
              date: v.updatedScheduleAt,
              type: "YY_MM_DD_HH_mm",
            }),
          };

          return row;
        })
        // 테이블 개인/전체 필터 값을 반영
        .filter((row) => {
          if (tableFilterValue === "individual") {
            return row.manager === currentAdminAuthInfo.name;
          }
          return row;
        })
    );
  }, [
    currentAdminAuthInfo.name,
    history,
    overallTracking,
    setTrelloCardId,
    tableFilterValue,
  ]);

  if (!data || isFetching) {
    return (
      <Layout breadcrumbs={[]} title={"대쉬보드"}>
        <Loading></Loading>
      </Layout>
    );
  }

  return (
    <Layout breadcrumbs={[]} title={"대시보드"}>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Card
          sx={{ width: 275, height: 200, cursor: "pointer" }}
          onClick={() => history.push("/users/list")}
        >
          <CardContent>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              총 가입자 수
            </Typography>

            <Typography>{data.usersStat.total}</Typography>
            <Divider />

            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              오늘 가입자 수
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <PersonIcon />

              <Typography>{data.usersStat.today}</Typography>
            </Box>

            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              이번 주 가입자 수
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <PersonIcon />

              <Typography>{data.usersStat.week}</Typography>
            </Box>
          </CardContent>
        </Card>

        <Card
          sx={{ width: 275, cursor: "pointer" }}
          onClick={() => history.push("/bid")}
        >
          <CardContent>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              전체 의뢰 수
            </Typography>

            <Typography>{data.bidsStat.total}</Typography>
            <Divider />

            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              오늘 등록된 의뢰
            </Typography>

            <Box>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                FCL
              </Typography>

              <Typography>{data.bidsStat.fcl}</Typography>
            </Box>

            <Box>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                LCL
              </Typography>

              <Typography>{data.bidsStat.lcl}</Typography>
            </Box>

            <Box>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                AIR
              </Typography>

              <Typography>{data.bidsStat.air}</Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <div style={{ marginTop: 30 }}>
        <FormControl>
          <FormLabel id="row-radio-buttons-group-label">필터</FormLabel>
          <RadioGroup
            row
            aria-labelledby="row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={tableFilterValue}
            onChange={(e) => setTableFilterValue(e.target.value)}
          >
            <FormControlLabel
              value="individual"
              control={<Radio />}
              label="개인"
            />
            <FormControlLabel value="all" control={<Radio />} label="전체" />
          </RadioGroup>
        </FormControl>

        <Table
          title="지연 중인 운송 현황"
          headCells={headCells}
          rows={rows}
          showsNoDataFound={isEmptyObjectOrArray(rows)}
        />
      </div>
    </Layout>
  );
};

export default Dashboard;
