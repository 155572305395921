import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Grid, TextField, Typography } from "@mui/material";

import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import ADMIN_PROMOTION_QUERY, {
  ADMIN_PROMOTION_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_PROMOTION_QUERY";

const ChangeBidInputModal = ({
  bidId,
  listId,
  setShowChangeBidInputModal,
  showChangeBidInputModal,
  isPromotion,
}: {
  bidId: number | null;
  listId: number;
  setShowChangeBidInputModal: React.Dispatch<React.SetStateAction<boolean>>;
  showChangeBidInputModal: boolean;
  isPromotion: boolean;
}) => {
  const queryClient = useQueryClient();

  const [bidIdTextFieldValue, setBidIdTextFieldValue] = useState(bidId);

  const [showsDeleteBidIdModal, setShowsDeleteBidIdModal] = useState(false);

  const {
    mutate: editPromotionBidId,
    ResponseHandler: ResponseHandlerOfEditPromotion,
  } = ADMIN_PROMOTION_QUERY.useEditPromotionBidId({
    successModalInfo: {
      handleConfirmSuccess: () => {
        setShowChangeBidInputModal(false);
        queryClient.invalidateQueries(ADMIN_PROMOTION_QUERY_KEY_GEN.all());
      },
      customizeMessage: () => ({
        title: isPromotion
          ? "의뢰번호를 등록했습니다."
          : "의뢰번호를 등록했습니다. 해당 신청번호는 프로모션  제외대상입니다.",
      }),
    },
  });

  const {
    mutate: deletePromotionBidId,
    ResponseHandler: ResponseHandlerOfDeletePromotion,
  } = ADMIN_PROMOTION_QUERY.useDeletePromotionBidId({
    successModalInfo: {
      handleConfirmSuccess: () => {
        setShowChangeBidInputModal(false);
        queryClient.invalidateQueries(ADMIN_PROMOTION_QUERY_KEY_GEN.all());
      },
      customizeMessage: () => ({
        title: "의뢰번호를 삭제했습니다.",
      }),
    },
  });

  const handlePromotionBidIdEdit = useCallback(() => {
    editPromotionBidId({
      bidId: !bidIdTextFieldValue ? null : bidIdTextFieldValue,
      pathParams: { promotionDetailId: listId },
    });
  }, [editPromotionBidId, bidIdTextFieldValue, listId]);

  const handleModalClose = useCallback(() => {
    setShowChangeBidInputModal(false);
  }, [setShowChangeBidInputModal]);

  return (
    <Modal
      isOpened={showChangeBidInputModal}
      handleClose={handleModalClose}
      modalBody={
        <>
          <TextField
            label="의뢰번호"
            type="numeric"
            value={!bidIdTextFieldValue ? "" : bidIdTextFieldValue}
            onChange={(e) => setBidIdTextFieldValue(parseInt(e.target.value))}
          />

          <Button onClick={handlePromotionBidIdEdit}>등록</Button>

          {bidId && (
            <Button
              color="error"
              onClick={() => setShowsDeleteBidIdModal(true)}
            >
              삭제
            </Button>
          )}

          {ResponseHandlerOfEditPromotion}

          {ResponseHandlerOfDeletePromotion}

          <Modal
            isOpened={showsDeleteBidIdModal}
            handleClose={() => setShowsDeleteBidIdModal(false)}
            modalBody={
              <>
                <Grid
                  container
                  spacing={4}
                  direction="column"
                  alignItems={"center"}
                >
                  <Grid item>
                    <Typography variant="h6" component="div">
                      의뢰번호를 삭제하시겠습니까?
                    </Typography>
                  </Grid>

                  <Grid container item justifyContent={"center"} spacing={2}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          deletePromotionBidId({
                            _customPath: `/promotion/bid/${listId}`,
                          });
                        }}
                      >
                        예
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => setShowsDeleteBidIdModal(false)}
                      >
                        아니오
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            }
          />

          {ResponseHandlerOfEditPromotion}

          {ResponseHandlerOfDeletePromotion}
        </>
      }
    />
  );
};

export default ChangeBidInputModal;
