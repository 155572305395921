import Modal from "@sellernote/shared/src/admin-ui/components/Modal";

import CreatePartnerModalBody from "./CreatePartnerModalBody";

export default function CreatePartnerModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Modal
      sx={{ ".MuiBox-root": { padding: 1 } }}
      modalBodySx={{ overflow: "visible", maxHeight: "none" }}
      isOpened={isOpen}
      handleClose={onClose}
      modalBody={<CreatePartnerModalBody onModalClose={onClose} />}
    />
  );
}
