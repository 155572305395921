import { useQueryClient } from "react-query";
import { useAtomValue } from "jotai";
import { FORWARDING_PARTNER_MANAGEMENT_ATOMS } from "jotaiStates/partnerManagement";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_PARTNER_MANAGEMENT_QUERY, {
  ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_PARTNER_MANAGEMENT_QUERY";

export default function useDeactivatePartner() {
  const { handleSnackbarOpen } = useSnackbar();
  const queryClient = useQueryClient();

  const { page, perPage } = useAtomValue(
    FORWARDING_PARTNER_MANAGEMENT_ATOMS.SEARCH_PARAMS
  );

  const onSuccess = (isDeactivated: boolean) => {
    handleSnackbarOpen(
      `파트너가 ${isDeactivated ? "활성화" : "비활성화"} 됐습니다.`
    );

    queryClient.invalidateQueries(
      ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN.getPartnerList({ page, perPage })
    );
  };

  const { mutate: deactivatePartner } =
    ADMIN_PARTNER_MANAGEMENT_QUERY.useDeactivatePartner();

  const handlePartnerDeactivate = (
    partnerId: number,
    isDeactivated: boolean
  ) => {
    deactivatePartner(
      { deactivate: !isDeactivated, pathParams: { partnerId } },
      { onSuccess: () => onSuccess(isDeactivated) }
    );
  };

  return { handlePartnerDeactivate };
}
