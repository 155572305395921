import { Dispatch, SetStateAction } from "react";
import { Box, Divider } from "@mui/material";

import {
  GET_PARTNER_DETAIL_RES,
  UPDATE_PARTNER_REQ,
} from "@sellernote/shared/src/api-interfaces/shipda-api/admin/partnerManagement";
import { MappedPartnerManagerInfo } from "@sellernote/shared/src/types/forwarding/partnerManagement";

import AccountInfo from "./AccountInfo";
import ManagerInfo from "./ManagerInfo";
import PartnerInfo from "./PartnerInfo";

export default function PartnerDetailBody({
  data,
  refetchDetail,
  partnerInfo,
  setPartnerInfo,
  managerList,
  setManagerList,
}: {
  data: GET_PARTNER_DETAIL_RES;
  refetchDetail: () => void;
  partnerInfo: Partial<UPDATE_PARTNER_REQ>;
  setPartnerInfo: Dispatch<SetStateAction<Partial<UPDATE_PARTNER_REQ>>>;
  managerList: MappedPartnerManagerInfo[];
  setManagerList: Dispatch<SetStateAction<MappedPartnerManagerInfo[]>>;
}) {
  return (
    <Box display={"flex"} gap={2} p={0}>
      {partnerInfo && (
        <PartnerInfo
          data={data}
          refetchDetail={refetchDetail}
          partnerInfo={partnerInfo}
          setPartnerInfo={setPartnerInfo}
        />
      )}

      <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 0 }} />

      <Box display={"flex"} flexDirection={"column"} gap={4}>
        <AccountInfo accountList={data.financialAccounts} />

        <ManagerInfo
          key={data.businessArea}
          businessArea={data.businessArea}
          managerList={managerList}
          setManagerList={setManagerList}
        />
      </Box>
    </Box>
  );
}
