import { useCallback, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { useAtom } from "jotai";

import MultiSearchWithTerm from "@sellernote/shared/src/admin-ui/components/MultiSearchWithTerm";
import { GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ_SEARCH_KIND } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import { ADMIN_FORWARDING_MANAGER_OPTION_LIST } from "@sellernote/shared/src/constants/forwarding/adminAuth";
import { SETTLEMENT_PURCHASE_COMPANY_TYPE_OPTION_LIST } from "@sellernote/shared/src/constants/forwarding/adminSettlement";
import useSearchWithDate from "@sellernote/shared/src/hooks/admin/useSearchWithDate";
import useSelectFilter, {
  SelectFilterOptionList,
} from "@sellernote/shared/src/hooks/admin/useSelectFilter";
import { SETTLEMENT_ADMIN_ATOMS } from "@sellernote/shared/src/jotaiStates/settlement";
import { BooleanStringV2 } from "@sellernote/shared/src/types/common/common";

import { useResetAtom } from "jotai/utils";

import {
  BOOLEAN_SELECT_FILTER_OPTION_LIST,
  DATE_SEARCH_TYPE_OPTION_LIST,
  SHIPMENT_TYPE_FILTER_LIST,
  TERM_SEARCH_TYPE_OPTION_LIST,
} from "./constants";

export default function usePurchaseManagementTableFilter() {
  const [finalPrice, setFinalPrice] = useState(0);

  const [purchaseTableSearchWithTermList, setPurchaseTableSearchWithTermList] =
    useAtom(SETTLEMENT_ADMIN_ATOMS.PURCHASE_TABLE_SEARCH_WITH_TERM_LIST);

  const [finalPriceFilterValue, setFinalPriceFilterValue] = useAtom(
    SETTLEMENT_ADMIN_ATOMS.PURCHASE_TABLE_FINAL_PRICE_SEARCH
  );

  const resetPurchaseTableSearchWithTermList = useResetAtom(
    SETTLEMENT_ADMIN_ATOMS.PURCHASE_TABLE_SEARCH_WITH_TERM_LIST
  );

  const { DateSearchPanel, dateSearchType, startDate, endDate } =
    useSearchWithDate({
      dateSearchTypeOptions: DATE_SEARCH_TYPE_OPTION_LIST,
    });

  const {
    SelectFilterPanel: ForwardingManagerSelectFilterPanel,
    selectFilter: forwardingManagerSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: [
      { label: "전체", value: undefined },
      ...ADMIN_FORWARDING_MANAGER_OPTION_LIST,
    ],
    label: "담당자",
  });

  const {
    SelectFilterPanel: CompanyTypeSelectFilterPanel,
    selectFilter: companyTypeSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: [
      { label: "전체", value: undefined },
      ...SETTLEMENT_PURCHASE_COMPANY_TYPE_OPTION_LIST,
    ],
    label: "구분",
  });

  const {
    SelectFilterPanel: IsWithdrawnSelectFilterPanel,
    selectFilter: isWithdrawnSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: BOOLEAN_SELECT_FILTER_OPTION_LIST,
    label: "출금 유무",
  });

  const {
    SelectFilterPanel: ShipmentTypeSelectFilterOptionList,
    selectFilter: shipmentTypeSelectFilter,
  } = useSelectFilter({
    selectFilterOptionList: SHIPMENT_TYPE_FILTER_LIST,
    label: "수출입 구분",
  });

  const changeBooleanStringToBoolean = useCallback(
    (
      booleanString:
        | SelectFilterOptionList<BooleanStringV2 | undefined>
        | undefined
    ) => {
      if (booleanString?.value === "TRUE") {
        return true;
      }

      if (booleanString?.value === "FALSE") {
        return false;
      }

      return undefined;
    },
    []
  );

  const handleFinalPriceSearch = useCallback(() => {
    setFinalPriceFilterValue(finalPrice);
  }, [finalPrice, setFinalPriceFilterValue]);

  const PurchaseSettlementTableFilter = useMemo(() => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 1,
          pt: 1,
          px: 1,
          background: "#fff",
        }}
      >
        {ForwardingManagerSelectFilterPanel}

        {DateSearchPanel}

        {CompanyTypeSelectFilterPanel}

        {IsWithdrawnSelectFilterPanel}

        {ShipmentTypeSelectFilterOptionList}

        <MultiSearchWithTerm
          setMultiSearchWithTermList={setPurchaseTableSearchWithTermList}
          multiSearchWithTermList={purchaseTableSearchWithTermList}
          termSearchTypeOptionList={TERM_SEARCH_TYPE_OPTION_LIST}
          resetMultiSearchWithTermList={resetPurchaseTableSearchWithTermList}
        />

        <TextField
          label="금액 검색"
          size="small"
          className="sads"
          /** 값이 0일 때는 비어있는 값으로 처리 */
          value={finalPrice || null}
          onChange={({ target: { value } }) => setFinalPrice(Number(value))}
          onKeyPress={({ key }) => {
            if (key === "Enter") {
              handleFinalPriceSearch();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleFinalPriceSearch}
                  edge="end"
                  className="sads"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  }, [
    CompanyTypeSelectFilterPanel,
    DateSearchPanel,
    ForwardingManagerSelectFilterPanel,
    IsWithdrawnSelectFilterPanel,
    ShipmentTypeSelectFilterOptionList,
    finalPrice,
    handleFinalPriceSearch,
    purchaseTableSearchWithTermList,
    resetPurchaseTableSearchWithTermList,
    setPurchaseTableSearchWithTermList,
  ]);

  // 배열 타입으로 되어있는 필터 전역 데이터를 객체로 변경
  const purchaseSearchWithTermObject = useMemo(() => {
    return purchaseTableSearchWithTermList.reduce((acc, cur) => {
      acc[cur.searchType] = cur.searchTerm;
      return acc;
    }, {} as Record<GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ_SEARCH_KIND, string>);
  }, [purchaseTableSearchWithTermList]);

  return {
    PurchaseSettlementTableFilter,
    shipmentTypeSelectFilterValue: shipmentTypeSelectFilter?.value,
    companyTypeSelectFilter: companyTypeSelectFilter?.value,
    isWithdrawnSelectFilterValue: changeBooleanStringToBoolean(
      isWithdrawnSelectFilter
    ),
    dateSearchType,
    startDate,
    endDate,
    purchaseSearchWithTermObject,
    forwardingManagerSelectFilterValue: forwardingManagerSelectFilter?.value,
    finalPriceFilterValue,
  };
}
