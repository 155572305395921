import { Dispatch, SetStateAction } from "react";

import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import { POProjectStatus } from "@sellernote/shared/src/types/forwarding/adminOrder";

import useCountingModalBody from "../useCountingModalBody";

export default function CountingModal({
  showsCountingModal,
  setShowsCountingModal,
  POId,
  userCompany,
  userId,
  POProjectStatus,
  hasSubPO,
  teamId,
  isOpenApiAuth,
}: {
  showsCountingModal: boolean;
  setShowsCountingModal: Dispatch<SetStateAction<boolean>>;
  POId: number;
  userCompany: string;
  userId: number;
  POProjectStatus: POProjectStatus;
  hasSubPO: boolean;
  teamId: number;
  isOpenApiAuth: boolean;
}) {
  const { ModalBody } = useCountingModalBody({
    setShowsCountingModal,
    POId,
    name: userCompany,
    userId,
    POProjectStatus,
    hasSubPO,
    teamId,
    isOpenApiAuth,
  });

  return (
    <Modal
      isOpened={showsCountingModal}
      handleClose={() => setShowsCountingModal(false)}
      modalBody={ModalBody}
    />
  );
}
