import { useState } from "react";
import { Box } from "@mui/material";

import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import FileViewer from "@sellernote/shared/src/admin-ui/containers/FileName/FilePreview/FileViewer";
import FileViewerController from "@sellernote/shared/src/admin-ui/containers/FileName/FilePreview/FileViewerController";
import useFileUrl from "@sellernote/shared/src/hooks/admin/useFileUrl";
import { noop } from "@sellernote/shared/src/utils/common/etc";

export default function Preview({
  showsFilePreview,
  setShowsFilePreview,
  s3,
  extension,
  name,
  id,
}: {
  showsFilePreview: boolean;
  setShowsFilePreview: (val: boolean) => void;
  s3: string;
  extension: string;
  name: string;
  id: number;
}) {
  /** 사진 확장자 확대, 축소 기능 */
  const [imageWidth, setImageWidth] = useState(100);
  /** pdf 확장자 확대, 축소 기능 */
  const [pdfScale, setPdfScale] = useState(3.0);

  const { fileUrl, isPdf, isHwp, hwpRenderDivRef } = useFileUrl(s3, extension);

  return (
    <Modal
      isOpened={showsFilePreview}
      handleClose={() => setShowsFilePreview(false)}
      modalBody={
        <Box sx={{ minWidth: 1600 }}>
          <FileViewerController
            fileName={name}
            s3Url={s3}
            fileExtension={extension}
            isPdf={isPdf}
            isHwp={isHwp}
            setPageNumber={noop}
            setImageWidth={setImageWidth}
            imageWidth={imageWidth}
            pdfScale={pdfScale}
            setPdfScale={setPdfScale}
            pageNumber={1}
            numPages={1}
            dataType={"trello"}
            attachmentId={id}
            isNewWindow={false}
            hidesDeleteButton
          />

          <div style={{ marginTop: 10 }} />

          <FileViewer
            isHwp={isHwp}
            isPdf={isPdf}
            fileExtension={extension}
            imageWidth={imageWidth}
            fileUrl={fileUrl}
            hwpRenderDivRef={hwpRenderDivRef}
            handleDocumentLoadSuccess={noop}
            pdfScale={pdfScale}
            pageNumber={1}
          />
        </Box>
      }
    />
  );
}
