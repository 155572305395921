import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";

import PurchaseManagementTable from "./PurchaseManagementTable";

const PurchaseManagement = () => {
  return (
    <Layout title={"매입 관리"}>
      <PurchaseManagementTable />
    </Layout>
  );
};

export default PurchaseManagement;
