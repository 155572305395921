import { useCallback } from "react";
import { Box, Typography } from "@mui/material";

import {
  BusinessManagementProfitSummary,
  BusinessManagementProfitSummaryInfo,
} from "@sellernote/shared/src/types/forwarding/businessManagement";
import { toThousandUnitFormat } from "@sellernote/shared/src/utils/common/number";

const SummaryInfo = ({
  summaryData,
}: {
  summaryData: BusinessManagementProfitSummary | undefined;
}) => {
  const getProfitPercent = useCallback(
    (amountInfo: BusinessManagementProfitSummaryInfo | undefined) => {
      if (!amountInfo) return 0;

      return (amountInfo.totalProfitAmount / amountInfo.totalSalesAmount) * 100;
    },
    []
  );

  return (
    <Box sx={{ display: "flex", p: 1, gap: 2 }}>
      <Box>
        <Typography variant="subtitle2" component="span">
          {new Date().getFullYear().toString().slice(-2)}년도 누적
        </Typography>

        <Box>
          <Typography variant="body2" component="span">
            매출:
          </Typography>

          <Typography variant="body2" component="span">
            {toThousandUnitFormat(summaryData?.yearInfo.totalSalesAmount)}원
          </Typography>
        </Box>

        <Box>
          <Typography variant="body2" component="span">
            매입:
          </Typography>

          <Typography variant="body2" component="span">
            {toThousandUnitFormat(summaryData?.yearInfo.totalPurchaseAmount)}원
          </Typography>
        </Box>

        <Box>
          <Typography variant="body2" component="span">
            손익:
          </Typography>

          <Typography variant="body2" component="span">
            {`${toThousandUnitFormat(
              summaryData?.yearInfo.totalProfitAmount
            )}  (${getProfitPercent(summaryData?.yearInfo).toFixed(2)}%)`}
          </Typography>
        </Box>

        <Box>
          <Typography variant="body2" component="span">
            진행건수:
          </Typography>

          <Typography variant="body2" component="span">
            {toThousandUnitFormat(summaryData?.yearInfo.cnt)}
          </Typography>
        </Box>
      </Box>

      <Box>
        <Typography variant="subtitle2" component="span">
          당월 누적
        </Typography>

        <Box>
          <Typography variant="body2" component="span">
            매출:
          </Typography>

          <Typography variant="body2" component="span">
            {toThousandUnitFormat(summaryData?.monthInfo.totalSalesAmount)}원
          </Typography>
        </Box>

        <Box>
          <Typography variant="body2" component="span">
            매입:
          </Typography>

          <Typography variant="body2" component="span">
            {toThousandUnitFormat(summaryData?.monthInfo.totalPurchaseAmount)}원
          </Typography>
        </Box>

        <Box>
          <Typography variant="body2" component="span">
            손익:
          </Typography>

          <Typography variant="body2" component="span">
            {`${toThousandUnitFormat(
              summaryData?.monthInfo.totalProfitAmount
            )}  (${getProfitPercent(summaryData?.monthInfo).toFixed(2)}%)`}
          </Typography>
        </Box>

        <Box>
          <Typography variant="body2" component="span">
            진행건수:
          </Typography>

          <Typography variant="body2" component="span">
            {toThousandUnitFormat(summaryData?.monthInfo.cnt)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryInfo;
