import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";
import useVesselScheduleTable from "@sellernote/shared/src/hooks/admin/useVesselScheduleTable";

function VesselScheduleAir() {
  const { VesselScheduleTable, ResponseHandlerOfGetVesselScheduleList } =
    useVesselScheduleTable({ freightType: "AIR", includesWarehouse: false });

  return (
    <Layout breadcrumbs={[]} title={"운항 스케줄 AIR"}>
      {VesselScheduleTable}

      {ResponseHandlerOfGetVesselScheduleList}
    </Layout>
  );
}

export default VesselScheduleAir;
