import { ChangeEvent, useMemo } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { Box, Button } from "@mui/material";

import { ApplyBidFormData } from "@sellernote/shared/src/types/forwarding/adminBid";
import { InvoiceData } from "@sellernote/shared/src/types/forwarding/tradingStatement";
import { TrelloBidDetail } from "@sellernote/shared/src/types/forwarding/trello";
import { calculateRTonForTradingStatement } from "@sellernote/shared/src/utils/forwarding/admin/tradingStatement";

import useCompareWithDefaultValue from "../../hooks/useCompareWithDefaultValue";

import DescriptionGridBox from "../components/DescriptionGridBox";
import NoLabelTextField from "../components/NoLabelTextField";

import ContainerList from "./ContainerList";
import ContainersNumberTextField from "./ContainerNumberTextField";
import useCargoInfoLogic from "./useCargoInfoLogic";

function CargoInfo({
  invoiceState,
  shipmentDetailData,
  directAirRton,
  handleDirectAirRtonChange,
  onCommonInputChange,
  control,
  setValue,
  invoiceData,
}: {
  invoiceState: ApplyBidFormData;
  shipmentDetailData: TrelloBidDetail;
  directAirRton: number;
  handleDirectAirRtonChange: (rton: number) => void;
  onCommonInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  control: Control<ApplyBidFormData>;
  setValue: UseFormSetValue<ApplyBidFormData>;
  invoiceData: InvoiceData | undefined;
}) {
  // TODO: 레거시에서 그대로 가져옴 MUI로 변경해서 하위 컴포넌트로 빼야함
  const ContainerNumber = useMemo(() => {
    if (!shipmentDetailData.containersNumberInfo) {
      return "-";
    }
    let dry20 = 0;
    let dry40 = 0;
    let hq40 = 0;

    shipmentDetailData.containersNumberInfo.forEach((v) => {
      if (v.includes("20DRY")) {
        dry20 += 1;
      }
      if (v.includes("40DRY")) {
        dry40 += 1;
      }
      if (v.includes("40HQ")) {
        hq40 += 1;
      }
    });

    return (
      <div>
        {dry20 > 0 && <div>20DRY X {dry20}</div>}
        {dry40 > 0 && <div>40DRY X {dry40}</div>}
        {hq40 > 0 && <div>40HQ X {hq40}</div>}
      </div>
    );
  }, [shipmentDetailData.containersNumberInfo]);

  const {
    handleApplyCargoInfoClick,
    handleResetCargoInfoClick,
    isResetButtonDisabled,
  } = useCargoInfoLogic({
    invoiceState,
    shipmentDetailData,
    directAirRton,
    handleDirectAirRtonChange,
    setValue,
    invoiceData,
  });

  const { isCargoInfoUnchanged } = useCompareWithDefaultValue({
    invoiceState,
    shipmentDetailData,
    directAirRton,
  });

  return (
    <DescriptionGridBox
      title={"화물 정보"}
      descriptionValueList={[
        {
          label: "수량",
          labelGridSize: 3,
          gridSize: 4,
          value: ContainerNumber,
        },
        {
          label: "CNTR",
          labelGridSize: 3,
          gridSize: 4,
          value: !shipmentDetailData.isImport ? (
            <ContainersNumberTextField control={control} />
          ) : shipmentDetailData.containersNumberInfo ? (
            <ContainerList dataList={shipmentDetailData.containersNumberInfo} />
          ) : (
            "-"
          ),
        },

        /** 빈공간을 만들기 위해 빈 값을 추가 */
        {
          gridSize: 4,
          value: "",
        },
        {
          label: "PACKAGE",
          labelGridSize: 3,
          gridSize: 4,
          value: (
            <NoLabelTextField
              name="packageValue"
              value={invoiceState.packageValue}
              onChange={onCommonInputChange}
              size="small"
              fullWidth
            />
          ),
        },
        ...(shipmentDetailData.freightType !== "AIR"
          ? [
              {
                label: "CBM",
                labelGridSize: 3,
                gridSize: 4,
                value: (
                  <NumericFormat
                    thousandSeparator={true}
                    decimalScale={3}
                    customInput={NoLabelTextField}
                    value={invoiceState.cbm || ""}
                    onValueChange={(value) => {
                      setValue("cbm", value?.floatValue || 0);
                    }}
                    fullWidth
                    size="small"
                  />
                ),
              },
            ]
          : []),

        {
          label: "KG(G.W)",
          labelGridSize: 3,
          gridSize: 4,
          value: (
            <NumericFormat
              thousandSeparator={true}
              decimalScale={3}
              customInput={NoLabelTextField}
              value={invoiceState.ton || ""}
              onValueChange={(value) => {
                setValue("ton", value?.floatValue || 0);
              }}
              fullWidth
              size="small"
            />
          ),
        },
        {
          label: shipmentDetailData.freightType === "AIR" ? "C.W" : "R.TON",
          labelGridSize: 3,
          gridSize: 4,
          value:
            shipmentDetailData.freightType === "AIR" ? (
              <NumericFormat
                thousandSeparator={true}
                decimalScale={3}
                size="small"
                fullWidth
                customInput={NoLabelTextField}
                value={directAirRton || ""}
                onValueChange={(value) => {
                  handleDirectAirRtonChange(value?.floatValue || 0);
                }}
              />
            ) : (
              calculateRTonForTradingStatement({
                shipmentDetailData: shipmentDetailData,
                cbm: invoiceState.cbm,
                ton: invoiceState.ton,
                directAirRton,
              })
            ),
        },

        {
          // AIR인 경우 항목이 하나 빠짐(CBM)
          gridSize: shipmentDetailData.freightType === "AIR" ? 10 : 6,
          value: "",
        },

        {
          gridSize: 2,
          value: (
            <Box display="flex" gap={1} justifyContent={"flex-end"}>
              <Button
                variant="outlined"
                disabled={isResetButtonDisabled}
                onClick={handleResetCargoInfoClick}
              >
                초기화
              </Button>

              <Button
                variant="contained"
                disabled={isCargoInfoUnchanged}
                onClick={handleApplyCargoInfoClick}
              >
                적용하기
              </Button>
            </Box>
          ),
        },
      ]}
    />
  );
}

export default CargoInfo;
