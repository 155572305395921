import { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Button, Typography } from "@mui/material";

import Table from "@sellernote/shared/src/admin-ui/components/Table";
import { Port } from "@sellernote/shared/src/types/common/common";
import { AdminOrderDetail } from "@sellernote/shared/src/types/forwarding/adminOrder";
import { formatDate } from "@sellernote/shared/src/utils/common/date";
import { getPortNameForDetailBidList } from "@sellernote/shared/src/utils/forwarding/admin/port";
import {
  changeBidProjectStatusNameToKr,
  changeBidStatusNameToKr,
  getSupplyValueInUnit,
} from "@sellernote/shared/src/utils/forwarding/bid";

import CountingModal from "./CountingModal";
import HEAD_CELL_LIST from "./HEAD_CELL_LIST";
import RemoveMappedShipmentConfirmModal from "./RemoveMappedShipmentConfirmModal";
import ShipmentMappingModal from "./ShipmentMappingModal";
import TeamMemberSelectModal from "./TeamMemberSelectModal";

export default function OrderCreateTable({
  orderDetail,
  portList,
}: {
  orderDetail: AdminOrderDetail;
  portList: Port[] | undefined;
}) {
  const location = useLocation();

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(0);

  const [perPage, setPerPage] = useState(20);

  const [showsCountingModal, setShowsCountingModal] = useState(false);
  const [opensTeamMemberSelectModal, setOpensTeamMemberSelectModal] =
    useState(false);

  const [opensShipmentMappingModal, setOpensShipmentMappingModal] =
    useState(false);

  /** 운송 정보 > 연결 해제 버튼이 클릭된 운송 의뢰 ID */
  const [selectedShipmentIdForRemoval, setSelectedShipmentIdForRemoval] =
    useState<number | undefined>(undefined);

  /** 운송 정보 > 연결 해제 버튼 클릭시 노출되는 삭제 확인 모달 Trigger */
  const [
    opensRemoveMappedShipmentConfirmModal,
    setOpensRemoveMappedShipmentConfirmModal,
  ] = useState(false);

  const handleUploadModalOpen = () => {
    if (orderDetail.teamPrivate) {
      setShowsCountingModal(true);
      return;
    }
    setOpensTeamMemberSelectModal(true);
  };

  const handleBidIdClick = useCallback(
    (id: number) => {
      /** 선적미계획 의뢰 */
      if (id === orderDetail.subPoNumber) {
        history.push(`/order/sub/${orderDetail.id}`);
      } else {
        history.push(`/bid/detail/${id}`);
      }
    },
    [history, orderDetail.id, orderDetail.subPoNumber]
  );

  const rows = useMemo(() => {
    /** 선적미계획에서는 테이블을 보여주지 않는다. */
    if (
      !orderDetail?.bids ||
      (orderDetail.subPoStatus === "notYet" &&
        location.pathname.includes("/sub"))
    )
      return [];

    const orderDetailBidRows = orderDetail?.bids.map((v) => {
      const row = {
        handleRowClick: () => handleBidIdClick(v.id),
        id: v.id,
        userName: v.userName,
        cargoInfo: (
          <Box>
            <Typography variant="body2" component="div">
              {v.name.length > 1
                ? `${v.name[0]} 외 ${v.name.length - 1}`
                : v.name[0]}
            </Typography>

            <Typography variant="body2" component="div">
              {v.freightType}
            </Typography>

            {v.supply && (
              <Typography variant="body2" component="div">
                {getSupplyValueInUnit(v.freightType, v.supply)}
              </Typography>
            )}
          </Box>
        ),
        shippingInfo: (
          <Box>
            <Typography variant="body2" component="div">
              {v.incoterms}
            </Typography>

            <Typography variant="body2" component="div">
              {getPortNameForDetailBidList(v, "startPortId", portList)}
              {"->"}
              {getPortNameForDetailBidList(v, "endPortId", portList)}
            </Typography>
          </Box>
        ),
        checkpoint: (
          <Box>
            <Typography variant="body2" component="div">
              통관의뢰여부: {v.containCustoms ? "예" : "아니오"}
            </Typography>

            <Typography variant="body2" component="div">
              내륙운송 의뢰여부: {v.inlandType ? "예" : "아니오"}
            </Typography>
          </Box>
        ),
        status: changeBidStatusNameToKr(v.status),
        projectStatus: changeBidProjectStatusNameToKr(v.projectStatus),
        createdAt: formatDate({
          date: v.createdAt,
          type: "YY_MM_DD_ddd_HH_mm",
        }),
        remove: (
          <Button
            color="error"
            className="sads"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedShipmentIdForRemoval(v.id);
              setOpensRemoveMappedShipmentConfirmModal(true);
            }}
          >
            <DeleteForeverIcon />
          </Button>
        ),
      };

      return row;
    });

    /** 선적 미계획의뢰가 있는 경우에는 임의 데이터를 추가한 후 다시 날짜 순으로 재 정렬한다. */
    if (orderDetail.subPoStatus === "notYet") {
      const newOrderDetailBidRows = [
        ...orderDetailBidRows,
        {
          handleRowClick: () => handleBidIdClick(orderDetail.subPoNumber),
          id: `NY-${orderDetail.subPoNumber}`,
          userName: "-",
          cargoInfo: "선적미계획 의뢰",
          shippingInfo: "-",
          checkpoint: "-",
          status: "확인 중",
          projectStatus: "운송의뢰 생성 전",
          createdAt: orderDetail.subPoCreatedAt
            ? formatDate({
                date: orderDetail.subPoCreatedAt,
                type: "YY_MM_DD_ddd_HH_mm",
              })
            : formatDate({
                date: new Date(),
                type: "YY_MM_DD_ddd_HH_mm",
              }),
        },
      ];
      return newOrderDetailBidRows.sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
    }

    return orderDetailBidRows;
  }, [
    handleBidIdClick,
    location.pathname,
    orderDetail?.bids,
    orderDetail.subPoCreatedAt,
    orderDetail.subPoNumber,
    orderDetail.subPoStatus,
    portList,
  ]);

  return (
    <>
      <Table
        headCells={HEAD_CELL_LIST}
        rows={rows}
        toolbarItems={{
          left: [
            <Typography key="title" variant="h6" className="sads">
              운송 정보
            </Typography>,
          ],
          right: [
            <>
              <Button
                variant="contained"
                key="shipmentMappingButton"
                className="sads"
                onClick={() => setOpensShipmentMappingModal(true)}
              >
                운송 의뢰 추가 매핑
              </Button>

              <Button
                variant="outlined"
                key="countButton"
                disabled={orderDetail.poStatus === "registered"}
                onClick={() => handleUploadModalOpen()}
              >
                운송 의뢰 생성
              </Button>
            </>,
          ],
        }}
        pagination={{
          rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
          totalCount: orderDetail.bids.length || 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
      />

      {showsCountingModal && (
        <CountingModal
          showsCountingModal={showsCountingModal}
          setShowsCountingModal={setShowsCountingModal}
          POId={orderDetail.id}
          userCompany={orderDetail.userCompany}
          userId={orderDetail.userId}
          POProjectStatus={orderDetail.poProjectStatus}
          hasSubPO={orderDetail.subPoStatus === "notYet"}
          teamId={orderDetail.teamId}
          isOpenApiAuth={orderDetail.isOpenApiAuth}
        />
      )}

      {opensTeamMemberSelectModal && (
        <TeamMemberSelectModal
          opensTeamMemberSelectModal={opensTeamMemberSelectModal}
          setOpensTeamMemberSelectModal={setOpensTeamMemberSelectModal}
          POId={orderDetail.id}
          teamName={orderDetail.teamName}
          POProjectStatus={orderDetail.poProjectStatus}
          hasSubPO={orderDetail.subPoStatus === "notYet"}
          teamId={orderDetail.teamId}
          isOpenApiAuth={orderDetail.isOpenApiAuth}
        />
      )}

      {opensShipmentMappingModal && (
        <ShipmentMappingModal
          teamId={orderDetail.teamId}
          onModalClose={() => setOpensShipmentMappingModal(false)}
        />
      )}

      {selectedShipmentIdForRemoval && (
        <RemoveMappedShipmentConfirmModal
          isOpened={opensRemoveMappedShipmentConfirmModal}
          onModalClose={() => setOpensRemoveMappedShipmentConfirmModal(false)}
          selectedShipmentIdForRemoval={selectedShipmentIdForRemoval}
          setSelectedShipmentIdForRemoval={setSelectedShipmentIdForRemoval}
        />
      )}
    </>
  );
}
