import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";

import CommentList from "@sellernote/shared/src/admin-ui/components/CommentList";
import Tabs from "@sellernote/shared/src/admin-ui/components/Tabs";
import BasicInfo from "@sellernote/shared/src/admin-ui/components/TeamAndUserDetail/BasicInfo";
import BidCreate from "@sellernote/shared/src/admin-ui/components/TeamAndUserDetail/BidCreate";
import EtcInfo from "@sellernote/shared/src/admin-ui/components/TeamAndUserDetail/EtcInfo";
import MemberInfo from "@sellernote/shared/src/admin-ui/components/TeamAndUserDetail/MemberInfo";
import POInfo from "@sellernote/shared/src/admin-ui/components/TeamAndUserDetail/POInfo";
import SalesInfo from "@sellernote/shared/src/admin-ui/components/TeamAndUserDetail/SalesInfo";
import SearchLogTable from "@sellernote/shared/src/admin-ui/components/TeamAndUserDetail/SearchLogTable";
import SettlementInfo from "@sellernote/shared/src/admin-ui/components/TeamAndUserDetail/SettlementInfo";
import ShipmentTable from "@sellernote/shared/src/admin-ui/components/TeamAndUserDetail/ShipmentTable";
import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";
import { UPDATE_TEAM_DETAIL_REQ } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminTeam";
import Loading from "@sellernote/shared/src/componentsToMoveToV1/Loading";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import useUserAndTeamCommentList from "@sellernote/shared/src/hooks/admin/useUserAndTeamCommentList";
import ADMIN_BID_USER_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_USER_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import ADMIN_TEAM_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_TEAM_QUERY";
import ADMIN_USER_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";

function TeamDetail() {
  const { handleSnackbarOpen } = useSnackbar();

  const { id }: { id: string } = useParams();

  const history = useHistory();

  const {
    control,
    watch,
    reset,
    formState: { errors },
    getValues,
  } = useForm<UPDATE_TEAM_DETAIL_REQ>();

  const [isEditMode, setIsEditMode] = useState(false);

  const { data: teamDetail, refetch: refetchTeamDetail } =
    ADMIN_TEAM_QUERY.useGetAdminTeamDetail(Number(id));

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const { data: adminList } = ADMIN_USER_QUERY.useGetAdminUserList();

  const { data: fullfillmentManagerData } =
    ADMIN_BID_USER_QUERY.useGetFullfillmentManagers({});

  const {
    handleCommentUpdate,
    comment,
    handleCommentInputChange,
    ResponseHandler: ResponseHandlerOfCommentUpdate,
  } = useUserAndTeamCommentList({
    userOrTeamId: Number(id),
    pageType: "team",
  });

  const {
    mutate: updateTeamDetail,
    ResponseHandler: ResponseHandlerOfUpdateTeamDetail,
  } = ADMIN_TEAM_QUERY.useUpdateTeamDetail({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        refetchTeamDetail();
        reset();
        setIsEditMode(false);
      },

      customizeMessage: () => ({
        title: "팀 상세를 수정했습니다.",
      }),
    },

    failureModalInfo: {
      customizeMessage: (error) => {
        if (error?.code === 404 && error?.error === "team not found") {
          return {
            title: "삭제된 팀입니다.",
          };
        }

        return {
          title: "팀 상세 수정에서 오류가 발생했습니다.",
        };
      },
    },
  });

  const {
    mutate: deleteTeamDetail,
    ResponseHandler: ResponseHandlerOfDeleteTeam,
  } = ADMIN_TEAM_QUERY.useDeleteTeam({
    teamId: Number(id),
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        history.push("/team/list");
      },

      customizeMessage: () => ({
        title: "팀을 삭제했습니다.",
      }),
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "팀 삭제에서 오류가 발생했습니다.",
      }),
    },
  });

  const handleTeamDetailUpdate = () => {
    if (getValues().BRN?.toString().length !== 10) {
      handleSnackbarOpen("사업자등록번호 입력을 확인해주세요.", "warning");
      return;
    }

    updateTeamDetail({
      ...watch(),
      pathParams: {
        teamId: Number(id),
      },
    });
  };

  const handleTeamDelete = () => {
    deleteTeamDetail({});
  };

  const handleTeamDetailUpdateCancel = () => {
    reset();
    setIsEditMode(false);
  };

  const handleEditModeChange = () => {
    setIsEditMode(true);
  };

  if (!teamDetail || !adminList || !portList || !fullfillmentManagerData) {
    return (
      <Layout>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout title={"팀 상세"}>
      <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
        {!isEditMode && (
          <Button variant="outlined" onClick={handleEditModeChange}>
            수정
          </Button>
        )}

        {isEditMode && (
          <Box>
            <Button variant="contained" onClick={handleTeamDetailUpdate}>
              확인
            </Button>

            <Button
              variant="contained"
              color="error"
              onClick={handleTeamDetailUpdateCancel}
            >
              취소
            </Button>
          </Box>
        )}

        {handleTeamDelete && !isEditMode && (
          <Button variant="contained" color="error" onClick={handleTeamDelete}>
            삭제
          </Button>
        )}

        <BidCreate
          userId={undefined}
          joinedAllTeam={undefined}
          userName={undefined}
          userCompany={teamDetail.company}
          teamId={teamDetail.id}
          corpSizeType={teamDetail.corpSizeType}
          isUserCreate={false}
          teamName={teamDetail.teamName}
          teamMemberList={teamDetail.members}
        />
      </Box>

      <BasicInfo
        detailData={teamDetail}
        adminList={adminList}
        type={"team"}
        fullfillmentManagerData={fullfillmentManagerData}
        refetchDetailData={refetchTeamDetail}
        isEditMode={isEditMode}
        control={control}
      />

      <SettlementInfo
        detailData={teamDetail}
        control={control}
        isEditMode={isEditMode}
        type={"team"}
      />

      <EtcInfo
        type={"team"}
        detailData={teamDetail}
        control={control}
        isEditMode={isEditMode}
      />

      <SalesInfo type={"team"} detailData={teamDetail} />

      <Tabs
        tabsData={[
          {
            tabLabel: "의뢰현황",
            tabPanelItem: (
              <ShipmentTable detailData={teamDetail} portList={portList} />
            ),
          },
          {
            tabLabel: "검색기록",
            tabPanelItem: (
              <SearchLogTable detailData={teamDetail} portList={portList} />
            ),
          },
          {
            tabLabel: "발주현황",
            tabPanelItem: <POInfo detailData={teamDetail} type={"team"} />,
          },
          {
            tabLabel: "메모",
            tabPanelItem: (
              <CommentList
                commentPageType="team"
                comment={comment}
                commentDataList={teamDetail.comments}
                onCommentInputChange={handleCommentInputChange}
                onCommentUpdate={handleCommentUpdate}
              />
            ),
          },
        ]}
      />

      <MemberInfo detailData={teamDetail} />

      {ResponseHandlerOfUpdateTeamDetail}

      {ResponseHandlerOfDeleteTeam}

      {ResponseHandlerOfCommentUpdate}
    </Layout>
  );
}

export default TeamDetail;
