import { Divider, Grid } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

import { PartnerBusinessArea } from "@sellernote/shared/src/types/forwarding/partner";
import {
  TrelloPartnerRequest,
  WithdrawalFormDetail,
} from "@sellernote/shared/src/types/forwarding/trello";

import PriceSummary from "./PriceSummary";
import WithdrawalRequestBody from "./WithdrawalRequestBody";

function WithdrawalRequestTable({
  withdrawalData,
  companyType,
  withdrawalRequestData,
}: {
  withdrawalData: WithdrawalFormDetail[];
  companyType: PartnerBusinessArea;
  withdrawalRequestData: TrelloPartnerRequest;
}) {
  return (
    <Grid container>
      <Grid
        item
        container
        columns={16}
        alignItems="center"
        sx={{ backgroundColor: grey[100], height: "32px" }}
      >
        <Grid item xs={companyType !== "foreign" ? 2 : 2.5}>
          항목
        </Grid>

        <Grid item xs={2}>
          화폐
        </Grid>

        <Grid item xs={companyType !== "foreign" ? 1.5 : 2}>
          단가
        </Grid>

        <Grid item xs={2}>
          단위
        </Grid>

        <Grid item xs={companyType !== "foreign" ? 1 : 2}>
          수량
        </Grid>

        {companyType !== "foreign" && (
          <Grid item xs={2}>
            공급가
          </Grid>
        )}

        {companyType !== "foreign" && (
          <Grid item xs={1.5}>
            부가세
          </Grid>
        )}

        <Grid item xs={2}>
          합계
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ bgcolor: blue[700] }} component={"div"} />
      </Grid>

      {withdrawalData.map((v, index) => {
        return (
          <WithdrawalRequestBody
            key={v.id}
            formListIndex={index}
            formData={v}
            companyType={companyType}
          />
        );
      })}

      <PriceSummary
        companyType={companyType}
        withdrawalRequestData={withdrawalRequestData}
      />
    </Grid>
  );
}

export default WithdrawalRequestTable;
