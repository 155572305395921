import { ChangeEvent } from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { ApplyBidFormData } from "@sellernote/shared/src/types/forwarding/adminBid";

import NoLabelTextField from "../components/NoLabelTextField";

function Comment({
  invoiceState,
  onCommonInputChange,
}: {
  invoiceState: ApplyBidFormData;
  onCommonInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}) {
  return (
    <Box padding={1} border={1} borderColor={grey[400]}>
      <Typography variant="h6" component="div">
        코멘트
      </Typography>

      <NoLabelTextField
        name="comment"
        value={invoiceState.comment}
        onChange={onCommonInputChange}
        size="small"
        fullWidth
        multiline
        rows={4}
      />
    </Box>
  );
}

export default Comment;
