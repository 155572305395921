import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Button, Typography } from "@mui/material";

import { FileTypes } from "@sellernote/shared/src/types/common/upload";
import { AccountDetailFile } from "@sellernote/shared/src/types/forwarding/adminAccount";
import { omitWithEllipsis } from "@sellernote/shared/src/utils/common/string";

export default function FileUploadButton({
  isSelected,
  onFileUploadModalOpen,
  BRNFileList,
  bankBookCopyfileList,
  requiresAccountInfo,
}: {
  isSelected: boolean;
  onFileUploadModalOpen: (domain: AccountDetailFile["domain"]) => void;
  BRNFileList: FileTypes[];
  bankBookCopyfileList: FileTypes[];
  requiresAccountInfo: boolean;
}) {
  return (
    <>
      <Box display={"flex"} alignItems={"center"} ml={-1}>
        {BRNFileList.map((file) => {
          return (
            <Box key={file.id}>
              {omitWithEllipsis({
                src: file.fileInfo.name,
                maxLength: 30,
                ellipsis: "...",
              })}
            </Box>
          );
        })}

        <Button
          variant="outlined"
          size="small"
          startIcon={<CloudUploadIcon />}
          color="info"
          disabled={isSelected || Boolean(BRNFileList.length)}
          sx={{ "&:disabled": { color: "darkgray" } }}
          onClick={() => onFileUploadModalOpen("financialAccountBRN")}
        >
          <>
            {isSelected
              ? "선택한 거래처의 사업자등록증이 자동 업로드됩니다."
              : "사업자등록증"}
            &nbsp;
            {requiresAccountInfo && (
              <Typography component={"span"} color="red">
                *
              </Typography>
            )}
          </>
        </Button>
      </Box>

      <Box display={"flex"} alignItems={"center"} ml={-1}>
        {bankBookCopyfileList.map((file) => {
          return (
            <Box key={file.id}>
              {omitWithEllipsis({
                src: file.fileInfo.name,
                maxLength: 30,
                ellipsis: "...",
              })}
            </Box>
          );
        })}

        <Button
          variant="outlined"
          size="small"
          startIcon={<CloudUploadIcon />}
          color="info"
          disabled={isSelected || Boolean(bankBookCopyfileList.length)}
          sx={{ "&:disabled": { color: "darkgray" } }}
          onClick={() => {
            onFileUploadModalOpen("financialAccountBankBookCopy");
          }}
        >
          <>
            {isSelected
              ? "선택한 거래처의 통장사본이 자동 업로드됩니다."
              : "통장사본"}
            &nbsp;
            {requiresAccountInfo && (
              <Typography component={"span"} color="red">
                *
              </Typography>
            )}
          </>
        </Button>
      </Box>
    </>
  );
}
