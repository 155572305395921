import { useCallback, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useQueryClient } from "react-query";
import { Box, Button, Input } from "@mui/material";
import { useRecoilState } from "recoil";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_ORDER_QUERY, {
  ADMIN_ORDER_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_ORDER_QUERY";
import { FORWARDING_ADMIN_BID_ATOMS } from "@sellernote/shared/src/states/forwarding/adminBid";
import { ExporterInfo } from "@sellernote/shared/src/types/common/common";
import { POExporterInfo } from "@sellernote/shared/src/types/forwarding/adminOrder";
import { validateEmail } from "@sellernote/shared/src/utils/common/validation";

import { styled } from "@mui/material/styles";

export default function useExporterButtons(
  POId: number,
  exporter: ExporterInfo
) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const ExporterButton = styled(Button)`
    border-radius: 2px;
    font-size: 14px;
  `;

  const [editExporterInfo, setEditExporterInfo] = useState<boolean>(false);

  const [exporterInfo, setExporterInfo] = useRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.ADMIN_BID_EXPORTER_INFO
  );

  const handleExporterInfoOpen = useCallback(() => {
    setExporterInfo({
      ...exporter,
    });
    setEditExporterInfo(true);
  }, [exporter, setEditExporterInfo, setExporterInfo]);

  const handleExporterInfoCancel = useCallback(() => {
    setExporterInfo({
      ...exporter,
    });
    setEditExporterInfo(false);
  }, [exporter, setEditExporterInfo, setExporterInfo]);

  const handleExporterInputChange = useCallback(
    (inputValue: string, inputType: POExporterInfo) => {
      setExporterInfo({
        ...exporterInfo,
        [inputType]: inputValue,
      });
    },
    [exporterInfo, setExporterInfo]
  );

  const {
    mutate: updateOrderExporterDetail,
    ResponseHandler: ResponseHandlerOfUpdateOrderExporterDetail,
  } = ADMIN_ORDER_QUERY.useUpdateAdminOrderDetailExporterInfo(POId);

  const handleExporterInputSubmit = useCallback(() => {
    if (
      exporterInfo.companyName.trim() === "" ||
      exporterInfo.personEmail.trim() === "" ||
      exporterInfo.personName.trim() === "" ||
      exporterInfo.personPhone.trim() === ""
    ) {
      handleSnackbarOpen("빈 칸을 채워 주세요.", "warning");
      return;
    }

    const isValidatedEmail = validateEmail({
      val: exporterInfo.personEmail,
      allowEmpty: false,
      Trans,
    }).result;

    if (!isValidatedEmail) {
      handleSnackbarOpen("이메일 형식에 맞게 입력해 주세요.", "warning");
      return;
    }

    updateOrderExporterDetail(
      {
        ...exporterInfo,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderDetail({
              id: POId,
            })
          );
          handleSnackbarOpen("요청에 성공했습니다.");
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );

    setEditExporterInfo(false);
  }, [
    exporterInfo,
    updateOrderExporterDetail,
    handleSnackbarOpen,
    queryClient,
    POId,
  ]);

  const ExporterInput = useCallback(
    (label: string) => {
      return (
        <Input
          sx={{ flex: 1 }}
          className="input"
          value={exporterInfo[label as POExporterInfo] || ""}
          onChange={(e) => {
            handleExporterInputChange(e.target.value, label as POExporterInfo);
          }}
        />
      );
    },
    [exporterInfo, handleExporterInputChange]
  );

  const ExporterButtons = useMemo(() => {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        {editExporterInfo ? (
          <>
            <ExporterButton
              variant="contained"
              onClick={() => handleExporterInputSubmit()}
            >
              확인
            </ExporterButton>

            <ExporterButton
              variant="outlined"
              onClick={() => handleExporterInfoCancel()}
            >
              취소
            </ExporterButton>
          </>
        ) : (
          <>
            {/* TODO: 해당하는 API 제작 중 */}
            <ExporterButton variant="contained">PUSH 메일 발송</ExporterButton>

            <ExporterButton
              variant="contained"
              onClick={() => handleExporterInfoOpen()}
            >
              수출자 정보 입력
            </ExporterButton>
          </>
        )}
      </Box>
    );
  }, [
    ExporterButton,
    handleExporterInfoCancel,
    handleExporterInputSubmit,
    editExporterInfo,
    handleExporterInfoOpen,
  ]);
  return {
    ExporterButtons,
    handleExporterInputChange,
    setEditExporterInfo,
    editExporterInfo,
    ExporterInput,
    ResponseHandler: <>{ResponseHandlerOfUpdateOrderExporterDetail}</>,
  };
}
