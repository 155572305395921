import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";

import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import FileViewer from "@sellernote/shared/src/admin-ui/containers/FileName/FilePreview/FileViewer";
import useFileUrl from "@sellernote/shared/src/hooks/admin/useFileUrl";
import ADMIN_FILE_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import { FileRelatedToFinAccount } from "@sellernote/shared/src/types/forwarding/partnerManagement";
import { noop } from "@sellernote/shared/src/utils/common/etc";

export default function AttachmentPreview({
  previewFile,
  setPreviewFile,
}: {
  previewFile: FileRelatedToFinAccount;
  setPreviewFile: (value: FileRelatedToFinAccount | undefined) => void;
}) {
  const { key, name } = previewFile;

  const [s3, setS3] = useState("");

  const handleFileDownload = () => {
    window.location.href = s3;
  };

  const { mutate: getFileDownloadUrl } =
    ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  /** Mount시 파일 다운로드 URL API 호출 */
  useEffect(() => {
    getFileDownloadUrl(
      { pathParams: { key } },
      { onSuccess: ({ data: { url } }) => setS3(url) }
    );
  }, [getFileDownloadUrl, key]);

  const extension = name.substring(name.lastIndexOf(".") + 1);

  const { fileUrl, isPdf, isHwp, hwpRenderDivRef } = useFileUrl(s3, extension);

  return (
    <Modal
      sx={{ ".MuiBox-root": { padding: 1 }, zIndex: 10002 }}
      isOpened={true}
      handleClose={() => setPreviewFile(undefined)}
      modalBody={
        <Box sx={{ maxHeight: 700, padding: 0 }}>
          <FileViewer
            isHwp={isHwp}
            isPdf={isPdf}
            fileExtension={extension}
            imageWidth={100}
            fileUrl={fileUrl}
            hwpRenderDivRef={hwpRenderDivRef}
            handleDocumentLoadSuccess={noop}
            pdfScale={1}
            pageNumber={1}
          />

          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: isPdf ? 2 : 0 }}
            size="small"
            onClick={handleFileDownload}
          >
            다운로드
          </Button>
        </Box>
      }
    />
  );
}
