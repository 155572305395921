import { useMemo } from "react";

import { PartnerListItem } from "@sellernote/shared/src/types/forwarding/partner";

export interface PartnerCompanyNameListForAutoComplete {
  label: string;
  value: number | null;
}

export default function usePartnerCompanyNameAutoCompleteOptions({
  partnerList,
}: {
  partnerList: PartnerListItem[] | undefined;
}) {
  const partnerCompanyNameListForAutoComplete = useMemo(() => {
    if (!partnerList) return [];

    const partnerCompanyNameList: PartnerCompanyNameListForAutoComplete[] =
      partnerList?.map((userItem) => {
        return {
          label: `${userItem.name}[${userItem.BRNNumber}]`,
          value: userItem.id,
        };
      });

    return partnerCompanyNameList;
  }, [partnerList]);

  return { partnerCompanyNameListForAutoComplete };
}
