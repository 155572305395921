import { Dispatch, SetStateAction } from "react";
import { useQueryClient } from "react-query";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_PARTNER_MANAGEMENT_QUERY, {
  ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_PARTNER_MANAGEMENT_QUERY";
import { MappedPartnerManagerInfo } from "@sellernote/shared/src/types/forwarding/partnerManagement";

import { checkValidEmail } from "../utils";

const useHandleManagerUpdate = ({
  managerList,
  setManagerList,
  id,
}: {
  managerList: MappedPartnerManagerInfo[];
  setManagerList: Dispatch<SetStateAction<MappedPartnerManagerInfo[]>>;
  id: number;
}) => {
  const queryClient = useQueryClient();

  const { handleSnackbarOpen } = useSnackbar();

  const handleSuccess = () => {
    queryClient.invalidateQueries(
      ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN.getPartnerDetail({ id })
    );

    handleSnackbarOpen("담당자 정보가 수정되었습니다.");
  };

  const { mutate: updatePartnerManager } =
    ADMIN_PARTNER_MANAGEMENT_QUERY.useUpdatePartnerManager(id, handleSuccess);

  /** 담당자 수정 API 요청 */
  const handleManagerUpdate = () => {
    const list = managerList.map(({ id, ...rest }) => rest);

    /** 메인 파트너 매니저가 존재하는지 확인 */
    const mainCount = list.filter(({ isMain }) => isMain).length;

    if (mainCount !== 1) {
      handleSnackbarOpen("한 명의 메인 담당자를 지정해주세요.", "warning");
      return;
    }

    /** 담당자 이름, 담당자 이메일이 존재하는지 확인 */
    const invalidManagers = list.some(({ name, email }) => !name || !email);

    if (invalidManagers) {
      handleSnackbarOpen("담당자 이름과 이메일은 필수입니다.", "warning");
      return;
    }

    if (!checkValidEmail(managerList)) {
      handleSnackbarOpen("이메일 형식을 확인해주세요.", "warning");
      return;
    }

    updatePartnerManager({
      managers: list,
    });
  };

  const handleManagerDelete = (id: number) => {
    if (managerList.length === 1) {
      handleSnackbarOpen("최소 한 명의 담당자가 필요합니다.", "warning");
      return;
    }

    setManagerList((prev) => prev.filter((manager) => manager.id !== id));
  };

  /** 담당자 상태 업데이트 */
  const handleManagerStateUpdate = (
    id: number,
    field: string,
    value: string | boolean
  ) => {
    const updatedManagerList = managerList.map((manager) => {
      if (manager.id === id) return { ...manager, [field]: value };

      return manager;
    });

    setManagerList(updatedManagerList);
  };

  return {
    handleManagerUpdate,
    handleManagerDelete,
    handleManagerStateUpdate,
  };
};

export default useHandleManagerUpdate;
