import { useMemo, useState } from "react";

import LegacyTable, {
  LegacyTableBodyRow,
} from "@sellernote/shared/src/admin-ui/components/LegacyTable";
import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";
import ADMIN_BID_USER_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_USER_QUERY";
import { AdminUserGuideList } from "@sellernote/shared/src/types/forwarding/adminBidUser";
import { formatDate } from "@sellernote/shared/src/utils/common/date";
import { changeBooleanValueToKr } from "@sellernote/shared/src/utils/common/etc";
import {
  getUserGuideFieldCategory,
  getUserGuideName,
} from "@sellernote/shared/src/utils/forwarding/admin/adminUser";

import HEAD_CELL_LIST from "./constants";
import UserComment from "./UserComment";

const GuideUser = () => {
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);

  const { data: userGuideList } = ADMIN_BID_USER_QUERY.useGetUserGuideList({
    isGuide: true,
  });

  const rows = useMemo(() => {
    if (!userGuideList?.list) return [];

    return (
      userGuideList?.list
        .map((userGuideItem) => {
          const row: LegacyTableBodyRow<keyof AdminUserGuideList | "domain"> = {
            id: userGuideItem.id,
            company: userGuideItem.company,
            name: userGuideItem.name,
            phone: userGuideItem.phone,
            email: userGuideItem.email,
            createdAt: formatDate({
              date: userGuideItem.createdAt,
              type: "YY_MM_DD_HH_mm",
            }),
            domain: getUserGuideFieldCategory(userGuideItem.careType),
            careType: getUserGuideName(userGuideItem.careType),
            isGuideDownloaded: changeBooleanValueToKr(
              userGuideItem.isGuideDownloaded
            ),
            userComment: userGuideItem.userComment ? (
              <UserComment comment={userGuideItem.userComment} />
            ) : (
              "-"
            ),
          };

          return row;
        })
        .reverse()
        // 현재 가이드 리스트 API에서 페이지 필터가 안되는 상태라서 프론트에서 임시로 추가함
        .filter((_, i) => {
          const startIndex = currentPage * perPage;
          const endIndex = startIndex + perPage;
          return i >= startIndex && i < endIndex;
        })
    );
  }, [currentPage, perPage, userGuideList?.list]);

  return (
    <Layout breadcrumbs={["영업 관리"]} title={"가이드 신청 리스트"}>
      <LegacyTable
        title="가이드 신청 리스트"
        headCells={HEAD_CELL_LIST}
        rows={rows}
        pagination={{
          totalCount: userGuideList?.total || 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
      />
    </Layout>
  );
};

export default GuideUser;
