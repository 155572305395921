import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "@sellernote/shared/src/admin-ui/components/LegacyTable";
import { GET_ADMIN_SETTLEMENT_WITHDRAWAL_PARTNERS_RES } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import useSet from "@sellernote/shared/src/hooks/common/useSet";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_SETTLEMENT_QUERY";
import { FORWARDING_ADMIN_SETTLEMENT_ATOMS } from "@sellernote/shared/src/states/forwarding/adminSettlement";
import { WithdrawalPartnerData } from "@sellernote/shared/src/types/forwarding/adminSettlement";
import { formatDate } from "@sellernote/shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/shared/src/utils/common/number";

import WithdrawalRequestModal from "pages/settlement/purchaseManagement/WithdrawalRequestModal";

type CellId = keyof WithdrawalPartnerData | "checkbox";

function PartnerCompanyTable({
  settlementWithdrawalPartners,
  withdrawalAmount,
  paymentInvoiceId,
  handleModalClose,
  refetchSettlementWithdrawalPartners,
}: {
  settlementWithdrawalPartners:
    | GET_ADMIN_SETTLEMENT_WITHDRAWAL_PARTNERS_RES
    | undefined;
  withdrawalAmount: number;
  paymentInvoiceId: number;
  handleModalClose: () => void;
  refetchSettlementWithdrawalPartners: () => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const setRequestAmount = useSetRecoilState(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.WITHDRAWAL_REQUEST_AMOUNT
  );

  const setInvoiceIds = useSetRecoilState(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.WITHDRAWAL_INVOICE_ID
  );

  const requestAmount = useRecoilValue(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.WITHDRAWAL_REQUEST_AMOUNT
  );

  const invoiceIds = useRecoilValue(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.WITHDRAWAL_INVOICE_ID
  );

  const [showsWithdrawalRequestModal, setShowsWithdrawalRequestModal] =
    useState(false);
  const [bidAccountPayableId, setBidAccountPayableId] = useState(0);

  const {
    mutate: matchBidIdToWithdrawal,
    isLoading: isMatchingOngoing,
    ResponseHandler: ResponseHandlerOfMatchBidIdToWithdrawal,
  } = ADMIN_SETTLEMENT_QUERY.useMatchBidIdToWithdrawal();

  const {
    array: checkBoxArr,
    set: checkBoxSet,
    toggle: toggleCheckBox,
    init: initCheckBoxSet,
  } = useSet<number>();

  useEffect(() => {
    if (settlementWithdrawalPartners) {
      initCheckBoxSet(invoiceIds);
    }
  }, [settlementWithdrawalPartners, initCheckBoxSet, invoiceIds]);

  const handleBidIdMatch = useCallback(() => {
    if (isMatchingOngoing) return;

    matchBidIdToWithdrawal(
      {
        paymentInvoiceId,
        withdrawalRequestIds: checkBoxArr,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("요청에 성공했습니다.");
          refetchSettlementWithdrawalPartners();
        },

        onError: ({ response }) => {
          if (response?.data?.errorCode === "E182") {
            handleSnackbarOpen(
              "이미 출금완료된 출금요청을 매핑할 수 없습니다.",
              "error"
            );
            return;
          }

          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  }, [
    isMatchingOngoing,
    matchBidIdToWithdrawal,
    paymentInvoiceId,
    checkBoxArr,
    handleSnackbarOpen,
    refetchSettlementWithdrawalPartners,
  ]);

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "checkbox",
        disablePadding: false,
        label: "선택",
      },
      {
        id: "bidId",
        disablePadding: false,
        label: "의뢰번호",
      },
      {
        id: "forwardingManager",
        disablePadding: false,
        label: "운영담당자",
      },
      {
        id: "requestDate",
        disablePadding: false,
        label: "출금요청일",
      },
      {
        id: "requestAmount",
        disablePadding: false,
        label: "출금요청금액",
        numeric: true,
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!settlementWithdrawalPartners) return [];

    return settlementWithdrawalPartners.map((v) => {
      const row: LegacyTableBodyRow<CellId> = {
        checkbox: (
          <Checkbox
            checked={checkBoxSet.has(v.withdrawalRequestId)}
            onClick={(e) => {
              e.stopPropagation();

              if (checkBoxSet.has(v.withdrawalRequestId)) {
                setRequestAmount(requestAmount - v.requestAmount);
                setInvoiceIds(
                  invoiceIds.filter((invoiceId) => {
                    return v.withdrawalRequestId !== invoiceId;
                  })
                );
              } else {
                setRequestAmount(requestAmount + v.requestAmount);
                setInvoiceIds([...invoiceIds, v.withdrawalRequestId]);
              }
              toggleCheckBox(v.withdrawalRequestId);
            }}
          />
        ),
        bidId: v.bidId,
        forwardingManager: v.forwardingManager,
        requestDate: formatDate({
          date: v.requestDate,
          type: "YY_MM_DD",
        }),
        requestAmount: (
          <Button
            variant="text"
            onClick={() => {
              setBidAccountPayableId(v.bidAccountPayableId);
              setShowsWithdrawalRequestModal(true);
            }}
          >
            {toThousandUnitFormat(v.requestAmount)}
          </Button>
        ),
      };
      return row;
    });
  }, [
    checkBoxSet,
    invoiceIds,
    requestAmount,
    setInvoiceIds,
    setRequestAmount,
    settlementWithdrawalPartners,
    toggleCheckBox,
  ]);

  const differenceAmount = requestAmount - withdrawalAmount;

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <LegacyTable sx={{ height: 300 }} headCells={headCells} rows={rows} />
      </Grid>

      <Grid container item justifyContent={"flex-end"}>
        <Grid item>
          <TableContainer component={Paper}>
            <MuiTable sx={{ minWidth: 500 }}>
              <TableBody>
                <TableRow>
                  <TableCell>출금요청금액</TableCell>

                  <TableCell align="right">{`${toThousandUnitFormat(
                    requestAmount
                  )}`}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>출금액</TableCell>

                  <TableCell align="right">{`${toThousandUnitFormat(
                    withdrawalAmount
                  )}`}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>차액</TableCell>

                  <TableCell align="right">{`${toThousandUnitFormat(
                    differenceAmount
                  )}`}</TableCell>
                </TableRow>
              </TableBody>
            </MuiTable>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container item justifyContent={"center"}>
        <Button
          variant="contained"
          onClick={handleBidIdMatch}
          disabled={isMatchingOngoing}
        >
          등록
        </Button>
      </Grid>

      {showsWithdrawalRequestModal && (
        <WithdrawalRequestModal
          setShowsWithdrawalRequestModal={setShowsWithdrawalRequestModal}
          showsWithdrawalRequestModal={showsWithdrawalRequestModal}
          bidAccountPayableId={bidAccountPayableId}
        />
      )}

      {ResponseHandlerOfMatchBidIdToWithdrawal}
    </Grid>
  );
}

export default PartnerCompanyTable;
