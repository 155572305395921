import { Divider } from "@mui/material";
import { useRecoilState } from "recoil";
import { SHIPDA_ADMIN_FILTER_ATOMS } from "states/filters";

import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";
import useForwardingManagerAdminUser from "@sellernote/shared/src/hooks/admin/useForwardingManagerAdminUser";
import ADMIN_USER_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";

import Loading from "components/Loading";

import BusinessManagementTable from "./_components/BusinessManagementTable";
import ManagerSummary from "./_components/ManagerSummary";

// TODO: 초기 진입시 담당자필터 기본값을 셋팅하기 위해 forwardingManagerAdminId값의 여부를 로딩으로 사용 개선이 필요
const BusinessOverview = () => {
  const { adminUserList } = ADMIN_USER_QUERY.useGetAdminUserList();

  const [businessManagementFilter, setBusinessManagementFilter] =
    useRecoilState(
      SHIPDA_ADMIN_FILTER_ATOMS.ADMIN_BUSINESS_MANAGEMENT_FILTER_LIST
    );

  const handleForwardingManagerFilterHistoryChange = (value: number) => {
    setBusinessManagementFilter({
      ...businessManagementFilter,
      adminId: value,
    });
  };

  const { forwardingManagerAdminId, forwardingManagerAdminList } =
    useForwardingManagerAdminUser({
      adminUserList,
    });

  if (!forwardingManagerAdminId) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout title="영업 현황">
      <ManagerSummary
        // 초기에는 전역값 businessManagementFilter 셋팅이 없기 때문에 forwardingManagerAdminId를 사용
        forwardingManagerAdminId={
          businessManagementFilter?.adminId ?? forwardingManagerAdminId
        }
        businessManagementFilter={businessManagementFilter}
        onForwardingManagerFilterHistoryChange={
          handleForwardingManagerFilterHistoryChange
        }
        forwardingManagerAdminList={forwardingManagerAdminList}
      />

      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

      <BusinessManagementTable
        // 초기에는 전역값 businessManagementFilter 셋팅이 없기 때문에 forwardingManagerAdminId를 사용
        forwardingManagerAdminId={
          businessManagementFilter?.adminId ?? forwardingManagerAdminId
        }
      />
    </Layout>
  );
};

export default BusinessOverview;
