import { ChangeEvent } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { CREATE_PARTNER_REQ } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/partnerManagement";

import CountryInCharge from "pages/partner-management/components/CountryInCharge";
import PartnerType from "pages/partner-management/components/PartnerType";
import TransportMode from "pages/partner-management/components/TransportMode";
import NO_LABEL from "../styles";

export default function PartnerInfo({
  form,
  onInfoUpdate,
}: {
  form: CREATE_PARTNER_REQ;
  onInfoUpdate: <T>(name: string, value: T | T[]) => void;
}) {
  const handleShipmentTypeUpdate =
    (type: string) =>
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
      const shipmentTypesSet = new Set(form.shipmentTypes);
      checked ? shipmentTypesSet.add(type) : shipmentTypesSet.delete(type);

      onInfoUpdate("shipmentTypes", Array.from(shipmentTypesSet));
    };

  return (
    <Box display={"flex"} flexDirection={"column"} width={250}>
      <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
        기본 정보
      </Typography>

      <Box ml={-1}>
        <InputLabel>
          회사명{" "}
          <Typography component={"span"} color="red">
            *
          </Typography>
        </InputLabel>

        <TextField
          required
          sx={NO_LABEL}
          size="small"
          fullWidth
          value={form.name}
          onChange={(e) => onInfoUpdate("name", e.target.value)}
        />
      </Box>

      <Box ml={-1}>
        <InputLabel>
          파트너 유형{" "}
          <Typography component={"span"} color="red">
            *
          </Typography>
        </InputLabel>

        <PartnerType
          handleTypeUpdate={(type: string) => {
            onInfoUpdate("businessArea", type);
          }}
        />
      </Box>

      <Box ml={-1}>
        <InputLabel>
          언어{" "}
          <Typography component={"span"} color="red">
            *
          </Typography>
        </InputLabel>

        <Select
          value={form.language}
          onChange={(e) => {
            onInfoUpdate("language", e.target.value);
          }}
          sx={{ ...NO_LABEL, width: "100%" }}
          size="small"
        >
          <MenuItem value={"kr"}>한국어</MenuItem>
          <MenuItem value={"en"}>영어</MenuItem>
        </Select>
      </Box>

      <Box ml={-1}>
        <InputLabel>운송 유형</InputLabel>

        <TransportMode
          setTransportModeList={(list) => {
            const modeList = list.map(({ value }) => value);
            onInfoUpdate("transportModes", modeList);
          }}
          hidesLabel
        />
      </Box>

      <Box ml={-1}>
        <InputLabel>담당 국가</InputLabel>

        <CountryInCharge
          countryList={[]}
          setCountryList={(list) => {
            const countryList = list.map((country) => country.value);
            onInfoUpdate("countries", countryList);
          }}
          hidesLabel
        />
      </Box>

      <FormGroup row sx={{ color: "#00000099" }}>
        <FormControlLabel
          value="importation"
          control={
            <Checkbox
              checked={form.shipmentTypes.includes("importation")}
              size="small"
              onChange={handleShipmentTypeUpdate("importation")}
            />
          }
          label="수입"
          labelPlacement="end"
        />

        <FormControlLabel
          value="exportation"
          control={
            <Checkbox
              checked={form.shipmentTypes.includes("exportation")}
              size="small"
              onChange={handleShipmentTypeUpdate("exportation")}
            />
          }
          label="수출"
          labelPlacement="end"
        />
      </FormGroup>

      <Box ml={-1} mb={-1}>
        <FormControlLabel
          sx={{ m: 0, color: "#00000099" }}
          control={
            <Checkbox
              size="small"
              checked={form.isFinancial}
              onChange={(e) => {
                onInfoUpdate("isFinancial", e.target.checked);
              }}
            />
          }
          label="금전거래 여부"
          labelPlacement="start"
        />
      </Box>

      <Box ml={-1} mb={-3}>
        <FormControlLabel
          sx={{ m: 0, color: "#00000099" }}
          control={
            <Checkbox
              size="small"
              onChange={(e) => {
                onInfoUpdate("invoiceIssueFlag", e.target.checked);
              }}
              checked={form.invoiceIssueFlag}
            />
          }
          label="계산서 발행"
          labelPlacement="start"
        />
      </Box>
    </Box>
  );
}
