import { useMemo } from "react";
import { Grid } from "@mui/material";

import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import TRELLO_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { PartnerBusinessArea } from "@sellernote/shared/src/types/forwarding/partner";

import WithdrawalRequestTable from "./WithdrawalRequestTable";

function WithdrawalRequestModal({
  showsWithdrawalRequestModal,
  setShowsWithdrawalRequestModal,
  bidAccountPayableId,
}: {
  showsWithdrawalRequestModal: boolean;
  setShowsWithdrawalRequestModal: (value: boolean) => void;
  bidAccountPayableId: number;
}) {
  const { data } = TRELLO_BID_QUERY.useGetWithdrawalBasicList({
    params: { bidAccountPayableId },
  });

  const withdrawalRequestData = useMemo(() => {
    if (!data || (data && !data.withdrawalRequest)) return;

    return data.withdrawalRequest;
  }, [data]);

  const basicWithdrawalRequestList = useMemo(() => {
    if (!data) {
      return;
    }
    return data.detailList.map((detailItem) => {
      return {
        id: detailItem.id,
        amount: detailItem.amount,
        currency: detailItem.currency,
        finalPrice: detailItem.finalPrice,
        isVAT: detailItem.isVAT,
        itemUnitMeasurement: detailItem.itemUnitMeasurement,
        name: detailItem.name,
        note: detailItem.note,
        totalPrice: detailItem.totalPrice,
        vatPrice: detailItem.vatPrice,
        unitPrice: detailItem.unitPrice,
      };
    });
  }, [data]);

  const getWithdrawalRequestModalTitle = (
    text: PartnerBusinessArea | undefined
  ) => {
    switch (text) {
      case "parcel":
        return "화물택배사 지불금액";
      case "foreign":
        return "해외파트너 지불금액";
      case "domestic":
        return "국내파트너 지불금액";
      case "customs":
        return "관세사 지불금액";
      case "customsDuty":
        return "세관 지불금액";
      case "inland":
        return "내륙운송사 지불금액";
      case "wareHouse":
        return "창고 지불금액";
      case "shipping":
        return "선사 지불금액";
      case "etc":
        return "기타1 지불금액";
      case "etc2":
        return "기타2 지불금액";
      default:
        return "-";
    }
  };

  return (
    <Modal
      isOpened={showsWithdrawalRequestModal}
      handleClose={() => setShowsWithdrawalRequestModal(false)}
      modalBody={
        <Grid container spacing={4} direction="column" sx={{ width: 1000 }}>
          <Grid item alignItems={"flex-end"}>
            {getWithdrawalRequestModalTitle(withdrawalRequestData?.companyType)}
          </Grid>

          <Grid container item justifyContent={"flex-end"}>
            <Grid item>
              {withdrawalRequestData?.currency}:{" "}
              {withdrawalRequestData?.exchangeRate}
            </Grid>
          </Grid>

          <Grid item>
            {basicWithdrawalRequestList && withdrawalRequestData && (
              <WithdrawalRequestTable
                withdrawalData={basicWithdrawalRequestList}
                companyType={withdrawalRequestData.companyType}
                withdrawalRequestData={withdrawalRequestData}
              />
            )}
          </Grid>
        </Grid>
      }
    />
  );
}

export default WithdrawalRequestModal;
