import { Grid, Typography } from "@mui/material";

import { PartnerBusinessArea } from "@sellernote/shared/src/types/forwarding/partner";
import { TrelloPartnerRequest } from "@sellernote/shared/src/types/forwarding/trello";
import { toThousandUnitFormat } from "@sellernote/shared/src/utils/common/number";

function PriceSummary({
  companyType,
  withdrawalRequestData,
}: {
  companyType: PartnerBusinessArea;
  withdrawalRequestData: TrelloPartnerRequest;
}) {
  return (
    <Grid item container>
      {companyType === "foreign" && (
        <Grid
          item
          container
          columns={16}
          alignItems="center"
          sx={{ height: "32px" }}
        >
          <Grid item xs={companyType === "foreign" ? 2.5 : 8.5}>
            <Typography variant="body1" component="div">
              합계:
            </Typography>
          </Grid>

          {companyType === "foreign" && (
            <Grid item xs={8}>
              <Typography variant="body1" component="div">
                {withdrawalRequestData.currency}
              </Typography>
            </Grid>
          )}

          {companyType !== "foreign" && (
            <Grid item xs={2}>
              <Typography
                variant="body1"
                component="div"
                sx={{ textAlign: "right" }}
              >
                {toThousandUnitFormat(withdrawalRequestData.totalPrice)}
              </Typography>
            </Grid>
          )}

          {companyType !== "foreign" && (
            <Grid item xs={1.5}>
              <Typography
                variant="body1"
                component="div"
                sx={{ textAlign: "right" }}
              >
                {toThousandUnitFormat(withdrawalRequestData.vatPrice)}
              </Typography>
            </Grid>
          )}

          <Grid item xs={2}>
            <Typography
              variant="body1"
              component="div"
              sx={{ textAlign: "right" }}
            >
              {toThousandUnitFormat(withdrawalRequestData.finalPrice)}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid
        item
        container
        columns={16}
        alignItems="center"
        sx={{ height: "32px" }}
      >
        <Grid item xs={companyType === "foreign" ? 2.5 : 8.5}>
          <Typography variant="body1" component="div">
            합계:
          </Typography>
        </Grid>

        {companyType === "foreign" && (
          <Grid item xs={8}>
            <Typography variant="body1" component="div">
              KRW
            </Typography>
          </Grid>
        )}

        {companyType !== "foreign" && (
          <Grid item xs={2}>
            <Typography
              variant="body1"
              component="div"
              sx={{ textAlign: "right" }}
            >
              {toThousandUnitFormat(withdrawalRequestData.totalPrice)}
            </Typography>
          </Grid>
        )}

        {companyType !== "foreign" && (
          <Grid item xs={1.5}>
            <Typography
              variant="body1"
              component="div"
              sx={{ textAlign: "right" }}
            >
              {toThousandUnitFormat(withdrawalRequestData.vatPrice)}
            </Typography>
          </Grid>
        )}

        <Grid item xs={2}>
          <Typography
            variant="body1"
            component="div"
            sx={{ textAlign: "right" }}
          >
            {toThousandUnitFormat(withdrawalRequestData.finalPrice)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default PriceSummary;
