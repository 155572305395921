import { useState } from "react";
import { useQueryClient } from "react-query";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_ORDER_QUERY, {
  ADMIN_ORDER_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_ORDER_QUERY";

import Form from "./Form";

export default function EditPONumber({
  poId,
  poNumber,
}: {
  poId: number;
  poNumber: string;
}) {
  const [showsEditModal, setShowsEditModal] = useState(false);

  const queryClient = useQueryClient();

  const { handleSnackbarOpen } = useSnackbar();

  const { mutate: updatePONumber, isLoading } =
    ADMIN_ORDER_QUERY.useUpdatePONumber({
      poId,
      onSuccess: () => {
        handleSnackbarOpen("수정되었습니다.", "success");
        queryClient.invalidateQueries(
          ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderDetail({ id: poId })
        );
        setShowsEditModal(false);
      },
      onError(err) {
        if (err?.code === 409) {
          handleSnackbarOpen("이미 존재하는 PO번호입니다.", "error");
        } else {
          handleSnackbarOpen(
            `수정하지 못했습니다. ${err?.code} ${err?.error} `,
            "error"
          );
        }
      },
    });

  const handleEditClick = () => {
    setShowsEditModal(true);
  };

  return (
    <>
      <ModeEditIcon
        color="primary"
        cursor="pointer"
        onClick={handleEditClick}
      />

      {showsEditModal && (
        <Modal
          isOpened
          handleClose={() => setShowsEditModal(false)}
          modalBody={
            <Form
              poNumber={poNumber}
              updatePONumber={updatePONumber}
              isLoading={isLoading}
            />
          }
        />
      )}
    </>
  );
}
