import { useAtomValue } from "jotai";

import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";
import ShipdaGeneralAndConsolidationTrello from "@sellernote/shared/src/admin-ui/pageContainers/trello/shipda/GeneralAndConsolidation";
import { FORWARDING_ADMIN_TRELLO_JOTAI_SELECTORS } from "@sellernote/shared/src/jotaiStates/trello";
import ADMIN_USER_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";
import TRELLO_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

function GeneralTrelloList() {
  const fetchTrelloListParams = useAtomValue(
    FORWARDING_ADMIN_TRELLO_JOTAI_SELECTORS.FETCH_TRELLO_LIST_PARAMS
  );

  const { region, corpSizeType, blKind, ...trelloParams } =
    fetchTrelloListParams;

  const {
    data: trelloBidList,
    isLoading: isLoadingForGetTrelloBidList,
    refetch: refetchForGetTrelloBidList,
  } = TRELLO_BID_QUERY.useGetTrelloBidList({
    ...trelloParams,
    /** fetchTrelloListParams.region 값이 all 일때는, 국가 파라미터 전달을 하지 않아 KR, SG의뢰를 모두 불러옴 */
    ...(region === "all" ? [] : { region }),
    ...(corpSizeType === "all" ? [] : { corpSizeType }),
    ...(blKind === "all" ? [] : { blKind }),
    status: "inProgress",
    perPage: 300,
    serviceType: undefined,
  });

  const {
    data: trelloBidFinishedList,
    isLoading: isLoadingForGetTrelloBidFinishedList,
    refetch: refetchForGetTrelloBidFinishedList,
  } = TRELLO_BID_QUERY.useGetTrelloBidFinishedList({
    ...trelloParams,
    /** fetchTrelloListParams.region 값이 all 일때는, 국가 파라미터 전달을 하지 않아 KR, SG의뢰를 모두 불러옴 */
    ...(region === "all" ? [] : { region }),
    ...(corpSizeType === "all" ? [] : { corpSizeType }),
    ...(blKind === "all" ? [] : { blKind }),
    status: "finished",
    projectStatus: "finished",
    perPage: 200,
    serviceType: undefined,
  });

  const {
    adminUserList,
    isLoading: isLoadingForGetAdminUserList,
    refetch: refetchForGetAdminUserList,
  } = ADMIN_USER_QUERY.useGetAdminUserList();

  const refetchData = () => {
    refetchForGetTrelloBidList();
    refetchForGetTrelloBidFinishedList();
    refetchForGetAdminUserList();
  };

  return (
    <Layout>
      <ShipdaGeneralAndConsolidationTrello
        trelloListData={trelloBidList?.concat(trelloBidFinishedList ?? [])}
        adminData={adminUserList}
        isLoading={
          isLoadingForGetTrelloBidList ||
          isLoadingForGetTrelloBidFinishedList ||
          isLoadingForGetAdminUserList
        }
        refetchData={refetchData}
      />
    </Layout>
  );
}

export default GeneralTrelloList;
