import { MappedPartnerManagerInfo } from "@sellernote/shared/src/types/forwarding/partnerManagement";
import { getEmailValidation } from "@sellernote/shared/src/utils/common/validation";

function checkValidEmail(list: MappedPartnerManagerInfo[]) {
  return list.every(({ email }) => getEmailValidation(email).result);
}

function getCellList(isCustomPartner: boolean) {
  return [
    {
      id: "isMain",
      disablePadding: false,
      label: "메인",
      width: 60,
    },
    {
      id: "name",
      disablePadding: false,
      label: "담당자",
      width: 140,
    },
    {
      id: "position",
      disablePadding: false,
      label: "직함",
      width: 140,
    },
    {
      id: "phone",
      disablePadding: false,
      label: "전화번호",
      width: 170,
    },
    {
      id: "email",
      disablePadding: false,
      label: "메일주소",
      width: 240,
    },

    ...(isCustomPartner
      ? [
          {
            id: "isCustomsAdmin",
            disablePadding: false,
            label: "관세사 어드민",
            width: 100,
          },
        ]
      : []),

    {
      id: "delete",
      disablePadding: false,
      label: "삭제",
      width: 80,
    },
  ];
}

export { checkValidEmail, getCellList };
