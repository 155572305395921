import { GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ_SEARCH_KIND } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import { GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ_DATE_KIND } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import { SearchWithDateTypeOption } from "@sellernote/shared/src/hooks/admin/useSearchWithDate";
import { TermSearchType } from "@sellernote/shared/src/hooks/admin/useSearchWithTerm";
import { SelectFilterOptionList } from "@sellernote/shared/src/hooks/admin/useSelectFilter";
import {
  BooleanStringV2,
  ShipmentType,
} from "@sellernote/shared/src/types/common/common";

const DATE_SEARCH_TYPE_OPTION_LIST: SearchWithDateTypeOption<GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ_DATE_KIND>[] =
  [
    {
      label: "출금 요청일",
      value: "requestDate",
    },
    {
      label: "INV. Date",
      value: "withdrawalInvoiceDate",
    },
    {
      label: "견적 생성일",
      value: "createdDate",
    },
    {
      label: "출금일",
      value: "withdrawalDate",
    },
  ];

const TERM_SEARCH_TYPE_OPTION_LIST: TermSearchType<GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ_SEARCH_KIND>[] =
  [
    {
      label: "회사명",
      value: "company",
    },
    {
      label: "INV. ID",
      value: "withdrawalInvoiceId",
    },
    {
      label: "의뢰번호",
      value: "bidId",
    },
    {
      label: "사업자등록번호",
      value: "BRN",
    },
    {
      label: "BL",
      value: "BL",
    },
  ];

const BOOLEAN_SELECT_FILTER_OPTION_LIST: SelectFilterOptionList<
  undefined | BooleanStringV2
>[] = [
  {
    label: "전체",
    value: undefined,
  },
  {
    label: "O",
    value: "TRUE",
  },
  {
    label: "X",
    value: "FALSE",
  },
];

const SHIPMENT_TYPE_FILTER_LIST: SelectFilterOptionList<
  undefined | ShipmentType
>[] = [
  {
    label: "전체",
    value: undefined,
  },
  {
    label: "수입",
    value: "importation",
  },
  {
    label: "수출",
    value: "exportation",
  },
];

export {
  DATE_SEARCH_TYPE_OPTION_LIST,
  TERM_SEARCH_TYPE_OPTION_LIST,
  BOOLEAN_SELECT_FILTER_OPTION_LIST,
  SHIPMENT_TYPE_FILTER_LIST,
};
