import LegacyTable from "@sellernote/shared/src/admin-ui/components/LegacyTable";
import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";
import { useAdminFareTable } from "@sellernote/shared/src/hooks/admin/useAdminFareTable";
import ADMIN_COMMON_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";

const FareManagementLclList = () => {
  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const { headCells, Rows, pagination } = useAdminFareTable({
    portList,
    freightType: "LCL",
  });

  return (
    <Layout breadcrumbs={["운임 관리"]} title="LCL운임 관리">
      {headCells && Rows && (
        <LegacyTable
          title="LCL 운임관리 리스트"
          headCells={headCells}
          rows={Rows}
          pagination={pagination}
        />
      )}
    </Layout>
  );
};

export default FareManagementLclList;
