import { useEffect, useRef, useState } from "react";
import { Box, styled } from "@mui/material";

import InfoFilledIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/InfoFilledIcon";
import Tooltip from "@sellernote/shared/src/sds-v2/components/Tooltip";

const MEMO_TWO_LINE_HEIGHT = 70;

const MemoBoxContainer = styled(Box)<{ isOverflow: boolean }>(
  ({ isOverflow }) => ({
    ...(isOverflow && {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
    }),
  })
);

const MemoTooltipBox = styled(Box)<{ isOverflow: boolean }>(
  ({ isOverflow }) => ({
    display: "none",
    ...(isOverflow && {
      display: "block",
      float: "right",
      shapeOutside: "border-box",
      marginTop: "42px",
    }),
  })
);

export default function OrderMemo({ memo }: { memo: string | null }) {
  const memoRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  /**
   * 메모가 3줄 이상인 경우, 말줄임표와 함께 툴팁을 표시
   * 메모가 3줄 이상임을 판단하는 값은 div의 height을 측정한 값으로, 60px(3줄)과 80px(4줄) 사이로 정의
   *
   * Mount 했을 때 말줄임과 툴팁 노출 여부를 판단하기 위해 Effect를 사용
   */
  useEffect(() => {
    if (memoRef.current) {
      setIsOverflow(memoRef.current.scrollHeight > MEMO_TWO_LINE_HEIGHT);
    }
  }, [memo]);

  if (!memo) return <>-</>;

  return (
    <MemoBoxContainer ref={memoRef} isOverflow={isOverflow}>
      <MemoTooltipBox isOverflow={isOverflow}>
        <Tooltip desc={<Box width={240}>{memo}</Box>} position="topCenter">
          <InfoFilledIcon width={16} height={16} />
        </Tooltip>
      </MemoTooltipBox>

      {memo || "-"}
    </MemoBoxContainer>
  );
}
