import { useMemo, useState } from "react";
import { Typography } from "@mui/material";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "@sellernote/shared/src/admin-ui/components/LegacyTable";
import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";
import { GET_ADMIN_INLAND_FARES_REQ_SEARCH_KIND } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminInalndFares";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/shared/src/hooks/admin/useSearchWithTerm";
import ADMIN_INLAND_FARES_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_INLAND_FARES_QUERY";
import { InlandLclFares } from "@sellernote/shared/src/types/forwarding/adminInlandFares";
import { toThousandUnitFormat } from "@sellernote/shared/src/utils/common/number";

type CellId = keyof InlandLclFares | "startPort" | "endAddress";

const termSearchTypeOptions: TermSearchType<GET_ADMIN_INLAND_FARES_REQ_SEARCH_KIND>[] =
  [
    {
      label: "주소",
      value: "address",
    },
    {
      label: "zoneId",
      value: "zoneId",
    },
  ];

const InlandFareLclList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(25);

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  //TODO: 이관 브랜치에서 따로 작업한 전용 hooks가 있음 나중에 병합 시 수정
  const termSearchTypeKeyObject = useMemo(() => {
    if (termSearchType?.value && debouncedSearchTerm) {
      const searchObject: { [key: string]: unknown } = {};
      searchObject[termSearchType?.value] = debouncedSearchTerm;
      return searchObject;
    }
    return undefined;
  }, [debouncedSearchTerm, termSearchType?.value]);

  const { data: inlandFareList } =
    ADMIN_INLAND_FARES_QUERY.useGetAdminInlandLclFares({
      page: 0,
      perPage: 25,
      ...termSearchTypeKeyObject,
    });

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "id",
        disablePadding: false,
        label: "번호",
        width: 100,
      },
      {
        id: "startPort",
        label: "출발지",
        disablePadding: false,
        width: 100,
      },
      {
        id: "endAddress",
        disablePadding: false,
        label: "도착지",
        width: 200,
      },
      {
        label: "합차(~1CBM,~250KG)",
        id: "consol1",
        disablePadding: false,
        width: 150,
      },
      {
        label: "합차(~2CBM,~500KG)",
        id: "consol2",
        disablePadding: false,
        width: 150,
      },
      {
        label: "합차(~3CBM,~1,000KG)",
        id: "consol3",
        disablePadding: false,
        width: 160,
      },
      {
        label: "다마스",
        id: "damas",
        disablePadding: false,
        width: 100,
      },
      {
        label: "라보",
        id: "labo",
        disablePadding: false,
        width: 100,
      },
      {
        label: "1톤차(M:1.1t)",
        id: "section1",
        disablePadding: false,
        width: 100,
      },
      {
        label: "1.4톤차(M1.54t)",
        id: "section2",
        disablePadding: false,
        width: 100,
      },
      {
        label: "2.5톤차(M2.75t)",
        id: "section3",
        disablePadding: false,
        width: 100,
      },
      {
        label: "3.5톤차(M3.85t)",
        id: "section4",
        disablePadding: false,
        width: 100,
      },
      {
        label: "5톤차(M5.5t)",
        id: "section5",
        disablePadding: false,
        width: 100,
      },
      {
        label: "5톤축(M5.5t)",
        id: "section6",
        disablePadding: false,
        width: 100,
      },
      {
        label: "11t톤차(M12.1t)",
        id: "section7",
        disablePadding: false,
        width: 100,
      },
      {
        label: "18톤차",
        id: "section8",
        disablePadding: false,
        width: 100,
      },
      {
        label: "20톤차",
        id: "section9",
        disablePadding: false,
        width: 100,
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!inlandFareList?.list) return [];

    return inlandFareList?.list.map((inlandFareListItem) => {
      const row: LegacyTableBodyRow<CellId> = {
        id: inlandFareListItem.id,
        startPort: inlandFareListItem.port.name,
        endAddress: inlandFareListItem.zone.name,
        consol1: toThousandUnitFormat(inlandFareListItem.consol1),
        consol2: toThousandUnitFormat(inlandFareListItem.consol2),
        consol3: toThousandUnitFormat(inlandFareListItem.consol3),
        damas: toThousandUnitFormat(inlandFareListItem.damas),
        labo: toThousandUnitFormat(inlandFareListItem.labo),
        section1: toThousandUnitFormat(inlandFareListItem.section1),
        section2: toThousandUnitFormat(inlandFareListItem.section2),
        section3: toThousandUnitFormat(inlandFareListItem.section3),
        section4: toThousandUnitFormat(inlandFareListItem.section4),
        section5: toThousandUnitFormat(inlandFareListItem.section5),
        section6: toThousandUnitFormat(inlandFareListItem.section6),
        section7: toThousandUnitFormat(inlandFareListItem.section7),
        section8: toThousandUnitFormat(inlandFareListItem.section8),
        section9: toThousandUnitFormat(inlandFareListItem.section9),
      };
      return row;
    });
  }, [inlandFareList?.list]);

  return (
    <Layout breadcrumbs={["내륙요금관리"]}>
      <LegacyTable
        toolbarItems={{
          left: [TermSearchPanel],
          right: [
            <Typography
              key="text"
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              t = ton, M = Max, 최대
            </Typography>,
          ],
        }}
        title="LCL 내륙요금 리스트"
        headCells={headCells}
        rows={rows}
        pagination={{
          totalCount: inlandFareList?.total || 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
      />
    </Layout>
  );
};

export default InlandFareLclList;
