import { useState } from "react";
import { Card } from "@mui/material";

import Table from "@sellernote/shared/src/admin-ui/components/Table";
import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";
import ADMIN_COMMON_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";

const COLUMNS = [
  {
    id: "id",
    disablePadding: false,
    label: "ID",
    width: 50,
  },
  {
    id: "code",
    disablePadding: false,
    label: "Code",
    width: 100,
  },
  {
    id: "name",
    disablePadding: false,
    label: "이름",
    width: 150,
  },
  {
    id: "nameC",
    disablePadding: false,
    label: "이름2",
    width: 150,
  },
  {
    id: "nameEN",
    disablePadding: false,
    label: "영문이름",
    width: 200,
  },
  {
    id: "countryName",
    disablePadding: false,
    label: "국가명",
    width: 100,
  },
  {
    id: "type",
    disablePadding: false,
    label: "타입",
    width: 100,
  },
];

const PortList = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const rowList = (() => {
    if (!portList) return [];

    return portList.map((port) => ({
      id: port.id,
      code: port.code,
      name: port.name,
      nameC: port.nameC,
      nameEN: port.nameEN,
      countryName: port.countryName,
      type: port.type === "sea" ? "해상" : "항공",
    }));
  })();

  const filteredRowList = rowList.filter((_, i) => {
    const startIndex = currentPage * 25;
    const endIndex = startIndex + 25;
    return i >= startIndex && i < endIndex;
  });

  return (
    <Layout breadcrumbs={["항구관리"]} title="항구관리">
      <Card>
        <Table
          headCells={COLUMNS}
          rows={filteredRowList}
          pagination={{
            rowsPerPageOptions: [],
            currentPage,
            setCurrentPage,
            perPage: 25,
            setPerPage: () => {},
            totalCount: rowList.length ?? 0,
          }}
        />
      </Card>
    </Layout>
  );
};

export default PortList;
