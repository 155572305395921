import { useMemo, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import axios from "axios";

import FileUpload from "@sellernote/shared/src/admin-ui/components/FileUpload";
import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import { GET_PRESIGNED_URL_RES_DATA } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminFile";
import { GET_ADMIN_SETTLEMENT_PURCHASE_LIST_RES } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_FILE_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_SETTLEMENT_QUERY";
import { FileTypes } from "@sellernote/shared/src/types/common/upload";
import { SettlementPurchaseListData } from "@sellernote/shared/src/types/forwarding/adminSettlement";

const RemittanceCertificateUploadModal = ({
  showsRemittanceCertificateUploadModal,
  setShowsRemittanceCertificateUploadModal,
  partnerId,
  setPartnerId,
  bidId,
  fetchPurchaseList,
  bidAccountPayableId,
  purchaseListData,
}: {
  showsRemittanceCertificateUploadModal: boolean;
  setShowsRemittanceCertificateUploadModal: (value: boolean) => void;
  partnerId: number;
  setPartnerId: (value: number) => void;
  bidId: number;
  fetchPurchaseList: () => void;
  bidAccountPayableId: number;
  purchaseListData: GET_ADMIN_SETTLEMENT_PURCHASE_LIST_RES | undefined;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const [fileList, setFileList] = useState<FileTypes[]>([]);

  const {
    mutate: getPresignedURL,
    ResponseHandler: ResponseHandlerOfGetPresignedURL,
  } = ADMIN_FILE_QUERY.useGetPresignedURL({
    bidId,
    isPermission: true,
  });

  const {
    mutate: bindFileAfterUpload,
    ResponseHandler: ResponseHandlerOfBindFileAfterUpload,
  } = ADMIN_FILE_QUERY.useSingleBindFileAfterUpload();

  const {
    mutate: checkRemittanceCertificate,
    ResponseHandler: ResponseHandlerOfCheckRemittanceCertificate,
  } = ADMIN_SETTLEMENT_QUERY.useCheckRemittanceCertificate(bidId);

  const { mutate: deleteFile, ResponseHandler: ResponseHandlerOfDeleteFile } =
    ADMIN_FILE_QUERY.useDeleteFile({});

  const {
    mutate: getFileDownloadUrl,
    ResponseHandler: ResponseHandlerOfGetFileDownloadUrl,
  } = ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  const attachment = useMemo(() => {
    const downloadKey = purchaseListData?.list.find(
      (v: SettlementPurchaseListData) => {
        return v.id === partnerId;
      }
    );
    return downloadKey?.attachment;
  }, [partnerId, purchaseListData?.list]);

  const handleFileUpload = async () => {
    const requestFileData = fileList.map((v: FileTypes) => {
      return {
        mimeType: v.fileInfo.type,
        name: v.fileInfo.name,
        type: "remittanceCertificate",
      };
    });

    const flatS3Data = fileList.flat();

    getPresignedURL(
      {
        presignedUrlOptions: requestFileData,
        isPublic: false,
        bidAccountPayableId,
        targetId: partnerId,
      },
      {
        onSuccess: async ({ data: successData }) => {
          let index = 0;
          for await (const param of successData) {
            await axios.put(param.url, flatS3Data[index].fileInfo, {
              headers: { "Content-Type": flatS3Data[index].fileInfo.type },
            });
            index += 1;
          }

          const bindingData = successData.map(
            (v: GET_PRESIGNED_URL_RES_DATA) => {
              return {
                key: v.key,
                domain: "remittanceCertificate",
                targetId: partnerId,
              };
            }
          );

          bindFileAfterUpload(bindingData[0], {
            onSuccess: ({ data: successData }) => {
              checkRemittanceCertificate(
                {
                  requestId: partnerId,
                  attachmentId: successData.attachmentId,
                  deleteFlag: false,
                },
                {
                  onSuccess: () => {
                    setFileList([]);
                    fetchPurchaseList();
                    handleSnackbarOpen("파일 업로드에 성공했습니다.");
                  },

                  onError: ({ response }) => {
                    if (response?.data?.errorCode === "E078") {
                      handleSnackbarOpen("발행 후 업로드 가능합니다.", "error");
                      return;
                    }

                    handleSnackbarOpen(
                      "이체증 체크에서 문제가 발생했습니다.",
                      "error"
                    );
                  },
                }
              );
            },

            onError: () => {
              handleSnackbarOpen(
                "파일 업로드 후 바인딩에서 문제가 발생했습니다.",
                "error"
              );
            },
          });
        },

        onError: () => {
          handleSnackbarOpen("파일 업로드에서 문제가 발생했습니다.", "error");
        },
      }
    );
  };

  const handleFileDelete = () => {
    if (!attachment) {
      return;
    }

    deleteFile(
      {
        attachmentId: attachment?.id,
      },
      {
        onSuccess: () => {
          fetchPurchaseList();
          handleSnackbarOpen("파일 삭제에 성공했습니다.");
        },

        onError: () => {
          handleSnackbarOpen("파일 삭제에 실패했습니다.", "error");
        },
      }
    );
  };

  const handleFileDownload = () => {
    if (!attachment) {
      return;
    }

    getFileDownloadUrl(
      {
        pathParams: {
          key: attachment.key,
        },
      },
      {
        onSuccess: ({ data }) => {
          window.location.href = data.url;
        },

        onError: () => {
          handleSnackbarOpen("파일 다운로드에 실패했습니다.", "error");
        },
      }
    );
  };

  return (
    <>
      <Modal
        handleClose={() => {
          setShowsRemittanceCertificateUploadModal(false);
        }}
        isOpened={showsRemittanceCertificateUploadModal}
        modalBody={
          <Grid container justifyContent={"center"}>
            <Grid>
              <Typography variant="h6" component={"div"}>
                이체증
              </Typography>
            </Grid>

            {attachment ? (
              <Grid item container justifyContent={"center"}>
                <Grid item>
                  <Typography variant="subtitle1" component={"span"}>
                    업로드 파일:
                  </Typography>

                  <Typography variant="subtitle2" component={"span"}>
                    {attachment.name}
                  </Typography>
                </Grid>

                <Grid container item justifyContent={"center"}>
                  <Grid item>
                    <Button onClick={handleFileDownload}>다운로드</Button>
                  </Grid>

                  <Grid item>
                    <Button color="error" onClick={handleFileDelete}>
                      삭제
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <FileUpload
                isMultiple={false}
                files={fileList}
                setFiles={setFileList}
                handleUpload={handleFileUpload}
                warningMessage="한 개의 파일만 업로드 가능합니다."
              />
            )}
          </Grid>
        }
      />

      {ResponseHandlerOfBindFileAfterUpload}
      {ResponseHandlerOfGetPresignedURL}
      {ResponseHandlerOfDeleteFile}
      {ResponseHandlerOfGetFileDownloadUrl}
      {ResponseHandlerOfCheckRemittanceCertificate}
    </>
  );
};

export default RemittanceCertificateUploadModal;
