import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";

import SalesSettlementTable from "./SalesSettlementTable";
import { SalesSettlementProvider } from "./useSalesSettlementContext";

function SalesManagement() {
  return (
    <Layout title="매출 관리">
      <SalesSettlementProvider>
        <SalesSettlementTable />
      </SalesSettlementProvider>
    </Layout>
  );
}

export default SalesManagement;
