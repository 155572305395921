import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { AxiosResponse } from "axios";

import { RequestInvoiceData } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminFile";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import ADMIN_FILE_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import TRELLO_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { ResponseFailureInfo } from "@sellernote/shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/shared/src/types/forwarding/adminBid";
import {
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/shared/src/types/forwarding/trello";
import { checkIfTradingStatementCanBeSendToCustomsBroker } from "@sellernote/shared/src/utils/forwarding/admin/tradingStatement";
import {
  checkHasPurchasePartner,
  checkPurchasePartnerDomainIsCustoms,
} from "@sellernote/shared/src/utils/forwarding/admin/trello";

import useCompareWithDefaultValue from "../../../hooks/useCompareWithDefaultValue";
import useCommonErrorMessage from "../hooks/useCommonErrorMessage";

function CustomsInvoiceButton({
  invoiceType,
  getInvoiceDataForRequest,
  handleRequestError,
  shipmentDetailData,
  invoiceState,
  isIssuedInvoice,
  isIssuedBidInvoice,
  directAirRton,
  sessionInvoiceId,
  onSessionInvoiceChange,
  invoiceIssueDate,
}: {
  invoiceType: InvoiceType;
  getInvoiceDataForRequest: (isTemporary: boolean) => RequestInvoiceData;
  handleRequestError: (
    response: AxiosResponse<ResponseFailureInfo, unknown> | undefined
  ) => void;
  shipmentDetailData: TrelloBidDetail;
  invoiceState: ApplyBidFormData;
  isIssuedInvoice: boolean;
  isIssuedBidInvoice: boolean;
  directAirRton: number;
  sessionInvoiceId: string | null;
  onSessionInvoiceChange: (invoiceId: string) => void;
  invoiceIssueDate: string | null;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const {
    mutate: saveInvoice,
    isLoading: saveInvoiceLoading,
    ResponseHandler: ResponseHandlerOfSaveInvoice,
  } = ADMIN_FILE_QUERY.useSaveInvoice();

  const {
    mutate: editInvoice,
    isLoading: editInvoiceLoading,
    ResponseHandler: ResponseHandlerOfEditInvoice,
  } = ADMIN_FILE_QUERY.useEditInvoice();

  const { checkAndShowCommonErrorMessage } = useCommonErrorMessage();

  const {
    mutate: sendCustomsInvoice,
    ResponseHandler: ResponseHandlerOfSendCustomsInvoice,
  } = ADMIN_FILE_QUERY.useSendCustomsInvoice({
    bidId: shipmentDetailData.id,
  });

  const [opensCustomsInvoiceModal, setOpensCustomsInvoiceModal] =
    useState(false);
  const [opensCustomsPartnerModal, setOpensCustomsPartnerModal] =
    useState(false);

  const { isCargoInfoUnchanged, isExchangeInfoUnchanged } =
    useCompareWithDefaultValue({
      invoiceState,
      shipmentDetailData,
      directAirRton,
    });

  const handleSuccess = useCallback(
    ({ data }) => {
      const invoiceId = data.id;

      sendCustomsInvoice(
        {
          invoiceType,
        },
        {
          onSuccess: () => {
            if (sessionInvoiceId) {
              queryClient.invalidateQueries(
                ADMIN_BID_QUERY_KEY_GEN.getAdminInvoice({
                  invoiceId,
                })
              );
            } else {
              onSessionInvoiceChange(invoiceId);
            }

            handleSnackbarOpen(
              shipmentDetailData.management.documentMailingFlag
                ? "거래명세서를 관세사에게 발송했습니다."
                : "화주에게 발송했습니다."
            );
          },

          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    },
    [
      handleRequestError,
      handleSnackbarOpen,
      invoiceType,
      onSessionInvoiceChange,
      queryClient,
      sendCustomsInvoice,
      sessionInvoiceId,
      shipmentDetailData.management.documentMailingFlag,
    ]
  );

  const requestCustomsInvoice = useCallback(() => {
    if (sessionInvoiceId) {
      editInvoice(
        {
          pathParams: {
            invoiceId: Number(sessionInvoiceId),
          },
          invoiceData: getInvoiceDataForRequest(false),
          isTemporary: false,
          invoiceType,
          ...(invoiceIssueDate && { issuedAt: invoiceIssueDate }),
        },
        {
          onSuccess: handleSuccess,

          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    } else {
      saveInvoice(
        {
          invoiceData: getInvoiceDataForRequest(false),
          isTemporary: false,
          invoiceType,
          ...(invoiceIssueDate && { issuedAt: invoiceIssueDate }),
        },
        {
          onSuccess: handleSuccess,

          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    }
  }, [
    editInvoice,
    getInvoiceDataForRequest,
    handleRequestError,
    handleSuccess,
    invoiceType,
    saveInvoice,
    sessionInvoiceId,
    invoiceIssueDate,
  ]);

  const {
    mutate: changeCustomsPaymentFlag,
    ResponseHandler: ResponseHandlerOfChangeCustomsPaymentFlag,
  } = TRELLO_BID_QUERY.useChangeCustomsPaymentFlag(shipmentDetailData.id);

  const handleCustomsPartnerModalButtonClick = useCallback(
    (flag: boolean) => {
      changeCustomsPaymentFlag(
        { customsPaymentFlag: flag },
        {
          onSuccess: () => {
            requestCustomsInvoice();
          },

          onError: () => {
            handleSnackbarOpen("물류비 대납변경에 실패했습니다.", "error");
          },
        }
      );
    },
    [changeCustomsPaymentFlag, handleSnackbarOpen, requestCustomsInvoice]
  );

  const handleCustomsInvoiceRequestClick = useCallback(() => {
    if (
      checkAndShowCommonErrorMessage({
        isCargoInfoUnchanged,
        isExchangeInfoUnchanged,
        isIssuedInvoice,
        isIssuedBidInvoice,
        isValidateBRN: invoiceState.userBRN.length !== 10,
        canBeSendToCustomsBroker:
          checkIfTradingStatementCanBeSendToCustomsBroker(shipmentDetailData),
        hasDirectAirRton:
          shipmentDetailData.freightType === "AIR" && !directAirRton,
      })
    ) {
      return;
    }

    if (!shipmentDetailData.management.documentMailingFlag) {
      setOpensCustomsInvoiceModal(true);
      return;
    }

    if (
      !checkHasPurchasePartner(shipmentDetailData.accountPayables, "customs")
    ) {
      handleSnackbarOpen("관세사를 선택해주세요", "error");
      return;
    }

    if (
      checkPurchasePartnerDomainIsCustoms(shipmentDetailData.accountPayables)
    ) {
      setOpensCustomsPartnerModal(true);
    } else {
      requestCustomsInvoice();
    }
  }, [
    checkAndShowCommonErrorMessage,
    isCargoInfoUnchanged,
    isExchangeInfoUnchanged,
    isIssuedInvoice,
    isIssuedBidInvoice,
    invoiceState.userBRN.length,
    shipmentDetailData,
    directAirRton,
    requestCustomsInvoice,
    handleSnackbarOpen,
  ]);

  return (
    <>
      <Button
        disabled={saveInvoiceLoading || editInvoiceLoading}
        variant="contained"
        color="success"
        onClick={handleCustomsInvoiceRequestClick}
      >
        {saveInvoiceLoading || editInvoiceLoading ? (
          <CircularProgress size={25} />
        ) : (
          "관세사 발송"
        )}
      </Button>

      <Dialog
        open={opensCustomsInvoiceModal}
        onClose={() => setOpensCustomsInvoiceModal(false)}
      >
        <DialogContent>
          통관서류 및 거래명세서가 고객 이메일로 발송됩니다.
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setOpensCustomsInvoiceModal(false)}
          >
            취소
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              requestCustomsInvoice();
              setOpensCustomsInvoiceModal(false);
            }}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={opensCustomsPartnerModal}
        onClose={() => setOpensCustomsPartnerModal(false)}
      >
        <DialogContent>
          화주자체관세사가 고객의 물류비를 대납합니다.
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              handleCustomsPartnerModalButtonClick(false);
              setOpensCustomsPartnerModal(false);
            }}
          >
            아니오
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              handleCustomsPartnerModalButtonClick(true);
              setOpensCustomsPartnerModal(false);
            }}
          >
            예
          </Button>
        </DialogActions>
      </Dialog>

      {ResponseHandlerOfChangeCustomsPaymentFlag}
      {ResponseHandlerOfSendCustomsInvoice}
      {ResponseHandlerOfSaveInvoice}
      {ResponseHandlerOfEditInvoice}
    </>
  );
}

export default CustomsInvoiceButton;
