import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";
import useVesselScheduleTable from "@sellernote/shared/src/hooks/admin/useVesselScheduleTable";

const VesselScheduleLclWarehouse = () => {
  const { VesselScheduleTable, ResponseHandlerOfGetVesselScheduleList } =
    useVesselScheduleTable({ freightType: "LCL", includesWarehouse: true });

  return (
    <Layout breadcrumbs={[]} title={"운항 스케줄 LCL(창고)"}>
      {VesselScheduleTable}

      {ResponseHandlerOfGetVesselScheduleList}
    </Layout>
  );
};

export default VesselScheduleLclWarehouse;
