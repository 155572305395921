import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_BID_USER_QUERY, {
  ADMIN_BID_USER_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_USER_QUERY";
import { RegisterAdminBidUserFormValue } from "@sellernote/shared/src/types/forwarding/adminBidUser";

const BUSINESS_TYPE = "person";

export default function useHandleRegisterForm({
  onUserCreateModalClose,
}: {
  onUserCreateModalClose: () => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [form, setForm] = useState<RegisterAdminBidUserFormValue>({
    country: "KR",
    company: "",
    name: "",
    email: "",
    phone: "",
    registerType: undefined,
    freightType: undefined,
  });

  const handleFormUpdate = useCallback((value: string, name: string) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const {
    mutate: registerUser,
    ResponseHandler: ResponseHandlerOfRegisterUser,
  } = ADMIN_BID_USER_QUERY.useRegisterUser({
    onSuccess: () => {
      handleSnackbarOpen("사용자가 생성되었습니다.");
      onUserCreateModalClose();

      return queryClient.invalidateQueries(ADMIN_BID_USER_QUERY_KEY_GEN.all());
    },
  });

  const validateRegisterForm = useCallback(() => {
    if (!form.company) {
      handleSnackbarOpen("회사명을 입력해주세요.", "warning");
      return false;
    }

    if (!form.name) {
      handleSnackbarOpen("성함을 입력해주세요.", "warning");
      return false;
    }

    if (!form.email) {
      handleSnackbarOpen("이메일을 입력해주세요.", "warning");
      return false;
    }

    if (!form.phone) {
      handleSnackbarOpen("전화번호를 입력해주세요.", "warning");
      return false;
    }

    return true;
  }, [form.company, form.email, form.name, form.phone, handleSnackbarOpen]);

  const handleUserRegister = useCallback(() => {
    const canSubmit = validateRegisterForm();

    if (!canSubmit) return;

    registerUser({ ...form, businessType: BUSINESS_TYPE });
  }, [form, registerUser, validateRegisterForm]);

  return {
    form,
    handleFormUpdate,
    ResponseHandlerOfRegisterUser,
    handleUserRegister,
  };
}
