import { Box, Button } from "@mui/material";

import DatePicker from "@sellernote/shared/src/admin-ui/components/DatePicker";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_SETTLEMENT_QUERY";

function UpdateWithdrawDate({
  checkBoxArr,
  initCheckBoxSet,
  refetchPurchaseListData,
}: {
  checkBoxArr: number[];
  initCheckBoxSet: (values?: number[]) => void;
  refetchPurchaseListData: () => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const { mutate: editWithdrawDate } =
    ADMIN_SETTLEMENT_QUERY.useEditWithdrawDate();

  const handleWithdrawDateUpdate = (date: string | null) => {
    editWithdrawDate(
      { requestIds: checkBoxArr, withdrawalDate: date ? date : null },
      {
        onSuccess: () => {
          initCheckBoxSet();
          handleSnackbarOpen("요청에 성공했습니다.");
          refetchPurchaseListData();
        },

        onError: (error) => {
          if (error.response?.data.errorCode === "E067") {
            handleSnackbarOpen("취소 권한이 없습니다.", "error");
            return;
          }

          if (error.response?.data.errorCode === "E079") {
            handleSnackbarOpen(
              "입금증, 이체증이 있으면 출금 취소가 불가능합니다.",
              "error"
            );
            return;
          }

          handleSnackbarOpen("출금일을 변경할 수 없습니다.", "error");
        },
      }
    );
  };

  return (
    <Box sx={{ display: "flex", gap: "4px" }}>
      <DatePicker
        value={null}
        setDate={(date) => handleWithdrawDateUpdate(date)}
        when={"start"}
        disabled={checkBoxArr.length < 1}
      />

      <Button
        variant="outlined"
        color="error"
        disabled={checkBoxArr.length < 1}
        onClick={() => handleWithdrawDateUpdate(null)}
      >
        출금일 초기화
      </Button>
    </Box>
  );
}

export default UpdateWithdrawDate;
