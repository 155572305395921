import { useState } from "react";
import { Box, Checkbox } from "@mui/material";

import Table from "@sellernote/shared/src/admin-ui/components/Table";
import {
  FileRelatedToFinAccount,
  PartnerFinancialAccountInfo,
} from "@sellernote/shared/src/types/forwarding/partnerManagement";
import { replaceEmptyToDash } from "@sellernote/shared/src/utils/common/string";

import BOX_STYLE from "../styles";
import { renderFileButton } from "./utils";

import AccountInfoHeader from "./AccountInfoHeader";
import AttachmentPreview from "./AttachmentPreview";
import CELL_LIST from "./CELL_LIST";

export default function AccountInfo({
  accountList,
}: {
  accountList: PartnerFinancialAccountInfo[];
}) {
  const [previewFile, setPreviewFile] = useState<
    FileRelatedToFinAccount | undefined
  >(undefined);

  const row = (() => {
    if (!accountList.length) return [];

    const rowList = accountList.map(
      ({
        name,
        representativeName,
        taxEmail,
        BRN,
        files,
        bankMetadata,
        bankAccount,
        isMain,
      }) => {
        const formattedBRN = BRN
          ? BRN.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3")
          : "-";

        return {
          isMain: (
            <Checkbox checked={isMain} size="small" sx={{ ml: -1 }} disabled />
          ),
          name,
          representativeName: replaceEmptyToDash(representativeName),
          BRN: formattedBRN,

          BRNFile: renderFileButton({
            list: files,
            targetDomain: "financialAccountBRN",
            setPreviewFile,
          }),

          taxEmail: replaceEmptyToDash(taxEmail),
          bankAccountInfo: `${replaceEmptyToDash(
            bankMetadata
          )} / ${replaceEmptyToDash(bankAccount)}`,

          bankBookCopy: renderFileButton({
            list: files,
            targetDomain: "financialAccountBankBookCopy",
            setPreviewFile,
          }),
        };
      }
    );

    return rowList;
  })();

  return (
    <Box sx={{ ...BOX_STYLE, width: "auto" }}>
      <AccountInfoHeader />

      <Box mt={2}>
        <Table headCells={CELL_LIST} rows={row} showsTableVerticalLine />
      </Box>

      {previewFile && (
        <AttachmentPreview
          previewFile={previewFile}
          setPreviewFile={setPreviewFile}
        />
      )}
    </Box>
  );
}
