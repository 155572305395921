import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useRecoilValue } from "recoil";

import MultiLevelSelect, {
  MultiLevelSelectInfo,
} from "@sellernote/shared/src/admin-ui/components/MultiLevelSelect";
import { GET_ADMIN_BID_LIST_FILTER } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminBid";
import { GET_ORDER_SEARCH_PARAMS } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminOrder";
import { I18N_KEY_BY_PO_STATUS } from "@sellernote/shared/src/constants/forwarding/purchaseOrder";
import useSearchWithDateWithNoUseEffect from "@sellernote/shared/src/hooks/admin/useSearchWithDateWithNoUseEffect";
import useSearchWithTermWithHistorySave, {
  TermSearchType,
} from "@sellernote/shared/src/hooks/admin/useSearchWithTermWithHistorySave";
import { FilterDataKey } from "@sellernote/shared/src/types/forwarding/common";

import { SHIPDA_ADMIN_FILTER_ATOMS } from "../../../states/filters";

const useOrderTablePanel = (filterDataKey: FilterDataKey) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const filterData = useRecoilValue(SHIPDA_ADMIN_FILTER_ATOMS[filterDataKey]);

  const POStatusHistory = useMemo(() => {
    if (filterData.poStatus) {
      return filterData.poStatus;
    }
  }, [filterData]);

  const POProjectStatusHistory = useMemo(() => {
    if (filterData.poProjectStatus) {
      return filterData.poProjectStatus;
    }
  }, [filterData]);

  const [totalStatus, setTotalStatus] = useState<string | undefined>(
    POStatusHistory ?? undefined
  );
  const [totalDetailStatus, setTotalDetailStatus] = useState<
    string | undefined
  >(POProjectStatusHistory ?? undefined);

  const MULTI_LEVEL_SELECT_INFO = useMemo(() => {
    const result: MultiLevelSelectInfo<string> = {
      title: "전체상태",
      handleSelect: setTotalStatus,
      selectedOptionValue: totalStatus,
      width: 120,
      optionList: [
        {
          label: t(I18N_KEY_BY_PO_STATUS["registered"]),
          value: "registered",
        },
        {
          label: t(I18N_KEY_BY_PO_STATUS["waiting"]),
          value: "waiting",
          dependentSelectInfo: {
            title: "전체 세부 상태",
            handleSelect: setTotalDetailStatus,
            width: 160,
            selectedOptionValue: totalDetailStatus,
            optionList: [
              {
                value: "none",
                label: "파트너 컨택 전",
              },
              {
                value: "contactPartner",
                label: "운송의뢰 생성 전",
              },
            ],
          },
        },
        {
          label: t(I18N_KEY_BY_PO_STATUS["inProgress"]),
          value: "inProgress",
          dependentSelectInfo: {
            title: "전체 세부 상태",
            handleSelect: setTotalDetailStatus,
            width: 160,
            selectedOptionValue: totalDetailStatus,
            optionList: [
              {
                value: "completed",
                label: "운송 완료",
              },
              {
                value: "canceled",
                label: "취소",
              },
              {
                value: "failed",
                label: "거절",
              },
            ],
          },
        },
        {
          label: t(I18N_KEY_BY_PO_STATUS["finished"]),
          value: "finished",
          dependentSelectInfo: {
            title: "전체 세부 상태",
            handleSelect: setTotalDetailStatus,
            width: 160,
            selectedOptionValue: totalDetailStatus,
            optionList: [
              {
                value: "completed",
                label: "운송 완료",
              },
              {
                value: "canceled",
                label: "취소",
              },
              {
                value: "failed",
                label: "거절",
              },
            ],
          },
        },
      ],
    };

    return result;
  }, [t, totalDetailStatus, totalStatus]);

  const termSearchTypeOptions: TermSearchType<GET_ORDER_SEARCH_PARAMS>[] =
    useMemo(() => {
      return [
        {
          label: "PO 번호",
          value: "poNumber",
        },
        {
          label: "등록번호",
          value: "poId",
          type: "number",
        },
        {
          label: "subPO번호",
          value: "subPoNumber",
        },
        {
          label: "상품명",
          value: "productName",
        },
        {
          label: "메모",
          value: "memo",
        },
        {
          label: "수입자명",
          value: "company",
        },
        {
          label: "담당자 이름",
          value: "userName",
        },
        {
          label: "담당자 연락처",
          value: "userPhone",
        },
        {
          label: "담당자 이메일",
          value: "userEmail",
        },
        {
          label: "운송 의뢰 번호",
          value: "bidId",
          type: "number",
        },
        {
          label: "BL 번호",
          value: "BL",
        },
      ];
    }, []);

  const searchTermHistory = useMemo(() => {
    const termSearchTypeArray = termSearchTypeOptions.map((item) => item.value);

    const itemKey = termSearchTypeArray.find(
      (item) => filterData[item] && item
    );

    const itemKeyIndex = termSearchTypeArray.findIndex(
      (item) => filterData[item] && filterData[item]
    );

    if (itemKey && itemKeyIndex && filterData && filterData[itemKey])
      return {
        itemKey,
        itemKeyIndex,
      };
  }, [filterData, termSearchTypeOptions]);

  const historyDataCallback = useCallback(
    (itemKey: keyof GET_ADMIN_BID_LIST_FILTER = "poId") => {
      return filterData[itemKey] && filterData[itemKey];
    },
    [filterData]
  );

  const { TermSearchPanel, debouncedSearchTermWithObject, reset } =
    useSearchWithTermWithHistorySave({
      termSearchTypeOptions,
      history: searchTermHistory,
      historyData: searchTermHistory
        ? historyDataCallback(searchTermHistory?.itemKey)
        : historyDataCallback(),
    });

  const dateSearchTypeOptions = [
    {
      label: "PO 생성일",
      value: "createdAt",
    },
  ];

  const { DateSearchPanel, startDate, endDate, setStartDate, setEndDate } =
    useSearchWithDateWithNoUseEffect({
      dateSearchTypeOptions,
      dateHistory: {
        startDate: filterData?.fromDate,
        endDate: filterData?.toDate,
        history: {
          itemKey: "createdAt",
          itemKeyIndex: 0,
        },
      },
    });

  const dateFilter = useMemo(() => {
    return startDate !== "" && endDate !== ""
      ? { fromDate: startDate, toDate: endDate }
      : null;
  }, [endDate, startDate]);

  const tablePanelRef = useCallback(() => {
    if (pathname.includes("/sub")) {
      setTotalStatus(undefined);
      setTotalDetailStatus(undefined);
    }
  }, [pathname]);

  const handleTableReset = () => {
    setTotalStatus(undefined);
    setTotalDetailStatus(undefined);
    reset(event);
    setStartDate("");
    setEndDate("");
  };

  const TablePanel = (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: 1,
        flex: 1,
      }}
      ref={tablePanelRef}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          flexWrap: "wrap",
          flex: 1,
        }}
      >
        {!pathname.includes("/sub") && (
          <Box alignSelf="flex-end">
            <MultiLevelSelect selectInfo={MULTI_LEVEL_SELECT_INFO} />
          </Box>
        )}

        <Box sx={{ alignSelf: "end" }}>
          <DateSearchPanel />
        </Box>

        <Box>{TermSearchPanel}</Box>
      </Box>

      <Button
        onClick={handleTableReset}
        variant="contained"
        sx={{ marginTop: "10px", height: "40px" }}
      >
        초기화
      </Button>
    </Box>
  );

  return {
    debouncedSearchTermWithObject,
    dateFilter,
    totalStatus,
    totalDetailStatus,
    TablePanel,
  };
};

export default useOrderTablePanel;
