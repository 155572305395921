import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";

import DepositTable from "./DepositTable";

function SettlementDepositManagement() {
  return (
    <Layout title="입금 관리">
      <DepositTable />
    </Layout>
  );
}

export default SettlementDepositManagement;
