import { useState } from "react";
import { Button } from "@mui/material";
import { Box, Typography } from "@mui/material";

import BUSINESS_MANAGEMENT_QUERY from "@sellernote/shared/src/queries/forwarding/admin/BUSINESS_MANAGEMENT_QUERY";

import BeforeEstimateTable from "./_components/BeforeEstimateTable";
import DBManagementTable from "./_components/DBManagementTable";
import ExcelDownloadModal from "./_components/ExcelDownloadModal";
import RemartketingTable from "./_components/RemarkteingTable";
import WaitingTable from "./_components/WaitingTable";
import useBusinessManagementFilter from "./useBusinessManagementFilter";

const BusinessManagementTable = ({
  forwardingManagerAdminId,
}: {
  forwardingManagerAdminId: number;
}) => {
  const [showExcelDownloadModal, setShowExcelDownloadModal] = useState(false);

  const {
    RadioGroupFilterPanel,
    radioGroupFilter,
    TermSearchPanel,
    debouncedSearchTermWithObject,
    EndDateFilter,
    endDate,
    page,
    setPage,
    perPage,
    setPerPage,
  } = useBusinessManagementFilter();

  const { data: businessManagementList } =
    BUSINESS_MANAGEMENT_QUERY.useGetBusinessManagementList(
      {
        page,
        pageSize: perPage,
        ...debouncedSearchTermWithObject,
        managementType: radioGroupFilter?.value || "beforeEstimate",
        remarketingToDate:
          radioGroupFilter?.value === "remarketing" && endDate
            ? endDate
            : undefined,
        adminId: forwardingManagerAdminId,
      },
      radioGroupFilter?.value !== "DBManagement"
    );

  const { data: businessManagementDbList } =
    BUSINESS_MANAGEMENT_QUERY.useGetBusinessManagementDbList(
      {
        page,
        perPage: 25,
      },
      radioGroupFilter?.value === "DBManagement"
    );

  return (
    <Box sx={{ p: 1, background: "#fff" }}>
      <Box sx={{ display: "flex", gap: 1, pb: 1 }}>
        {RadioGroupFilterPanel}

        {radioGroupFilter?.value === "remarketing" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              {TermSearchPanel}

              <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <Typography>재영업일</Typography>

                <Typography>~</Typography>

                {EndDateFilter}
              </Box>
            </Box>

            <Button
              sx={{ height: "40px" }}
              variant="contained"
              onClick={() => setShowExcelDownloadModal(true)}
            >
              엑셀 다운로드
            </Button>
          </Box>
        )}
      </Box>

      {radioGroupFilter?.value === "DBManagement" && (
        <DBManagementTable
          perPage={perPage}
          setPerPage={setPerPage}
          setPage={setPage}
          page={page}
          businessManagementDbList={businessManagementDbList}
        />
      )}

      {radioGroupFilter?.value === "beforeEstimate" && (
        <BeforeEstimateTable
          perPage={perPage}
          setPerPage={setPerPage}
          businessManagementList={businessManagementList}
          setPage={setPage}
          page={page}
        />
      )}

      {radioGroupFilter?.value === "waiting" && (
        <WaitingTable
          perPage={perPage}
          setPerPage={setPerPage}
          setPage={setPage}
          page={page}
          businessManagementList={businessManagementList}
        />
      )}

      {radioGroupFilter?.value === "remarketing" && (
        <>
          <RemartketingTable
            perPage={perPage}
            setPerPage={setPerPage}
            setPage={setPage}
            page={page}
            businessManagementList={businessManagementList}
          />

          {showExcelDownloadModal && (
            <ExcelDownloadModal
              setShowExcelDownloadModal={setShowExcelDownloadModal}
              showExcelDownloadModal={showExcelDownloadModal}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default BusinessManagementTable;
