import {
  GET_ADMIN_SETTLEMENT_SALES_LIST_REQ_DATE_KIND,
  GET_ADMIN_SETTLEMENT_SALES_LIST_REQ_SEARCH_KIND,
} from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import { SearchWithDateTypeOption } from "@sellernote/shared/src/hooks/admin/useSearchWithDate";
import { TermSearchType } from "@sellernote/shared/src/hooks/admin/useSearchWithTerm";
import { SelectFilterOptionList } from "@sellernote/shared/src/hooks/admin/useSelectFilter";
import {
  BooleanStringV2,
  ShipmentType,
} from "@sellernote/shared/src/types/common/common";
import { SettlementSalesListStatus } from "@sellernote/shared/src/types/forwarding/adminSettlement";
import { InvoiceType } from "@sellernote/shared/src/types/forwarding/trello";

const DATE_SEARCH_TYPE_OPTION_LIST: SearchWithDateTypeOption<GET_ADMIN_SETTLEMENT_SALES_LIST_REQ_DATE_KIND>[] =
  [
    {
      label: "입금일 기준",
      value: "depositDate",
    },
    {
      label: "발행일 기준",
      value: "createdAt",
    },
    {
      label: "계산서 발행일",
      value: "issueDate",
    },
  ];

const TERM_SEARCH_TYPE_OPTION_LIST: TermSearchType<GET_ADMIN_SETTLEMENT_SALES_LIST_REQ_SEARCH_KIND>[] =
  [
    {
      label: "회사명",
      value: "recipient",
    },
    {
      label: "의뢰번호",
      value: "bidId",
    },
    {
      label: "사업자등록번호",
      value: "BRN",
    },
    {
      label: "BL",
      value: "BL",
    },
  ];

const INVOICE_TYPE_SELECT_FILTER_OPTION_LIST: SelectFilterOptionList<
  undefined | InvoiceType
>[] = [
  {
    label: "전체",
    value: undefined,
  },
  {
    label: "거래명세서",
    value: "invoice",
  },
  {
    label: "부킹 커미션",
    value: "refund",
  },
  {
    label: "창고료",
    value: "warehouseReceipt",
  },
  {
    label: "기타입금",
    value: "etcDeposit",
  },
];

const BOOLEAN_SELECT_FILTER_OPTION_LIST: SelectFilterOptionList<
  undefined | BooleanStringV2
>[] = [
  {
    label: "전체",
    value: undefined,
  },
  {
    label: "O",
    value: "TRUE",
  },
  {
    label: "X",
    value: "FALSE",
  },
];

type NonNullSettlementSalesListStatus =
  | Exclude<SettlementSalesListStatus, null>
  | "null"
  | "none";

const INVOICE_STATUS_SELECT_FILTER_OPTION_LIST: SelectFilterOptionList<NonNullSettlementSalesListStatus>[] =
  [
    {
      label: "전체",
      value: "null",
    },
    {
      label: "- (발행 전)",
      value: "none",
    },
    {
      label: "발행취소",
      value: "canceled",
    },
    {
      label: "발행 실패",
      value: "rejected",
    },
    {
      label: "전송중",
      value: "sending",
    },
    {
      label: "전송성공",
      value: "done",
    },
    {
      label: "전송실패",
      value: "failed",
    },
  ];

const INVOICE_SHIPMENT_TYPE_FILTER_LIST: SelectFilterOptionList<
  undefined | ShipmentType
>[] = [
  {
    label: "전체",
    value: undefined,
  },
  {
    label: "수입",
    value: "importation",
  },
  {
    label: "수출",
    value: "exportation",
  },
];

export {
  DATE_SEARCH_TYPE_OPTION_LIST,
  TERM_SEARCH_TYPE_OPTION_LIST,
  INVOICE_TYPE_SELECT_FILTER_OPTION_LIST,
  BOOLEAN_SELECT_FILTER_OPTION_LIST,
  INVOICE_STATUS_SELECT_FILTER_OPTION_LIST,
  INVOICE_SHIPMENT_TYPE_FILTER_LIST,
};
