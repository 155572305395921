import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  TextField,
} from "@mui/material";

import { CREATE_PARTNER_REQ } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/partnerManagement";

import NO_LABEL from "../../styles";
import { formatBRN } from "../../utils";

interface Option {
  id: number;
  label: string;
  value: string | null;
}

const filter = createFilterOptions<Option>();

/**
 * 파트너 등록 > 거래처 자동완성 Select
 *
 * 동작
 *  - 검색은 대쉬를 포함한 사업자등록번호 총 12자리를 입력한다.
 *  - 검색 결과가 있을 경우, 기존 거래처를 클릭하여 해당 파트너에 매핑할 수 있다.
 *    - 기존 거래처 매핑시 첨부파일은 기존 거래처의 첨부파일를 사용한다.
 *  - 검색 결과가 없을 경우, 새로운 거래처를 생성할 수 있다.
 *    - 검색 결과가 없을 경우 새로운 거래처를 생성할 수 있는 SelectOption이 추가된다.
 *    - 새로운 거래처를 선택하면 첨부파일을 업로드해야 한다.
 *
 * @param isCreate 새로운 거래처 생성 여부 flag
 */
export default function AccountAutoComplete({
  optionList,
  form,
  setIsSelected,
  isCreate,
  setIsCreate,
  onAutoCompleteSelect,
}: {
  optionList: Option[];
  form: CREATE_PARTNER_REQ;
  setIsSelected: (val: boolean) => void;
  isCreate: boolean;
  setIsCreate: (val: boolean) => void;
  onAutoCompleteSelect: (val: string | null, isNew?: boolean) => void;
}) {
  return (
    <Box ml={-1} display={"flex"} alignItems={"center"} gap={1}>
      <Autocomplete
        options={optionList}
        value={form.targetAccountPayloads[0].BRN}
        sx={{ width: 300 }}
        size="small"
        freeSolo
        disabled={isCreate}
        filterOptions={(options, params) => {
          const filteredList = filter(options, params);
          const { inputValue: input } = params;

          const isExist = options.some(
            ({ value }) => formatBRN(input) === formatBRN(value)
          );

          if (input !== "" && !isExist) {
            filteredList.push({
              id: 0,
              label: `+ 새로운 거래처 ${input}`,
              value: input,
            });
          }

          return filteredList;
        }}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              key={option.id}
              style={{ color: option.id === 0 ? "#0288d1" : "inherit" }}
            >
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            key={params.id}
            required
            sx={NO_LABEL}
            onChange={(e) => onAutoCompleteSelect(e.target.value)}
            placeholder="000-00-00000"
          />
        )}
        onChange={(_, v) => {
          if (typeof v === "string" || v === null) return;
          const isNew = v.id === 0;

          setIsSelected(true);
          onAutoCompleteSelect(v.value, isNew);
        }}
        componentsProps={{
          clearIndicator: {
            onClick: () => {
              setIsSelected(false);
              onAutoCompleteSelect("");
            },
          },
        }}
      />

      {isCreate && (
        <Button
          variant="outlined"
          onClick={() => {
            setIsCreate(false);
            onAutoCompleteSelect("");
          }}
          sx={{ height: 39 }}
        >
          해제
        </Button>
      )}
    </Box>
  );
}
