import { useCallback } from "react";
import { useQueryClient } from "react-query";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";

import {
  RequestInvoiceData,
  SAVE_INVOICE_RES,
} from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminFile";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import ADMIN_FILE_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import { ResponseFailureInfo } from "@sellernote/shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/shared/src/types/forwarding/adminBid";
import { InvoiceData } from "@sellernote/shared/src/types/forwarding/tradingStatement";
import {
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/shared/src/types/forwarding/trello";

import useCompareWithDefaultValue from "../../../hooks/useCompareWithDefaultValue";
import useCommonErrorMessage from "../hooks/useCommonErrorMessage";

function SaveAndDownloadButton({
  invoiceType,
  getInvoiceDataForRequest,
  handleRequestError,
  invoiceState,
  shipmentDetailData,
  directAirRton,
  invoiceData,
  sessionInvoiceId,
  onSessionInvoiceChange,
  invoiceIssueDate,
}: {
  invoiceType: InvoiceType;
  getInvoiceDataForRequest: () => RequestInvoiceData;
  handleRequestError: (
    response: AxiosResponse<ResponseFailureInfo, any> | undefined
  ) => void;
  invoiceState: ApplyBidFormData;
  shipmentDetailData: TrelloBidDetail;
  directAirRton: number;
  invoiceData: InvoiceData | undefined;
  sessionInvoiceId: string | null;
  onSessionInvoiceChange: (invoiceId: string) => void;
  invoiceIssueDate: string | null;
}) {
  const queryClient = useQueryClient();

  const {
    mutate: saveInvoice,
    isLoading: saveInvoiceLoading,
    ResponseHandler: ResponseHandlerOfSaveInvoice,
  } = ADMIN_FILE_QUERY.useSaveInvoice();

  const {
    mutate: editInvoice,
    isLoading: editInvoiceLoading,
    ResponseHandler: ResponseHandlerOfEditInvoice,
  } = ADMIN_FILE_QUERY.useEditInvoice();

  const {
    mutate: getFileDownloadUrl,
    isLoading: downloadInvoiceLoading,
    ResponseHandler: ResponseHandlerOfGetFileDownloadUrl,
  } = ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  const { handleSnackbarOpen } = useSnackbar();

  const { isCargoInfoUnchanged, isExchangeInfoUnchanged } =
    useCompareWithDefaultValue({
      invoiceState,
      shipmentDetailData,
      directAirRton,
    });

  const { checkAndShowCommonErrorMessage } = useCommonErrorMessage();

  const handleSuccess = useCallback(
    ({ data }: { data: SAVE_INVOICE_RES }) => {
      if (sessionInvoiceId) {
        queryClient.invalidateQueries(
          ADMIN_BID_QUERY_KEY_GEN.getAdminInvoice({
            invoiceId: Number(sessionInvoiceId),
          })
        );
      } else {
        onSessionInvoiceChange(data.id.toString());
      }

      handleSnackbarOpen("저장했습니다.");
    },

    [sessionInvoiceId, handleSnackbarOpen, queryClient, onSessionInvoiceChange]
  );

  const handleSaveClick = useCallback(() => {
    if (
      checkAndShowCommonErrorMessage({
        isCargoInfoUnchanged,
        isExchangeInfoUnchanged,
        isValidateBRN: invoiceState.userBRN.length !== 10,
        hasDirectAirRton:
          shipmentDetailData.freightType === "AIR" && !directAirRton,
      })
    ) {
      return;
    }

    if (sessionInvoiceId) {
      editInvoice(
        {
          pathParams: {
            invoiceId: Number(sessionInvoiceId),
          },
          invoiceData: getInvoiceDataForRequest(),
          isTemporary: false,
          invoiceType,
          ...(invoiceIssueDate && { issuedAt: invoiceIssueDate }),
        },
        {
          onSuccess: handleSuccess,
          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    } else {
      saveInvoice(
        {
          invoiceData: getInvoiceDataForRequest(),
          isTemporary: false,
          invoiceType,
          ...(invoiceIssueDate && { issuedAt: invoiceIssueDate }),
        },
        {
          onSuccess: handleSuccess,
          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    }
  }, [
    checkAndShowCommonErrorMessage,
    isCargoInfoUnchanged,
    isExchangeInfoUnchanged,
    invoiceState.userBRN.length,
    shipmentDetailData.freightType,
    directAirRton,
    sessionInvoiceId,
    editInvoice,
    getInvoiceDataForRequest,
    invoiceType,
    invoiceIssueDate,
    handleSuccess,
    handleRequestError,
    saveInvoice,
  ]);

  const handleDownloadClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();

      if (!invoiceData?.pdfKey) {
        handleSnackbarOpen("저장된 명세서가 없습니다.", "error");
        return;
      }

      getFileDownloadUrl(
        {
          pathParams: {
            key: invoiceData.pdfKey,
          },
        },
        {
          onSuccess: ({ data: successData }) => {
            window.location.href = successData.url;
          },

          onError: () => {
            handleSnackbarOpen("파일 다운로드에 실패했습니다.", "error");
          },
        }
      );
    },
    [getFileDownloadUrl, handleSnackbarOpen, invoiceData?.pdfKey]
  );

  return (
    <>
      <Button
        disabled={saveInvoiceLoading || editInvoiceLoading}
        variant="contained"
        onClick={handleSaveClick}
      >
        {saveInvoiceLoading || editInvoiceLoading ? (
          <CircularProgress size={25} />
        ) : (
          "저장"
        )}
      </Button>

      <Button
        endIcon={<DownloadIcon />}
        disabled={downloadInvoiceLoading}
        variant="contained"
        onClick={handleDownloadClick}
      >
        {downloadInvoiceLoading ? <CircularProgress size={25} /> : "다운로드"}
      </Button>

      {ResponseHandlerOfGetFileDownloadUrl}
      {ResponseHandlerOfSaveInvoice}
      {ResponseHandlerOfEditInvoice}
    </>
  );
}

export default SaveAndDownloadButton;
