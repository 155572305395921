import { useParams } from "react-router-dom";
import { Divider, Typography } from "@mui/material";

import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";

import useGetPartnerDetail from "./hooks";

import PartnerDetailBody from "./PartnerDetailBody";

function PartnerDetail() {
  const { id }: { id: string } = useParams();

  const {
    data,
    refetchDetail,
    partnerInfo,
    setPartnerInfo,
    managerList,
    setManagerList,
  } = useGetPartnerDetail({ id });

  return (
    <Layout>
      <Typography variant="h5" fontWeight={"bold"}>
        파트너 상세
      </Typography>

      <Divider sx={{ mt: 1, mb: 2 }} />

      {data && (
        <PartnerDetailBody
          data={data}
          refetchDetail={refetchDetail}
          partnerInfo={partnerInfo}
          setPartnerInfo={setPartnerInfo}
          managerList={managerList}
          setManagerList={setManagerList}
        />
      )}
    </Layout>
  );
}

export default PartnerDetail;
