import { useEffect } from "react";
import { useSetAtom } from "jotai";
import { FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS } from "jotaiStates/tradingStatement";

import { InvoiceData } from "@sellernote/shared/src/types/forwarding/tradingStatement";
import { TrelloBidDetail } from "@sellernote/shared/src/types/forwarding/trello";
import {
  extractNumberFromRtonString,
  getTradingStatementInitialCbm,
  getTradingStatementInitialPackageValue,
  getTradingStatementInitialTon,
} from "@sellernote/shared/src/utils/forwarding/admin/tradingStatement";

/**
 * 초기 렌더링 시 거래명세서 화물 정보의 기본값을 셋팅해준다.
 *   - 화물정보에서 적용하기 클릭 시 적용시점의 값들로 변경이 됨
 */
export default function useSetDefaultCargoInfo({
  shipmentDetailData,
  invoiceData,
}: {
  shipmentDetailData: TrelloBidDetail;
  invoiceData: InvoiceData | undefined;
}) {
  const setDefaultCbm = useSetAtom(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_CBM
  );

  const setDefaultTon = useSetAtom(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_TON
  );

  const setDefaultPackageType = useSetAtom(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_PACKAGE
  );

  const setDefaultDirectRton = useSetAtom(
    FORWARDING_ADMIN_TRADING_STATEMENT_ATOMS.TRADING_STATEMENT_DEFAULT_DIRECT_RTON
  );

  // 화물정보의 기본값을 셋팅
  useEffect(() => {
    setDefaultCbm(
      getTradingStatementInitialCbm({
        shipmentDetailData,
        invoiceData,
      })
    );
    setDefaultTon(
      getTradingStatementInitialTon({
        shipmentDetailData,
        invoiceData,
      })
    );
    setDefaultPackageType(
      getTradingStatementInitialPackageValue({
        shipmentDetailData,
        invoiceData,
      })
    );
    setDefaultDirectRton(
      shipmentDetailData.freightType === "AIR" && invoiceData?.rton
        ? extractNumberFromRtonString(invoiceData.rton)
        : 0
    );
  }, [
    invoiceData,
    setDefaultCbm,
    setDefaultDirectRton,
    setDefaultPackageType,
    setDefaultTon,
    shipmentDetailData,
  ]);
}
