import { Dispatch, SetStateAction, useMemo } from "react";
import { Grid, Typography } from "@mui/material";

import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import { GET_ADMIN_SETTLEMENT_SALES_LIST_REQ } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminSettlement";

import SalesChitExcelDownload from "./SalesChitExcelDownload";
import SalesListExcelDownload from "./SalesListExcelDownload";

function ExcelDownloadModal({
  showsExcelDownloadModal,
  setShowsExcelDownloadModal,
  fetchListParams,
}: {
  showsExcelDownloadModal: boolean;
  setShowsExcelDownloadModal: Dispatch<SetStateAction<boolean>>;
  fetchListParams: GET_ADMIN_SETTLEMENT_SALES_LIST_REQ;
}) {
  const ModalBody = useMemo(() => {
    return (
      <Grid container spacing={4} justifyContent="center">
        <Grid item>
          <Typography variant="h6" component="div">
            엑셀 다운로드
          </Typography>
        </Grid>

        <Grid item container spacing={3}>
          <Grid item xs={5}>
            <SalesChitExcelDownload fetchListParams={fetchListParams} />
          </Grid>

          <Grid item xs={5}>
            <SalesListExcelDownload fetchListParams={fetchListParams} />
          </Grid>
        </Grid>
      </Grid>
    );
  }, [fetchListParams]);

  return (
    <Modal
      isOpened={showsExcelDownloadModal}
      handleClose={() => setShowsExcelDownloadModal(false)}
      modalBody={ModalBody}
    />
  );
}

export default ExcelDownloadModal;
