import { useCallback } from "react";
import { Button, Grid } from "@mui/material";

import { GET_ADMIN_SETTLEMENT_SALES_LIST_REQ } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_SETTLEMENT_QUERY";

function SalesListExcelDownload({
  fetchListParams,
}: {
  fetchListParams: GET_ADMIN_SETTLEMENT_SALES_LIST_REQ;
}) {
  const { refetch } =
    ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementSalesExcelList(fetchListParams);

  const handleSalesExcelListReFetch = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSalesExcelListReFetch}
        >
          세금계산서 용
        </Button>
      </Grid>
    </Grid>
  );
}

export default SalesListExcelDownload;
